import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/1.jpg";
import { GrFormAdd } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import { MdComputer, MdArrowRightAlt } from "react-icons/md";
import "./ServicesDetails.css";
// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import StickyBox from "react-sticky-box";
import bg2 from "../../../images/background/web.jpg";
import bg3 from "../../../images/background/web1.jpg";
import pic1 from "../../../images/Icons/1.png";
import pic2 from "../../../images/Icons/2.png";
import pic3 from "../../../images/Icons/3.png";
import pic4 from "../../../images/Icons/4.png";
import pic5 from "../../../images/Icons/5.png";
import pic6 from "../../../images/Icons/6.png";
import pic7 from "../../../images/Icons/7.png";
import pic8 from "../../../images/Icons/8.png";
import pic9 from "../../../images/Icons/9.png";
import pic10 from "../../../images/Icons/10.png";
import pic11 from "../../../images/Icons/11.png";
import pic12 from "../../../images/Icons/12.png";
import pic13 from "../../../images/Icons/13.png";
import pic14 from "../../../images/Icons/14.png";
import pic15 from "../../../images/Icons/15.png";
import NewCard from "./NewCard";

class Webdevelopment extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="Web Development"
              activeMenu="Web Development"
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl mx-auto ">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div class="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <div className="">
                      <h2 class="title">Web Development</h2>
                      <p className="">
                        Gobeens Technology has a well equipped web development lab in
                        place which is run by highly experienced team of
                        designers, developers, testers and project managers. Our
                        team of experts has the knowledge of the latest
                        technologies as per the current industry standards and
                        keep themselves updated about the latest trends. We as a
                        team put focus on our Icons requirements and make sure
                        that the project is delivered with the utmost
                        satisfaction.
                      </p>
                    </div>

                    {/* Card */}
                    <NewCard data={webCard} />
                    {/* Card end */}
                    <section className="row">
                      <h1 className="text-center">TOOL AND TECHNOLOGIES</h1>
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {tool.map((data, index) => (
                          <div
                            className="shadow-md hover:shadow-xl cursor-pointer duration-300 flex justify-center flex-col items-center "
                            style={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              className="p-4 w-32"
                              src={data.pic}
                              alt="Avatar"
                            />
                            <h2
                              className="text-center"
                              style={{ fontSize: "20px" }}
                            >
                              {data.name}
                            </h2>
                          </div>
                        ))}
                      </div>
                      {/* <div className="col-sm-6 col-md-6 ">
                        <span className="shadow-lg p-3 mb-5 bg-body rounded ">
                          <h8>FRONT END FRAMEWORK</h8>
                          <p class="card-texts">
                            jQuery, Bootstrap, Semantic-UI, Foundation, CSS/HTML
                          </p>
                          <h8 className="mart">DATABASES</h8>
                          <p class="card-texts">
                            MongoDB, Redis, PostgreSQL, MySQL, Oracle, SQL
                            Server
                          </p>
                          <h8 className="mart">WEB SERVERS</h8>
                          <p class="card-texts"> Nginx, Apache, IIS, Caddy</p>
                        </span>
                        <span className=" shadow-lg p-3 mb-5 bg-body rounded ">
                          <h8>WEB DESIGN & PROTOTYPING</h8>
                          <p class="card-texts">
                            ProtoPie, Framer, Affinity Designer, Photoshop,
                            Illustrator
                          </p>
                          <h8 className="mart">LOCAL DEV ENVIRONMENTS</h8>
                          <p class="card-texts">
                            Xampp, Mamp, WampServer, Laragon, Vagrant
                          </p>
                          <h8 className="mart">CSS PROCESSORS</h8>
                          <p class="card-texts">Less, Sass, Stylus</p>
                        </span>
                      </div> */}
                    </section>
                    {/* Technology Card */}
                    <div class="row align-items-center justify-content-center container">
                      <div class="col-lg-6">
                        <div class="image text-center mb-40 tool">
                          <img
                            src={bg3}
                            style={{ height: "350px", width: "350px" }}
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <h3 class="sub-title">
                          How To Buildup Website & Create
                        </h3>
                        <p>
                          Nor again is there anyone who loves or pursues or
                          desires to obtain pain of itself, because it is pain,
                          but because occasionally circumstances occur in which
                          toil and pain can procure him some great pleasure.
                        </p>
                        <ul class="check-list space-y-3 mt-30 mb-40">
                          <li className="flex space-x-4 items-center">
                            <TiTick class="design" />
                            Business & Consulting Agency
                          </li>
                          <li className="flex space-x-4 items-center">
                            <TiTick class="design" />
                            Awards Winning Business Comapny
                          </li>
                          <li className="flex space-x-4 items-center">
                            <TiTick class="design" />
                            Complete Guide To Mechanical
                          </li>
                          <li className="flex space-x-4 items-center">
                            <TiTick class="design" />
                            Firebase Authentication & Database
                          </li>
                        </ul>
                      </div>
                      <div class="faq-section mt-40">
                        <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                          <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                            How much does a new website cost?
                          </summary>
                          <p class="px-4 py-3">
                            The cost of a new website depends on various factors
                            like the kind of features and functionality required
                            in it, number of pages, complexity of the website,
                            etc. When our experts are able to gain a thorough
                            understanding of your specific website building
                            needs, only then they can provide you with a
                            realistic estimate of your website’s pricing.
                          </p>
                        </details>

                        <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                          <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                            Do you build WordPress websites?
                          </summary>
                          <p class="px-4 py-3">
                            Yes we create WordPress websites. As WordPress is
                            flexible and supportive in creating any type of
                            website without any restrictions, it is increasingly
                            used today for business website building. More than
                            30% of websites being powered by WordPress shows its
                            dominant position in the website development realm.
                            Apart from WordPress, we use other content
                            management systems like Joomla, Drupal, Wix, Weebly
                            and Sitecore for building business websites.
                          </p>
                        </details>

                        <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                          <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                            Will you also maintain my website?
                          </summary>
                          <p class="px-4 py-3">
                            Our experts will not only build your website, but
                            will also take care of its maintenance to ensure it
                            continues to function in a smooth, glitch-free and
                            secure manner. Regular website maintenance performed
                            by them will help your business function normally
                            and retain customers’ trust in your business brand.
                          </p>
                        </details>
                        <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                          <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                            Will my website be mobile friendly?
                          </summary>
                          <p class="px-4 duration-500 py-3">
                            Yes, the website we will develop for you will open
                            and look perfect on a variety of mobile phones and
                            other devices like tablets, laptops and desktops.
                            The responsive website will provide your users an
                            appealing viewing experience as well as an optimum
                            browsing experience on their respective devices.
                          </p>
                        </details>
                      </div>
                    </div>
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>
          <Pricing />
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          {/* <!-- Get in touch --> */}
          <Footer />
        </div>
      </>
    );
  }
}
const tool = [
  {
    pic: pic1,
    name: "HTML5 Boilerplate",
  },
  {
    pic: pic2,
    name: "Bootstrap",
  },
  {
    pic: pic3,
    name: "Coral Draw",
  },
  {
    pic: pic4,
    name: "CSS3",
  },
  {
    pic: pic5,
    name: "Dreamweaver",
  },
  {
    pic: pic6,
    name: "Foundation 3",
  },
  {
    pic: pic7,
    name: "Grid Systems",
  },
  {
    pic: pic8,
    name: "HTML5",
  },
  {
    pic: pic9,
    name: "Illustrator",
  },
  {
    pic: pic10,
    name: "In Design",
  },
  {
    pic: pic11,
    name: "jQuery",
  },
  {
    pic: pic12,
    name: "LESS",
  },
  {
    pic: pic13,
    name: "Material Design",
  },
  {
    pic: pic14,
    name: "SASS",
  },
  {
    pic: pic15,
    name: "Sketch",
  },
];
export const webCard = [
  {
    title: "Understand Requirement",
    desc: "Understanding the requirement is the most important phase of any project. Here, we also work with the client to establish the schedule, budget,timeline & technical needs.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "CODE AND DEVELOP",
    desc: "Here, we make sure that the team works as a unit to code & develop the masterpiece in creation and be ready for the first review and take the feedback.",
    icon: <MdComputer className="text-4xl" />,
    no: "02",
  },
  {
    title: "DEPLOYMENT",
    desc: " Hurray! The masterpiece is ready to be deployed for your audience to feel the new experience the act, process, or result of developing.",
    icon: <MdComputer className="text-4xl" />,
    no: "03",
  },
  {
    title: "DESIGN THE WIREFRAME",
    desc: "  The site structure forms the backbone of the project. It acts as a reference guide for everyone during the entire duration of the project by providing the website its initial look and feel.",
    icon: <MdComputer className="text-4xl" />,
    no: "04",
  },
  {
    title: "TESTING & FIXING OF BUGS",
    desc: "No project is flawless. Hence, rigorous testing done by our QA team makes sure that your project is fixed for any bugs before it is delivered.",
    icon: <MdComputer className="text-4xl" />,
    no: "05",
  },
  {
    title: "MAINTENANCE",
    desc: "What else? We not only deliver your projects but we also pledge to make sure that your website faces no problem.",
    icon: <MdComputer className="text-4xl" />,
    no: "06",
  },
];

export default Webdevelopment;
