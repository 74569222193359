import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/4.jpg";
import { GrFormAdd } from "react-icons/gr";
import "./ServicesDetails.css";
import { Link } from "react-router-dom";
import Feature from "./Feature";
import Sidebar from "./Sidebar";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import StickyBox from "react-sticky-box";
import bg2 from "../../../images/background/digital.jpg";
import NewCard from "./NewCard";
import { MdComputer } from "react-icons/md";
// import bg3 from "../../../images/background/digital1.jpg";
class Webdevelopment extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="Digital Marketing Development"
              activeMenu="Digital Marketing Development"
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl mx-auto">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div class="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <h2 class="title mt-30">DIGITAL MARKETING SERVICES</h2>
                    <p className="">
                      Gobeens Technology understands your marketing needs and helps
                      you succeed by bringing strategies, expertise and
                      execution to the areas that you require the most. From
                      workplace community to marketing performance and company's
                      growth, we have been lucky enough to be recognized for
                      some of the most sought-after successes in the business
                      realm.
                    </p>
                    <h2 class="title mt-30">
                      DIGITAL MARKETING & SEO SERVICES DELAWARE
                    </h2>
                    <p className="">
                      SEO services are search engine optimization services
                      intended to increase visibility and ultimately organic
                      search traffic to websites. These are often provided by
                      agencies or freelancers who are experts in all aspects of
                      SEO.
                    </p>
                    <Link to="/seo">
                      <button className="mt-20 dez-page  site-button primary">
                        Know More
                      </button>
                    </Link>
                    <div class="faq-section mt-10 lol2">
                      {/* collapse */}
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          ANALYTICAL APPROACH
                        </summary>
                        <p class="px-4 py-3">
                          Our Digital Marketing services include a comprehensive
                          consultation to help identify gaps, opportunities and
                          results in a detailed report that includes a project
                          plan with timelines and cost analysis. Our customized
                          plans are made up of quality services that will help
                          you get to your goal quickly and smoothly.
                        </p>
                      </details>
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                          DELIVER HIGH ROI
                        </summary>
                        <p class="px-4 py-3">
                          No Digital Marketing campaign is deemed successful
                          without yielding high ROI. Gobeens Technology’s strategical
                          and effective digital marketing services are
                          unequivocally result oriented by matching cutting-edge
                          digital marketing strategy with real-time business
                          concepts. Our campaigns deliver results that are
                          laser-focused on SEM, content, PPC and SEO. Let us
                          help you to fulfill your digital dreams.
                        </p>
                      </details>
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          PERSONALIZATION
                        </summary>
                        <p class="px-4 py-3">
                          We believe that our client’s marketing campaign should
                          be unique. Instead of using a “one size fits all”
                          approach, we analyzes every need along with an
                          appropriate marketing strategy. Our experts help our
                          clients to determine their goals and work accordingly
                          to achieve those goals.
                        </p>
                      </details>
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          OUR CULTURE
                        </summary>
                        <p class="px-4 duration-500 py-3">
                          We deliver the best to our clients. Gobeens Technology is an
                          organization where passion reigns and innovation
                          thrives. We always give our best to deliver the
                          exceptional experience to clients. Our belief: Big
                          success always comes with great experience.
                        </p>
                      </details>
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          WHY US?
                        </summary>
                        <p class="px-4 duration-500 py-3">
                          We take a custom approach for each and every client’s
                          campaign in order to make sure that our strategies
                          meet their unique business goals and objectives.
                          Whether you want to drive more leads or need to
                          increase your online revenue or you simply want to
                          ensure your brand is getting the maximum visibility
                          possible online, Gobeens Technology can help you reach
                          there! No matter what the situation is, our team takes
                          a data-driven and ROI-focused approach in helping you
                          grow your business.
                        </p>
                      </details>
                    </div>
                    {/* Card New */}
                    {/* <span className=" row">
                      <div className=" col-sm-4 col-md-6">
                        <div
                          class="card mb-10"
                          style={{ width: "20rem", height: "200px" }}
                        >
                          <div class="card-body">
                            <h7 class="">SEARCH ENGINE OPTIMIZATION (SEO)</h7>
                            <p class="card-text">
                              Within 3-4 months your website will be on first
                              page of search engines. Try us today.
                            </p>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">EMAIL MARKETING</h7>
                              <p class="card-text">
                                Email marketing is a powerful tool that can be
                                used to build brand loyalty, gain insight into
                                your customer behavior and drive sales. Let us
                                help you get started!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">SOCIAL MEDIA MANAGEMENT</h7>
                              <p class="card-text">
                                We create and manage top-performing social media
                                campaigns / accounts / posts for our clients.
                                This covers all Social Media platforms.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card "
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">
                                ORGANIC GROWTH IN FOLLOWERS COUNT
                              </h7>
                              <p class="card-text">
                                100% Organic followers on your Twitter &
                                Instagram accounts. No bots, no fake accounts.
                                See results in a week or get your money back.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card "
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">CONTENT MARKETING</h7>
                              <p class="card-text">
                                Companies that use content marketing can get six
                                times more lead conversions compared to their
                                competitors. Try us for this.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div
                            class="card "
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">SOCIAL MEDIA MANAGEMENT</h7>
                              <p class="card-text">
                                Maximize your sales B2B/B2C by deploying Gobeens
                                Global for your lead generation campaigns. Sit
                                back and relax, let us perform it for you
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 ">
                        <div
                          class="card mb-10"
                          style={{ width: "20rem", height: "200px" }}
                        >
                          <div class="card-body">
                            <h7 class="">SOCIAL MEDIA ADVERTISING</h7>
                            <p class="card-text">
                              Whether you are looking to enhance your brand's SM
                              presence or drive targeted Sales/Leads through
                              ads, our SM advertising services can help you meet
                              your goals.
                            </p>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">REPUTATION MANAGEMENT</h7>
                              <p class="card-text">
                                Building trust, credibility, providing better
                                insights & increasing sales along with online
                                visibility. Helping clients stay ahead of
                                competitors.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">CONVERSION OPTIMIZATION</h7>
                              <p class="card-text">
                                Optimizing conversion rate is an essential part
                                of Digital Marketing Strategy . Gobeens Technology
                                will take care of this for you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">AFFILIATE MARKETING</h7>
                              <p class="card-text">
                                Affiliate Marketing is a process of earning
                                commission by promoting other people’s or
                                company’s products. You can count on us for such
                                services.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-10">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">PAY PER CLICK SERVICES (PPC)</h7>
                              <p class="card-text">
                                We offer PPC services with conversion in mind.
                                We make sure we deliver the best. Our main focus
                                is always on increasing profitability.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div
                            class="card"
                            style={{ width: "20rem", height: "200px" }}
                          >
                            <div class="card-body">
                              <h7 class="">RE-TARGETING & RE-MARKETING</h7>
                              <p class="card-text">
                                Yes, this works! However, needs to be done in
                                the right way. Increase leads and sales with our
                                Re-Targeting & Re-Marketing strategies.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span> */}
                    <NewCard data={DigitalData} />
                  </div>
                  {/* <Feature /> */}
                  <div class="faq-section mt-40">
                    {/* collapse */}

                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        What is digital marketing?
                      </summary>
                      <p class="px-4 py-3">
                        Digital marketing includes all those forms of
                        advertising and marketing that make use of the internet.
                        These include search engine optimization (SEO), social
                        media advertising, email marketing, pay per click
                        services, content marketing besides others.
                      </p>
                    </details>
                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                        What is a digital marketing company?
                      </summary>
                      <p class="px-4 py-3">
                        A digital marketing company is a business that provides
                        assistance to other companies (businesses) in their
                        online marketing efforts. The assistance includes
                        forming a marketing strategy and formulating of custom
                        marketing campaigns for various online marketing
                        platforms, executing them, analyzing their level of
                        success and refining the future campaigns based on the
                        insights gained from the analysis.
                      </p>
                    </details>
                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        What are the gains of digital marketing?
                      </summary>
                      <p class="px-4 py-3">
                        Digital marketing provides businesses various gains in
                        terms of fostering brand development, building a
                        positive online image and reputation of their business
                        and increased customer engagement, loyalty and
                        retention. Digital marketing also enables businesses to
                        attract qualified traffic to their website and generate
                        increased conversions, sales and revenue. Also, they are
                        able to gain an edge over their rival businesses and
                        achieve an improved return on investment.
                      </p>
                    </details>
                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        How often should I post or blog?
                      </summary>
                      <p class="px-4 duration-500 py-3">
                        To catch the attention of customers in your industry,
                        your business needs to have an active presence on the
                        web. It is your sole discretion how many posts or blogs
                        you should post in a week. Once you plan the frequency
                        of weekly posting, you need to stick to it. Your
                        audience will appreciate the regularity and this in turn
                        will improve the level of customer engagement.
                      </p>
                    </details>
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>

          <Pricing />
          {/* Bottom Form */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const DigitalData = [
  {
    title: "SEARCH ENGINE OPTIMIZATION (SEO)",
    desc: "Within 3-4 months your website will be on first page of search engines. Try us today.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "SOCIAL MEDIA ADVERTISING",
    desc: "Whether you are looking to enhance your brand's SM presence or drive targeted Sales/Leads through ads, our SM advertising services can help you meet your goals.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "EMAIL MARKETING",
    desc: "Email marketing is a powerful tool that can be used to build brand loyalty, gain insight into your customer behavior and drive sales. Let us help you get started!",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "REPUTATION MANAGEMENT",
    desc: "Building trust, credibility, providing better insights & increasing sales along with online visibility. Helping clients stay ahead of competitors.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "SOCIAL MEDIA MANAGEMENT",
    desc: "We create and manage top-performing social media campaigns / accounts / posts for our clients. This covers all Social Media platforms.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "CONVERSION OPTIMIZATION",
    desc: "Optimizing conversion rate is an essential part of Digital Marketing Strategy . Gobeens Technology will take care of this for you.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "ORGANIC GROWTH IN FOLLOWERS COUNT",
    desc: "100% Organic followers on your Twitter & Instagram accounts. No bots, no fake accounts. See results in a week or get your money back.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "AFFILIATE MARKETING",
    desc: "Affiliate Marketing is a process of earning commission by promoting other people’s or company’s products. You can count on us for such services.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "CONTENT MARKETING",
    desc: "Companies that use content marketing can get six times more lead conversions compared to their competitors. Try us for this.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "PAY PER CLICK SERVICES (PPC)",
    desc: "We offer PPC services with conversion in mind. We make sure we deliver the best. Our main focus is always on increasing profitability.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "SOCIAL MEDIA MANAGEMENT",
    desc: "Maximize your sales B2B/B2C by deploying Gobeens Technology for your lead generation campaigns. Sit back and relax, let us perform it for you",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
  {
    title: "RE-TARGETING & RE-MARKETING",
    desc: "Yes, this works! However, needs to be done in the right way. Increase leads and sales with our Re-Targeting & Re-Marketing strategies.",
    icon: <MdComputer className="text-4xl" />,
    no: "01",
  },
];
export default Webdevelopment;
