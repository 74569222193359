import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/5.jpg";
import { GrFormAdd } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import "./ServicesDetails.css";
// import { Link } from "react-router-dom";
import View from "./View";
import Sidebar from "./Sidebar";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import bg2 from "../../../images/background/aap.jpg";
import bg3 from "../../../images/background/app2.jpg";
import StickyBox from "react-sticky-box";
import angular from "../../../images/Custom App Development/angular.png";
import Bootstrap from "../../../images/Custom App Development/Bootstrap.png";
import html from "../../../images/Custom App Development/html.png";
import Ionic from "../../../images/Custom App Development/Ionic.png";
import js from "../../../images/Custom App Development/js.png";
import next from "../../../images/Custom App Development/next.png";
import react from "../../../images/Custom App Development/react.png";
import tailwind from "../../../images/Custom App Development/tailwind.png";
import typescript from "../../../images/Custom App Development/typescript.png";
import vue from "../../../images/Custom App Development/Vue.png";
import NewCard from "./NewCard";

class App extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="Custom App Development"
              activeMenu="Custom App Development"
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl  ">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div class="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <h2 class="title ">Custom App Development</h2>
                    <p className="mb-20">
                      As a leading custom application development organization
                      with multiple years of involvement in this field, Gobeens
                      Global makes exceptionally secure, scalable, and robust
                      applications worldwide. Our bespoke customized application
                      development service is accessible for new businesses,
                      SMEs, and ventures from different industry verticals. Get
                      custom-fitted applications worked by our experienced
                      developers to counter your complicated business challenges
                      effortlessly.
                    </p>
                    <div className="mb-20 ">
                      <h2 class="title lol5">
                        OFFERING FULLY-OPTIMIZED SOLUTIONS
                      </h2>
                      <div className=" loll">
                        <span className="margin">
                          <p>1. Qualified Web Resources</p>

                          <p>2. Cutting Edge Facility</p>

                          <p>3. Normalized Approach</p>
                        </span>
                        <span>
                          <p>4. Client-Centric Solutions</p>

                          <p>5. Demonstrated Track Record</p>

                          <p>6. Approved Credentials</p>
                        </span>
                      </div>
                    </div>
                    {/* What we Offer */}
                    <div className="my-10">
                      <h2>What Technology we Offer</h2>
                      <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                        {WeOfferTechnology.map((e) => {
                          return (
                            <div className="bg-white rounded-3xl hover:shadow-md duration-300 cursor-pointer shadow-xl">
                              <img className="  w-56" src={e} alt="" />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="">
                      <h3 className="lol5  ">Our Service</h3>
                      {/* <span className=" row ">
                      <div className="col-md-6 col-sm-3">
                        <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                          <div class="card-bodyy">
                            <img
                              src={Web}
                              style={{height:"350px"}}
                              alt=""
                            />
                            <h7 class="">CUSTOMIZED WEB DEVELOPMENT</h7>
                            <p class="card-text">
                              Our custom web development service incorporates
                              both back-end and front-end improvement. Whether
                              it is upgrading a current application or curating
                              a fresh one, our designers are up for the test.
                            </p>
                          </div>
                        </div>
                        <div className="lol1">
                          <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                            <div class="card-bodyy">
                              <img
                                src={Portal}
                               
                                alt=""
                              />
                              <h7 class="">WEB PORTAL<br/> DEVELOPMENT</h7>
                              <p class="card-text">
                                In the hood of web portal development service,
                                our team at Gobeens Technology approaches the portal
                                architecture to give different solutions. Our
                                web portal development services ensure to
                                execute each idea of yours in your development
                                ventures.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="lol1">
                          <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                            <div class="card-bodyy">
                              <img
                                src={Application}
                                alt=""
                              />
                              <h7 class="">
                                ENTERPRISE APPLICATION DEVELOPMENT
                              </h7>
                              <p class="card-text">
                                Gobeens Technology is an award-winning application
                                development organization that caters to
                                different customers’ needs across many
                                enterprises. We’ve constructed web and mobile
                                applications that regularly enable them with
                                specialized services and products.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-3">
                        <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                          <div class="card-bodyy">
                            <img
                              src={Custom}
                              style={{height:"350px"}}
                              alt=""
                            />
                            <h7 class="">PROGRESSIVE CUSTOM APPS</h7>
                            <p class="card-text">
                              The current web capability to deliver a concrete
                              application that is reliable and customer engaging
                              is conceivable by Progressive Web App Development
                              Company. We at Gobeens Technology articulate the assets
                              to bring the best and the required solution.
                            </p>
                          </div>
                        </div>
                        <div className="lol1">
                          <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                            <div class="card-bodyy">
                              <img
                                src={App1}
                                className=""
                                alt=""
                              />
                              <h7 class="">CROSS-PLATFORM <br/>APPS</h7>
                              <p class="card-text">
                                Cross-platform application development alludes
                                to the advancement of mobile applications that
                                can get used on various portable stages. Our
                                specialists make powerful, quality rich,
                                versatile, and intuitive portable applications
                                that offer a “one-code-fits-all” facility.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="lol2">
                          <div class="shadow-lg p-3 mb-5 bg-body rounded" >
                            <div class="card-bodyy">
                              <img
                                src={Party}
                                alt=""
                              />
                              <h7 class="">
                                THIRD-PARTY INTEGRATION & CUSTOMIZATION
                              </h7>
                              <p class="card-text">
                                With many years of involvement with Custom
                                Software Development, the specialized group of
                                Gobeens Technology has made various applications for a
                                reputed client base.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span> */}
                      <NewCard data={OurService} />
                    </div>
                    {/* <View /> */}
                    <div class="row align-items-center justify-content-center container">
                      <div class="col-lg-6">
                        <div class="image text-center mb-40">
                          <img src={bg3} className="w-full" alt="" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <h3 class="sub-title">OUR WORK PROCESS</h3>
                        <p>
                          At Gobeens Technology, we undertake an organized and
                          efficient app development process that includes steps
                          like specifying and understanding the app idea,
                          visualizing the app, graphically designing it and
                          coding and programming the app.
                        </p>
                        <ul class="check-list space-y-3s mt-30 mb-40 ">
                          <li className="flex items-center">
                            <TiTick class="design" />
                            SPECIFICATION AND PLANNING
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            HIGHLIGHTING THE FEATURES
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            DEVELOPING THE APPLICATION
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            QUALITY CONFIRMATION
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            MAKING THE WEBSITE LIVE
                          </li>
                        </ul>
                      </div>
                      <div class="faq-section mt-40">
                        {/* collapse */}
                        {/* <div
                          class="accordion faq-loop border-style"
                          id="faqAccordion"
                        >
                          <div class="card">
                            <div class="card-header">
                              <h6
                                class="collapsed"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                              >
                                Can I schedule a call for discussing my app idea
                                with your experts and for understanding your
                                technical capabilities?
                                <span class="icons">
                                  <GrFormAdd />
                                </span>
                              </h6>
                            </div>
                            <div
                              id="collapseOne"
                              class="collapse"
                              data-parent="#faqAccordion"
                            >
                              <div class="card-body">
                                Yes, our experts are available for a call or
                                email to discuss your app idea. You can choose
                                one of these contact modes as per your
                                convenience.
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header ">
                              <h6
                                class="collapsed"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                              >
                                Do you also build progressive web apps and
                                enterprise applications?
                                <span class="icons">
                                  <GrFormAdd />
                                </span>
                              </h6>
                            </div>

                            <div
                              id="collapseTwo"
                              class="collapse"
                              data-parent="#faqAccordion"
                            >
                              <div class="card-body">
                                Our professionals have the skills, knowledge,
                                expertise and resources to build high quality
                                and tailor-made enterprise applications and
                                progressive web apps that bring businesses
                                various benefits like ability to work
                                everywhere, ability to operate offline and
                                automatic updation..
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header">
                              <h6
                                class="collapsed"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                              >
                                What control do I have over my custom app
                                development project?
                                <span class="icons">
                                  <GrFormAdd />
                                </span>
                              </h6>
                            </div>

                            <div
                              id="collapseThree"
                              class="collapse"
                              data-parent="#faqAccordion"
                            >
                              <div class="card-body">
                                <p>
                                  You will get complete control over your
                                  project. We will assign a dedicated project
                                  manager to your project who will be your
                                  single-point-of-contact during the entire
                                  duration of your project. Moreover, he will
                                  work closely with your guidelines.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header">
                              <h6
                                class="collapsed"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                              >
                                How do you ensure confidentiality over my app
                                development?
                                <span class="icons">
                                  <GrFormAdd />
                                </span>
                              </h6>
                            </div>
                            <div
                              id="collapseFour"
                              class="collapse"
                              data-parent="#faqAccordion"
                            >
                              <div class="card-body">
                                All our employees are full-time employees and
                                they are bound by our company’s confidentiality
                                and non-disclosure clauses.
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div class="faq-section mt-40">
                          <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                            <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                              What are the different types of mobile apps that
                              your developers can build?
                            </summary>
                            <p class="px-4 py-3">
                              Our seasoned developers are skilled at building
                              mobile apps for both Android and iOS phones and
                              tablets. They are also adept at building mobile
                              apps for Windows and Phonegap mobile platforms.
                            </p>
                          </details>

                          <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                            <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                              What is the difference between Cross-Platform App
                              Development and Native App Development?
                            </summary>
                            <p class="px-4 py-3">
                              Certainly, there is a big difference between
                              Cross-Platform and Native App development. In
                              native app development, separate apps are built
                              for the Android and iOS platforms. In
                              cross-platform development, a single app functions
                              on both platforms.
                            </p>
                          </details>

                          <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                            <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                              Will you sign an NDA for the app you are building?
                            </summary>
                            <p class="px-4 py-3">
                              Yes, we will sign the non-disclosure agreement
                              (NDA) while we are developing the mobile app for
                              your business. You can confidently rely on us to
                              keep your development details confidential and
                              protect them from your competitors.
                            </p>
                          </details>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>

          {/* <Pricing /> */}
          {/* Bottom Form */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const OurService = [
  {
    title: "CUSTOMIZED WEB DEVELOPMENT    ",
    desc: "Our custom web development service incorporates both back-end and front-end improvement. Whether it is upgrading a current application or curating a fresh one, our designers are up for the test.    ",
  },
  {
    title: "PROGRESSIVE CUSTOM APP    ",
    desc: "The current web capability to deliver a concrete application that is reliable and customer engaging is conceivable by Progressive Web App Development Company. We at Gobeens Technology articulate the assets to bring the best and the required solution.    ",
  },
  {
    title: "WEB PORTAL DEVELOPMENT    ",
    desc: "In the hood of web portal development service, our team at Gobeens Technology approaches the portal architecture to give different solutions. Our web portal development services ensure to execute each idea of yours in your development ventures.    ",
  },
  {
    title: "CROSS-PLATFORM APPS ",
    desc: "Cross-platform application development alludes to the advancement of mobile applications that can get used on various portable stages. Our specialists make powerful, quality rich, versatile, and intuitive portable applications that offer a “one-code-fits-all” facility. ",
  },
  {
    title: "ENTERPRISE APPLICATION DEVELOPMENT",
    desc: "Gobeens Technology is an award-winning application development organization that caters to different customers’ needs across many enterprises. We’ve constructed web and mobile applications that regularly enable them with specialized services and products.",
  },
  {
    title: "THIRD-PARTY INTEGRATION & CUSTOMIZATION",
    desc: "With many years of involvement with Custom Software Development, the specialized group of Gobeens Technology has made various applications for a reputed client base.",
  },
];

const WeOfferTechnology = [
  react,
  angular,
  vue,
  next,
  typescript,
  js,
  Ionic,
  Bootstrap,
  tailwind,
  html,
];
export default App;
