import React from "react";
import Footer from "../../../Layout/Footer";
import Header from "../../../Layout/Header";
import PageTitle from "../../../Layout/PageTitle";
import bnr5 from "../../../../images/banner/4.jpg";

const IwatchApps = () => {
  return (
    <>
      <Header />
      <section>
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <PageTitle
            motherMenu="Iwatch App Development"
            activeMenu="Iwatch App Development"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default IwatchApps;
