import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ThemeButton from "./Element/ThemeButton/ThemeButton";
import Homepage from "./Pages/Homepage";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Contact from "./Pages/Contact";
import Service from "./Pages/Service/Service";
import WebDevelopment from "./Pages/Service/WebDevelopment";
import App from "./Pages/Service/App";
import Aboutus from "./Pages/Aboutus/Aboutus";
import BlogListRightSidebar from "./Pages/Blog/Blog";
import BlogList from "./Pages/Blog/BlogDetails";
import Portfolio from "./Pages/Protfolio/Portfolio";
import PortfolioDetails from "./Pages/Protfolio/PortfolioDetails";
import Career from "./Pages/Career/Career";
import Jobs from "./Pages/Job/Jobs";
import JobsDetails from "./Pages/Job/JobsDetails";
import ScrollToTop from "./Element/ScrollToTop";
import Faqs from "./Pages/Faqs";
import Ecommerce from "./Pages/Service/Ecommerce";
import Mobile from "./Pages/Service/Mobile";
import Outsourcing from "./Pages/Service/Outsourcing";
import DigitalMarketing from "./Pages/Service/DigitalMarketing";
import SEO from "./Pages/Service/Searchengin.js";
import SMM from "./Pages/Service/SMM.js";
import Privacy from "./Layout/Privacy";
import IphoneApps from "./Pages/Service/AllMobileApps/IphoneApps";
import IpadApps from "./Pages/Service/AllMobileApps/IpadApps";
import WindowApps from "./Pages/Service/AllMobileApps/WindowApps";
import IwatchApps from "./Pages/Service/AllMobileApps/IwatchApps";
import AndroidApps from "./Pages/Service/AllMobileApps/AndroidApps";
import PhoneGapApps from "./Pages/Service/AllMobileApps/PhoneGapApps";
class Markup extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="page-wraper">
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/logins" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/services" exact component={Service} />
            {/* <Route path="/web" exact component={WebDevelopment} /> */}
            <Route path="/custom-app-development" exact component={App} />
            <Route path="/blog" exact component={BlogListRightSidebar} />
            <Route path="/web-development" exact component={WebDevelopment} />
            <Route path="/out-sourcing" exact component={Outsourcing} />
            <Route path="/e-commerce" exact component={Ecommerce} />
            <Route path="/mobile-app-development" exact component={Mobile} />
            <Route
              path="/digital-marketing"
              exact
              component={DigitalMarketing}
            />
            <Route path="/blog/:title" exact component={BlogList} />
            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/privacy-policy" exact component={Privacy} />

            <Route
              path="/portfoliodetails"
              exact
              component={PortfolioDetails}
            />
            <Route path="/career" exact component={Career} />
            <Route path="/Jobs" exact component={Jobs} />
            <Route path="/Jobs-details" exact component={JobsDetails} />
            <Route path="/aboutus" exact component={Aboutus} />
            <Route path="/faq" exact component={Faqs} />
            <Route path="/seo" exact component={SEO} />
            <Route path="/smm" exact component={SMM} />

            {/* APPS Route */}
            <Route
              path="/iphone-apps-development"
              exact
              component={IphoneApps}
            />
            <Route path="/ipad-apps-development" exact component={IpadApps} />
            <Route
              path="/windows-apps-development"
              exact
              component={WindowApps}
            />
            <Route
              path="/iwatch-apps-development"
              exact
              component={IwatchApps}
            />
            <Route
              path="/android-apps-development"
              exact
              component={AndroidApps}
            />
            <Route
              path="/phonegap-apps-development"
              exact
              component={PhoneGapApps}
            />
          </Switch>
        </div>
        <ScrollToTop />
        <ThemeButton />
      </BrowserRouter>
    );
  }
}

export default Markup;
