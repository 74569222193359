import React from "react";
import "./Pricing.css";

const Seoservice = () => {
  return (
    <>
      <section class="pricing py-5">
        <div class="container">
          <h2 className="text-center ">What our SEO Package includes</h2>
          <div class="grid mt-10  md:grid-cols-3 gap-5">
            {/* <!--Technical SEO--> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>Technical SEO</h5>
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className=" flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Manual Audit</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>Automation Audit</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> 270+audit checklist</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Mobile Friendliness</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Schema Implementation</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Sitemap and Robots.txt Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Desktop Page Speed</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Mobile Page Speed</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Site Architecture</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Crawl Optimization</h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        XML Sitemap Creation & Submission
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- On Page SEO --> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>On Page SEO</h5>
                  {/* <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6> */}
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword Research</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword to URL Mapping</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Header Tags Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Internal Linking Strategy
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Meta Tags Optimization</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Image and Video Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        URL Structure Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Canonicals and Redirects
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        Title & Meta Tags Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Top Navigation & Footer Links Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> User Engagement</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Off Page SEO --> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>On Page SEO</h5>
                  {/* <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6> */}
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword Research</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword to URL Mapping</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Header Tags Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Internal Linking Strategy
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Meta Tags Optimization</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Image and Video Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        URL Structure Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Canonicals and Redirects
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        Title & Meta Tags Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Top Navigation & Footer Links Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> User Engagement</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Content --> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>On Page SEO</h5>
                  {/* <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6> */}
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword Research</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword to URL Mapping</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Header Tags Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Internal Linking Strategy
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Meta Tags Optimization</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Image and Video Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        URL Structure Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Canonicals and Redirects
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        Title & Meta Tags Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Top Navigation & Footer Links Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> User Engagement</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Analysis--> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>On Page SEO</h5>
                  {/* <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6> */}
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword Research</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword to URL Mapping</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Header Tags Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Internal Linking Strategy
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Meta Tags Optimization</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Image and Video Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        URL Structure Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Canonicals and Redirects
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        Title & Meta Tags Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Top Navigation & Footer Links Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> User Engagement</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Reporting--> */}
            <div class={Style.mainDiv}>
              <div class="">
                <div class="">
                  <h5 class={Style.toph5}>On Page SEO</h5>
                  {/* <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6> */}
                  <hr className="border-gray-400 group-hover:border-white" />
                  <ul class="">
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword Research</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Keyword to URL Mapping</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Header Tags Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Internal Linking Strategy
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> Meta Tags Optimization</h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Image and Video Optimization
                      </h5>
                    </li>
                    <li className="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        URL Structure Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Canonicals and Redirects
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        Title & Meta Tags Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}>
                        {" "}
                        Top Navigation & Footer Links Optimization
                      </h5>
                    </li>
                    <li class="flex">
                      <span class="text-black mr-2">
                        <i class="fas group-hover:text-[#FF3300] fa-check"></i>
                      </span>
                      <h5 className={Style.listh5}> User Engagement</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <button className="site-button text-center">Get In TOUCH</button>
          </div>
        </div>
      </section>
    </>
  );
};

const Style = {
  mainDiv:
    "shadow-lg group cursor-pointer hover:shadow-2xl duration-500 rounded-xl border hover:bg-[#0000ff] p-4",
  toph5: "text-uppercase text-center group-hover:text-white duration-500",
  listh5:
    "text-sm group-hover:text-white duration-500 font-normal tracking-wide",
};

export default Seoservice;
