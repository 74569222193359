import React from "react";
import "./Pricing.css";

const Pricing = () => {
  return (
    <>
      <section class="pricing py-5">
        <div class="container">
          <h2 className="text-center ">Pricing Table</h2>
          <div class="row">
            {/* <!-- Free Tier --> */}
            <div class="col-lg-4">
              <div class="card12 mb-5 mb-lg-0">
                <div class="card-bodyss p-4 group">
                  <h5 class=" text-muted text-uppercase text-center">Free</h5>
                  <h6 class="card-price text-center">
                    $0<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Single User
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      5GB Storage
                    </li>
                    <li className="">
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li class="text-gray-300 group-hover:text-gray-400">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li class="text-gray-300 group-hover:text-gray-400">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li class="text-gray-300 group-hover:text-gray-400">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Free Subdomain
                    </li>
                    <li class="text-gray-300 group-hover:text-gray-400">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid ">
                    <a
                      href="/#"
                      class="text-white text-center p-2 rounded-lg bg-[#4C9DE2] group-hover:bg-[#FF3300] text-uppercase"
                    >
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Plus Tier --> */}
            <div class="col-lg-4">
              <div class="card12 mb-5 mb-lg-0">
                <div class="card-bodyss p-4 group">
                  <h5 class=" text-muted text-uppercase text-center">Plus</h5>
                  <h6 class="card-price text-center">
                    $9<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>5 Users</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      50GB Storage
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Free Subdomain
                    </li>
                    <li class="text-gray-300 group-hover:text-gray-400">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/#" class="text-white text-center p-2 rounded-lg bg-[#4C9DE2] group-hover:bg-[#FF3300] text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Pro Tier --> */}
            <div class="col-lg-4">
              <div class="card12">
                <div class="card-bodyss group p-4 hover:text-white">
                  <h5 class=" text-muted text-uppercase text-center">Pro</h5>
                  <h6 class="card-price text-center">
                    $49<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Unlimited Users</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      150GB Storage
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Unlimited</strong> Free Subdomains
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/#" class="text-white text-center p-2 rounded-lg bg-[#4C9DE2] group-hover:bg-[#FF3300] text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
