import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Counter from "./Counter";

// import bg1 from './../../images/background/bg1.jpg';

const iconBlog = [
  {img:("https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg"), title: "WEBSITE DESIGN & DEVELOPMENT" },
  { img: "	https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg", title: "APPLICATION DEVELOPMENT" },
  { img: "	https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg", title: "MOBILE APPLICATIONS​" },
  {img: "	https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg", title: "E-COMMERCE" },
  { img: "	https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg", title: "DIGITAL MARKETING" },
  {img: "	https://webtend.net/demo/html/finsa/assets/img/feature/02.jpg", title: "BRANDING" },
];

class SectionCounter extends Component {
  render() {
    return (
      <Fragment>
        <div>
          <div className="container">
            <div className="section-head text-black text-center">
              <h2 className="box-title m-tb0 max-w800 m-auto">
                Popular Service<span className="bg-primary"></span>
              </h2>
              <p>Our Awesome Services To Grow Your Business</p>
            </div>
          </div>
          <div className="container">
            <div className="row text-black">
              {iconBlog.map((item, index) => (
                <div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
                  <div className="icon-bx-wraper bx-style-1 p-a30 center">
                    <div className="relative m-b20 hight-100vh">
                      {" "}
                      <Link to={"#"} className=" text-black">
                        <img src={item.img} alt=""/>
                      </Link>{" "}
					  <div className=" text-black">
                      {/* <h5 className="dlab-tilte text-uppercase "> */}
                        <h4 className="text-bold">
						{item.title}
                      {/* </h5> */}
					  </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod..
                      </p>
                    </div>
                    </div>
                    {/* <div className="icon-content"> */}
					
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="choses-info text-white">
            <Counter />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default SectionCounter;
