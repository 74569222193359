import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import SectionCounter from "../../Element/SectionCounter";
import TestimonialCarousel from "../../Element/TestimonialCarousel";
import FormStyle from "../../Element/FormStyle";

//Images
import about9 from "./../../../images/about/pic13.jpg";
import bg2 from "./../../../images/background/bg-map.jpg";
import services1 from "./../../../images/our-services/pic2.jpg";
import services2 from "./../../../images/our-services/pic1.jpg";
import bg1 from "./../../../images/background/bg1.jpg";

import bnr from "./../../../images/banner/bnr16.jpg";
import SectionCounterNew from "../../Element/SectionCounterNew";

class Aboutus1 extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr + ")" }}
          >
            <PageTitle motherMenu="About Us" activeMenu="About Us" />
          </div>
          <div className="content-block">
            <div className="section-full content-inner-2">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h4 className="text-gray-dark m-b10">About Us</h4>
                  <h2 className="box-title m-tb0">
                    Make your customers happy by giving services.
                    <span className="bg-primary"></span>
                  </h2>
                  <p>
                    The word ‘Gobeens’ means ‘Promise’. Gobeens Technology is a CANDID
                    IT Consulting / Digital Marketing company with a host of
                    services to offer under one umbrella. Founded in the year
                    2019 by a couple of IT enthusiasts who believe in making
                    something big. Today, Gobeens Technology is serving more than 100+
                    Clients across the globe. We are a bunch of interesting
                    people who work day in and day out to achieve the goals of
                    our clients.
                  </p>
                </div>
              </div>
              <div className="container">
                <div className="row ">
                  <div className="col-lg-5 col-md-4 about-img">
                    <img src={about9} data-tilt alt="" />
                  </div>
                  <div className="col-lg-7 col-md-8">
                    <div className="abuot-box left row m-lr0 ">
                      <div className="col-lg-4">
                        <h2 className="box-title m-tb0">
                          About Us<span className="bg-primary"></span>
                        </h2>
                        <h4 className="text-gray-dark">
                          Make your customers happy by giving services.
                        </h4>
                      </div>
                      <div className="col-lg-8">
                        <p>
                          At Gobeens Technology, fulfilling each and every requirement
                          of yours is our top-most priority. We ensure that all
                          your needs are accounted for and properly addressed.
                          Our clients trust and count on us to keep their
                          operations up and running 24x7x365.
                        </p>

                        <p className="m-b0">
                          Also the leap into electronic typesetting, remaining
                          essentially unchanged. It was popularised in the 1960s
                          with the release of Letraset sheets containing Lorem
                          Ipsum passages.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Counetr section */}
            <SectionCounterNew />
            {/* Counetr section End*/}
            <VisionBlog />

            {/* <!-- Testimonials --> */}
            <div
              className="section-full content-inner"
              style={{
                backgroundImage: "url(" + bg2 + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="box-title m-tb0">
                    Our Testimonials<span className="bg-primary"></span>
                  </h2>
                  <p>
                    {" "}
                   
                  </p>
                </div>
                <div className="section-content m-b30 ">
                  <TestimonialCarousel />
                </div>
              </div>
            </div>
            {/* <!-- Testimonials END --> */}
            <div
              className="section-full overlay-primary-dark bg-img-fix"
              style={{ backgroundImage: "url(" + bg1 + ")" }}
            >
              <FormStyle />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
function VisionBlog() {
  return (
    <>
      <div
        className=" "
        style={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div style={{marginTop : 50}} className="container">
          <div className="section-head text-center">
            <h2 className="">
              Mission And Vision<span className="bg-primary"></span>
            </h2>
            <p>
              {" "}
              Our core work strategy is instituted on a meticulous understanding
              of every client’s needs, ever changing work requirements and
              overall budget constraints. By using the expertise and profound
              technological background, Gobeens creates an exhaustive web strategy
              for digital transformation of your organization as per your
              business objectives. You can count on our IT consulting and
              in-depth market knowledge that will give you an edge over others
              and help you program and digitize operations, optimize company’s
              portfolio, and realize your mission and vision.
            </p>
          </div>
          <div className=" row dzseth  m-b30">
            <div className="col-lg-6 col-md-12 m-b30 about-img ">
              <img src={services1} data-tilt alt="" />
            </div>
            <div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
              <div className="dis-tbl-cell">
                <h3 className="box-title">
                  Mission<span className="bg-primary"></span>
                </h3>
                <p className="font-16">
                  To become the one and only choice of our clients by providing
                  them with the top-notch services. Gaining their trust by
                  delivering innovative products and adding value to
                  organizations with utmost satisfaction and accuracy.
                </p>
              </div>
            </div>
          </div>
          <div className="row dzseth column-reverse">
            <div className="col-lg-6 col-md-12 dis-tbl text-justify">
              <div className="dis-tbl-cell">
                <h3 className="box-title">
                  Vision<span className="bg-primary"></span>
                </h3>
                <p className="font-16">
                  We work untiringly to make sure that we keep our promises and
                  make our efforts in a positive direction. Help our clients in
                  achieving their cause and create a difference in the world by
                  contributing to the betterment of the society at large.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 about-img ">
              <img src={services2} data-tilt alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { VisionBlog };
export default Aboutus1;
