import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

/* function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick}/>
	</div>
  );
} */

class EventSlider extends Component {
  render() {
    var settings = {
      arrows: false,
      slidesToShow: 4,
      speed: 1500,
      navSpeed: 1500,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <Slider
        className="img-carousel-content owl-carousel owl-none"
        {...settings}
      >
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="item-service-content m-b40">
              <h5>WEBSITE DESIGN & DEVELOPMENT</h5>
              <p className="m-b0">
                Designs crafted and built by experienced professionals with customer needs in mind . We Are the Best Web Development Team. Gobeens Technology.<br/><br/>
              </p>
            </div>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="icon-bx-md radius border-1 m-t40 m-b20">
              <span className="icon-cell">
                <i className="flaticon-devices"></i>
              </span>
            </div>
            <Link to={"/services"} className="site-button outline outline-2">
              <span className="font-weight-500">Read More</span>
            </Link>
          </div>
        </div>
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="icon-bx-md radius border-1 m-b20 m-t0">
              <span className="icon-cell">
                <i className="flaticon-pen"></i>
              </span>
            </div>
            <Link
              to={"/services"}
              className="site-button outline outline-2 m-b40"
            >
              <span className="font-weight-500">Read More</span>
            </Link>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="item-service-content m-t40">
              <h5>APPLICATION DEVELOPMENT</h5>
              <p className="m-b0">
                Our Custom App development provides users with unique SaaS experience that integrates with AI and Virtual Reality. We Are the Best Application Team<br/><br/>
              </p>
            </div>
          </div>
        </div>
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="item-service-content m-b40">
              <h5>MOBILE APPLICATIONS​</h5>
              <p className="m-b0">
                Our mobile app development provides mobile e-commerce solutions, mobile wallet solutions that are W3C compliant. We Are the Best Mobile, Development Team. Gobeens Technology
              </p>
            </div>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="icon-bx-md radius border-1 m-t40 m-b20">
              <span className="icon-cell">
                <i className="flaticon-smartphone"></i>
              </span>
            </div>
            <Link to={"/services"} className="site-button outline outline-2">
              <span className="font-weight-500">Read More</span>
            </Link>
          </div>
        </div>
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="icon-bx-md radius border-1 m-b20 m-t0">
              <span className="icon-cell">
                <i className="flaticon-team"></i>
              </span>
            </div>
            <Link
              to={"/services"}
              className="site-button outline outline-2 m-b40"
            >
              <span className="font-weight-500">Read More</span>
            </Link>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="item-service-content m-t40">
              <h5>E-COMMERCE</h5>
              <p className="m-b0">
                Scale up your business with our comprehensive e-commerce
                development solutions and give your customers a stellar shopping experience. We Are the Best E-Commerce.
              </p>
            </div>
          </div>
        </div>
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="item-service-content m-b40">
              <h5>DIGITAL MARKETING</h5>
              <p className="m-b0">
                We offer result driven and highly professional Digital Marketing
                services to help you develop a robust online marketing strategy. We Are the Best Digital Marekting. Gobeens Technology
              </p>
            </div>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="icon-bx-md radius border-1 m-t40 m-b20">
              <span className="icon-cell">
                <i className="flaticon-smartphone"></i>
              </span>
            </div>
            <Link to={"/services"} className="site-button outline outline-2">
              <span className="font-weight-500">Read More</span>
            </Link>
          </div>
        </div>
        <div className="item p-1">
          <div className="box-item-service text-center">
            <div className="icon-bx-md radius border-1 m-b20 m-t0">
              <span className="icon-cell">
                <i className="flaticon-team"></i>
              </span>
            </div>
            <Link
              to={"/services"}
              className="site-button outline outline-2 m-b40"
            >
              <span className="font-weight-500">Read More</span>
            </Link>
            <div className="clearfix">
              <span className="text-primary round-center"></span>
            </div>
            <div className="item-service-content m-t40">
              <h5>BRANDING</h5>
              <p className="m-b0">
                Performance optimization techniques to increase traffic and converting it to leads and sales by making your business a brand. We Are the Best Branding Team. We Delaware What we Promise
              </p>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default EventSlider;
