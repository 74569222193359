import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/2.jpg";
import { GrFormAdd } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import "./ServicesDetails.css";
// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import StickyBox from "react-sticky-box";
import big from "./../../../images/ecommerce/big.jpg";
import mag from "./../../../images/ecommerce/mag.jpg";
import prestashop from "./../../../images/ecommerce/prestashop.png";
import sopify from "./../../../images/ecommerce/sopify.png";
import woo from "./../../../images/ecommerce/woo.jpg";
import ecommerce6 from "./../../../images/ecommerce/ecommerce6.jpg";
import ecommerce7 from "./../../../images/ecommerce/ecommerce7.jpg";
import ecommerce8 from "./../../../images/ecommerce/ecommerce8.jpg";
import ecommerce9 from "./../../../images/ecommerce/ecommerce9.jpg";
import ecommerce10 from "./../../../images/ecommerce/ecommerce10.jpg";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import Pricing from "./Pricing";
import bg2 from "../../../images/background/e-commerce.jpg";
import bg3 from "../../../images/background/e-commerce1.jpg";
class Ecommerce extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="E-commerce Web Design  "
              activeMenu="E-commerce Web Design  "
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl mx-auto  ">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div className="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <h3>PLATFORMS WE SUPPORT</h3>
                    <p className="lol1">
                      At Gobeens Technology, we understand that everyone is unique and
                      has different requirements. With over 10,000 man-hours of
                      experience in designing and developing storefronts, our
                      team of e-commerce experts works with every customer to
                      understand their needs and come up with a unique &
                      remarkable design. Our proposed designs are high in
                      conversions, extremely user friendly, and will give your
                      audience distinguished user experience. We foster
                      customers’ requirements from the beginning until the end
                      and help them in achieving their goals and increased ROI.
                    </p>
                    <div className="">
                      <h5 className="text-4xl">What do we Offer?</h5>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="">
                          <img src="" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="grid my-5 grid-cols-2 md:grid-cols-5 gap-4">
                      {WhatWeOffer.map((e) => {
                        return (
                          <div className="">
                            <img
                              className="rounded-3xl hover:shadow-md duration-300 cursor-pointer shadow-xl w-56"
                              src={e}
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                    {/* <span className=" row">
                      <div className="col-md-6 com-sm-6">
                        <div class="kid-space">
                          <div className=" card1 mb-20 contain shadow-lg">
                            <div className="kid ">
                              <img
                                src={ecommerce1}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce2} alt="Adult" />
                            </div>
                            <p className="contain">
                              Empower your customers with incredible shopping
                              experiences that offer the amazing options with
                              BigCommerce specific modules render.
                            </p>
                          </div>
                          <div className="card1 mb-20 contain shadow-lg">
                            <div className="kid">
                              <img
                                src={ecommerce7}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce8} alt="Adult" />
                            </div>
                            <p className="contain">
                              Give your e-commerce brand the Shopify advantage
                              by with an online store that is capable of driving
                              conversions and boosting sales for your business.
                            </p>
                          </div>
                          <div className="card1 contain shadow-lg">
                            <div className="kid">
                              <img
                                src={ecommerce3}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce4} alt="Adult" />
                            </div>
                            <p className="contain">
                              Give your e-commerce brand the Shopify advantage
                              by with an online store that is capable of driving
                              conversions and boosting sales for your business.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 com-sm-6">
                        <div class=" kid-space">
                          <div className=" card1 mb-20 contain shadow-lg">
                            <div className="kid ">
                              <img
                                src={ecommerce3}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce4} alt="Adult" />
                            </div>
                            <p className="contain">
                              Unlock the potential of Magneto to empower your
                              online business with an e-commerce store that is
                              feature rich, customizable, flexible, responsive
                              and secure.
                            </p>
                          </div>
                          <div className="card1 mb-20 contain shadow-lg">
                            <div className="kid">
                              <img
                                src={ecommerce5}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce6} alt="Adult" />
                            </div>
                            <p className="contain">
                              Bring new opportunities for your business by
                              availing the rich variant features of the tried
                              and tested PrestaShop development platform.
                            </p>
                          </div>
                          <div className=" card1  contain shadow-lg">
                            <div className="kid ">
                              <img
                                src={ecommerce9}
                                alt="Kid"
                                itemprop="image"
                              />
                              <img src={ecommerce10} alt="Adult" />
                            </div>
                            <p className="contain">
                              Collaborate with us for customer focused, brand
                              centric and sales optimized WooCommerce websites
                              that give your e-commerce business a winning edge.
                            </p>
                          </div>
                        </div>
                      </div>
                    </span> */}
                    <div class="row align-items-center justify-content-center container">
                      <div class="col-lg-6">
                        <div class="image text-center mb-40">
                          <img
                            className="w-full"
                            src={bg3}
                            // style={{ height: "350px", width: "350px" }}
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <h3 class="sub-title">WHY COLLABORATE WITH Gobeens?</h3>
                        <p>
                          Let our experts handle all your e-commerce needs and
                          give you the support that your business deserves. With
                          our top-notch development services and innovative
                          marketing techniques, we will help you to kick-start
                          your next growth curve.
                        </p>
                        <ul class="check-list space-y-3 mt-30 mb-40">
                          <li className="flex items-center">
                            <TiTick class="design" />
                            <p>UI/UX</p>
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            <p>Fully Customizable</p>
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            <p>Security</p>
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            <p>Fully Resposive</p>
                          </li>
                          <li className="flex items-center">
                            <TiTick class="design" />
                            <p>Branding</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* FAQ's */}
                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        Why is it important for business owners to develop an
                        Ecommerce website?
                      </summary>
                      <p class="px-4 py-3">
                        Due to busy schedules, people have much less time to
                        visit physical stores for purchasing the desired items.
                        Ecommerce websites let such people purchase the items
                        from their place or on the go through their mobile
                        phones or other devices. To catch the attention of such
                        an audience, inform them about your latest
                        products/services and marketing offers and inspire them
                        to buy your products online, you need to get built an
                        ecommerce website. It will help you capture a wider base
                        of ecommerce customers and tap lucrative ecommerce
                        selling opportunities.
                      </p>
                    </details>

                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                        What are the different kinds of ecommerce?
                      </summary>
                      <p class="px-4 py-3">
                        Ecommerce is differentiated on the basis of how business
                        transactions are conducted. The four main ways of doing
                        eCommerce business are Business to Business (B2B),
                        Business to Customer (B2C), Customer to Business (C2B)
                        and Customer to Customer (C2C).
                      </p>
                    </details>

                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        How can I choose the best and right fit platform for my
                        ecommerce business website?
                      </summary>
                      <p class="px-4 py-3">
                        Before starting with your custom ecommerce web
                        development, you need to consider a few fundamentals
                        that can help you choose the best platform. Some worth
                        considering points are items you are selling and whether
                        a given ecommerce platform can manage inventory tracking
                        and multiple product options. You should also assess the
                        available design options, website security, payment
                        gateways, integration with other tools and features and
                        pricing of an ecommerce platform. Rigorous analysis of
                        these parameters will help you in choosing the perfect
                        platform for your ecommerce website development.
                      </p>
                    </details>
                    <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                      <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                        Is there any limit on the size of my product or database
                        of customers?
                      </summary>
                      <p class="px-4 duration-500 py-3">
                        There are no limits on the size. An online ecommerce
                        store is flexible and scalable enough, as a result it
                        allows you to add an unlimited number of products and
                        catalogs. Also, the store enables you to grow your
                        customer base as required.
                      </p>
                    </details>
                    {/* FAQ's End */}
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>
          <Pricing />
          {/* Bottom Form */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const WhatWeOffer = [mag, sopify, prestashop, big, woo];
export default Ecommerce;
