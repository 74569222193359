import React, { Component } from "react";
import Slider from "react-slick";

//Images
// import pic1 from "./../../images/testimonials/pic1.jpg";
// import pic2 from "./../../images/testimonials/pic2.jpg";
// import pic3 from "./../../images/testimonials/pic3.jpg";
// import Videoimg from "./../../images/video/pic1.jpg";

class TestimonialCarousel extends Component {
  render() {
    var settings = {
      arrows: false,
      slidesToShow: 3,
      infinite: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider
          className="testimonial-box center-active-owl item-center owl-loaded owl-carousel owl-none  owl-dots-black-full "
          {...settings}
        >
          {Data.map((e, index) => {
            return (
              <div key={index} className="item">
                <div className="testimonial-8   ">
                  <div className="p-3 mb-5">
                    <div className="testimonial-text  ">
                      <p>{e.comment}</p>
                    </div>
                    <div className="testimonial-detail clearfix">
                      <div className="testimonial-pic radius shadow">
                        <img src={e.img} width="100" height="100" alt="" />
                      </div>
                      <h5 className="testimonial-name m-t0 m-b5">{e.name}</h5>
                      <span>{e.position}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </>
    );
  }
}

const Data = [
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "David Van Waldick",
    comment:
      "Gobeens provide a web re-design for us. Anshul as project manager did great job of communication the needs to designers. Gobeens was timely, professional, and priced well for the service. I would recommend using them to anyone needing a new web site at a fair price with professional results.",
    position: "Owner",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Joseph Pero",
    comment:
      "They did a wonderful job for two of my websites. Ankush and his staff were very supportive and responsive to my needs. I plan on using them again!",
    position: "CEO & President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Habi Coulibaly",
    comment:
      "Anshul is an excellent communicator with amazing professionalism. We originally had a nice website but the optimization and feel were not there. Anshul took a look and came up with solid suggestions to start the process. We were able to revamp our website to represent our brand in the way that we wanted working with Gobeens. The team is always ready to make changes to adapt to our vision as we continue to grow. It has been a great experience.",
    position: "CEO & President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Alexys Feaster",
    comment:
      "Gobeens Technology created a great website for my new company and is always very responsive and focused on making their clients happy. Anshul is extremely professional, attentive and great at what he does. It was a great experience and I highly recommend them!",
    position: "Founder",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Ian Miller",
    comment:
      "Gobeens was responsive, professional, and generally great to work with. They helped me set up a landing page for a new business.",
    position: "Founder & CEO",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Dana Hawthorne",
    comment:
      "Overall the experience with Vcanna was quite good. Ankush and the team offshore did a good job and for the price, you’d be hard pressed to get this much time and attention from a solely US based web dev shop. We had them build our initial website for modernize.io and overall the site looks good!",
    position: "Founder & CEO",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Vennard Wright",
    comment:
      "Gobeens did a great job of redesigning our website quickly. They were economical, professional and efficient. I would recommend them for any digital needs you're considering.",
    position: "President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Kwaku Dokyi",
    comment:
      "Gobeens Technology Inc has been working with my business, Kooman Computing, for about 6 months now and the communication has been exceptional. Anshul and his team set the standard for customer service. I look forward to future projects and collaborations. I appreciate the service!",
    position: "President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Jan Havmoeller",
    comment:
      "Gobeens Technology completed our database building project to our full satisfaction. we will use them again when we have the next project defined. Very responsive and courteous.",
    position: "Founder",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Michael Brown",
    comment:
      "Gobeens will definitely get a lot more business from me. Anshul, is easy to work with, knowledgeable, considerate, professional, and works quickly. I have already recommended Gobeens to my daughter's Notary Signing company and my niece's ecommerce company. As a restaurant consultant I will also recommend my clients to Gobeens.",
    position: "Founder & CEO",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Thurman Brown",
    comment:
      "Gobeens staff was very responsive to what I thought my needs were and at the end of the day, I got a product that reflected my vision. Than You, Gobeens Technology.",
    position: "CEO",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Adam Hofmann",
    comment:
      "Great on all accounts. Prompt, professional and outstanding work. Really on top of their game. Flexible and can handle many different assignments and situations. Amazing manager and great team.",
    position: "Manager",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Adriann Negreros",
    comment:
      "Excellent work. Asking price for a development project was a little high comparatively, but the work done was FANTASTIC and done to the perfection.",
    position: "Manager",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Liane Parker",
    comment:
      "Gobeens Technology Inc. Were responsive, creative and amazingly economical in building our hospital website. I highly recommend them for any website creation.",
    position: "Chief Operating Officer",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Alyssa Wilson ",
    comment:
      "Gobeens Technology has been over accommodating with all of our edits for websites, been patient with us, and have really shown us what it is to be a good company that builds websites. We have had some interesting clients and they have helped us through working with them. Anshul and his team is available when ever we need them. Fast to respond and always has a solution to the task at hand. Thank you Gobeens Technology for your service!",
    position: "President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Maria Gilbert",
    comment:
      "Since I’m not a real pro in digital marketing, I hired Gobeens Technology to do the job for me. The team did exceptionally well and I could see the desired results within a month of hiring Gobeens Technology.",
    position: "Manager",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Vennard Wright",
    comment:
      "An excellent and talented team. They work hard and efficiently, flexible and available to all your needs, and are very good at clarifying questions to make sure that they are completing the tasks as required. Hire Gobeens Technology.",
    position: "President",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Eric Jorgenson",
    comment:
      "Gobeens Technology Inc has been working with my business, Kooman Computing, for about 6 months now and the communication has been exceptional. Anshul and his team set the standard for customer service. I look forward to future projects and collaborations. I appreciate the service!",
    position: "Manager",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Tom Lev",
    comment:
      "Gobeens Technology is a magnificent company with an exceptional team. someone whom you can depend on. They knows the BPO world well, and is capable of managing digital marketing processes for businesses big or small. Besides that, the leaders are genuine persons with a sweet and welcoming personality.",
    position: "CEO",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Peter Hanley",
    comment:
      "Team Gobeens is an excellent agency. They performed the tasks perfectly and we have hired them for our ongoing project where we needed to have an increased number of followers on our Instagram and Twitter account.",
    position: "Manager",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Scott Allred",
    comment:
      "Gobeens Technology did a very well work on our lead generation project. With the help of Gobeens Technology, we were able to get the targeted leads as per our business requirements and great ROI. I just wish I had more work for them to do. Will definitely consider hiring in the future.",
    position: "Founder",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Neil Nguyen",
    comment:
      "Gobeens Technology is a highly professional company. I assigned them the task of calling my other staff and take summaries of their works and type up reports for me. They did so with great attitude and skills. I am now assigning them for more work hours! I would recommend them if you need someone who is good with Internet tools and communications.",
    position: "Founder",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Mario C",
    comment:
      "Anshul with Gobeens Technology does excellent work. Anshul is very friendly and professional. He is an extremely hard worker and has a very creative eye. He delivered on exactly what he promised in a time efficient manner. I highly recommend his services.",
    position: "Co Founder",
  },
  {
    img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
    name: "Timothy Barlow",
    comment:
      "Anshul and his team do outstanding web development with great results. The work was complete within 2 weeks as proposed. They are very professional and great to work with. I would recommend them to anyone that wishes to upgrade their current website or build a new one!!",
    // position: "Manager",
  },
  // {
  //   img: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
  //   name: "Peter Hanley",
  //   comment:
  //     "Team Gobeens is an excellent agency. They performed the tasks perfectly and we have hired them for our ongoing project where we needed to have an increased number of followers on our Instagram and Twitter account.",
  //   position: "Manager",
  // },
];

export default TestimonialCarousel;
