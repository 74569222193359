import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Header from "../Layout/Header";
import Footer from "./../Layout/Footer";

import bgimg from "./../../images/background/bg2.jpg";

class Contact extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="full-section">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary-dark contact-page"
            style={{ backgroundImage: "url(" + bgimg + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xs-4">
                  <div className="row text-white">
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i style={{color :'white'}} className="ti-location-pin"></i>
                            </span>
                            Company Address
                          </h5>
                          <p>First Address:  Gole Ka Mandir, Mela Road, Gwalior, MP, 474005</p>
                          <p>Second Address: A66, Sector 63, Noida, UP, 201301</p>
                          <h6 className="m-b15 font-weight-400">
                            <i style={{color :'white'}} className="ti-alarm-clock"></i> Office Hours
                          </h6>
                          <p className="m-b0">Mon To Sat - 08.00-18.00</p>
                          <p>Sunday - Close</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30 ">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i style={{color :'white'}} className="ti-email"></i>
                            </span>
                            E-mail
                          </h5>
                          <p className="m-b0"> Info@gobeens.com</p>
                          <p className="m-b0">Sales@gobeens.com</p>
                          {/* <p className="m-b0">hr@Gobeensglobal.com</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30 ">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i style={{color :'white'}} className="ti-mobile"></i>
                            </span>
                            Phone Numbers
                          </h5>
                          <p className="m-b0">+91 9988772199</p>
                          {/* <p>+91 9988772199</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{marginTop : 50}} className="col-lg-8 col-xs-8">
                  <form
                    className="inquiry-form dzForm"
                    action="script/contact.php"
                  >
                    <div className="dzFormMsg"></div>
                    <h3 className="box-title m-t0 m-b10">
                      Let's Convert Your Idea into Reality{" "}
                      <span className="bg-primary"></span>
                    </h3>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the
                    </p> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-user text-primary"></i>
                            </span>
                            <input
                              name="dzName"
                              type="text"
                              required
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-mobile text-primary"></i>
                            </span>
                            <input
                              name="dzOther[Phone]"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-email text-primary"></i>
                            </span>
                            <input
                              name="dzEmail"
                              type="email"
                              className="form-control"
                              required
                              placeholder="Your Email Id"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-agenda text-primary"></i>
                            </span>
                            <textarea
                              name="dzMessage"
                              rows="4"
                              className="form-control"
                              required
                              placeholder="Tell us about your project or idea"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                              data-callback="verifyRecaptchaCallback"
                              data-expired-callback="expiredRecaptchaCallback"
                            ></div>
                            <input
                              className="form-control d-none"
                              data-recaptcha="true"
                              required
                              data-error="Please complete the Captcha"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="site-button button-lg"
                        >
                          {" "}
                          <span>Get A Free Quote!</span>{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- inner page banner END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Contact;
