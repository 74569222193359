import React, { Component } from "react";
import "./Jobs.css";

import Header from "../../Layout/Header";
import Footer from "./../../Layout/Footer";
import PageTitle from "./../../Layout/PageTitle";

import bnr1 from "./../../../images/banner/bnr4.jpg";
import { AiOutlineSearch } from "react-icons/ai";

class ServicesDetails extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Jobs" activeMenu="Jobs" />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="job-list-area-section section-gap">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <div class="job-list-wrapper">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="single-job">
                          <h3>
                            <a href="/#" class="title">
                              Front End Software Engineer
                            </a>
                          </h3>
                          <p class="deadline">
                            <strong>
                              <i class="fa fa-calendar"></i> Deadline:
                            </strong>{" "}
                            27th December, 2019
                          </p>
                          <p class="education">
                            <strong>
                              <i class="fa fa-graduation-cap"></i> Educational
                              Experience:
                            </strong>{" "}
                            You must have practical experience on Node.js,
                            TypeScript, webpack and Third party library.You have
                            an in-dept...
                          </p>
                          <p class="experience">
                            <strong>
                              <i class="fa fa-briefcase"></i> Work Experience:
                            </strong>{" "}
                            2 to 5 year(s)
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="single-job ">
                          <h3>
                            <a href="/#" class="title">
                              Front End Software Engineer
                            </a>
                          </h3>
                          <p class="deadline">
                            <strong>
                              <i class="far fa-calendar-alt"></i> Deadline:
                            </strong>{" "}
                            27th December, 2019
                          </p>
                          <p class="education">
                            <strong>
                              <i class="fas fa-graduation-cap"></i> Educational
                              Experience:
                            </strong>{" "}
                            You must have practical experience on Node.js,
                            TypeScript, webpack and Third party library.You have
                            an in-dept...
                          </p>
                          <p class="experience">
                            <strong>
                              <i class="fas fa-briefcase"></i> Work Experience:
                            </strong>{" "}
                            2 to 5 year(s)
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="single-job ">
                          <h3>
                            <a href="/#" class="title">
                              Front End Software Engineer
                            </a>
                          </h3>
                          <p class="deadline">
                            <strong>
                              <i class="far fa-calendar-alt"></i> Deadline:
                            </strong>{" "}
                            27th December, 2019
                          </p>
                          <p class="education">
                            <strong>
                              <i class="fas fa-graduation-cap"></i> Educational
                              Experience:
                            </strong>{" "}
                            You must have practical experience on Node.js,
                            TypeScript, webpack and Third party library.You have
                            an in-dept...
                          </p>
                          <p class="experience">
                            <strong>
                              <i class="fas fa-briefcase"></i> Work Experience:
                            </strong>{" "}
                            2 to 5 year(s)
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="single-job ">
                          <h3>
                            <a href="/#" class="title">
                              Front End Software Engineer
                            </a>
                          </h3>
                          <p class="deadline">
                            <strong>
                              <i class="far fa-calendar-alt"></i> Deadline:
                            </strong>{" "}
                            27th December, 2019
                          </p>
                          <p class="education">
                            <strong>
                              <i class="fas fa-graduation-cap"></i> Educational
                              Experience:
                            </strong>{" "}
                            You must have practical experience on Node.js,
                            TypeScript, webpack and Third party library.You have
                            an in-dept...
                          </p>
                          <p class="experience">
                            <strong>
                              <i class="fas fa-briefcase"></i> Work Experience:
                            </strong>{" "}
                            2 to 5 year(s)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  {/* <!-- sidebar --> */}
                  <div class="sidebar">
                    {/* <!-- Search Widget --> */}
                    <div class="widget search-widget">
                      <form action="/#">
                        <input type="text" placeholder="Search here" />
                        <button type="submit">
                          <AiOutlineSearch className="bold" />
                        </button>
                      </form>
                    </div>
                    {/* <!-- Cat Widget --> */}
                    <div class="widget cat-widget">
                      <h4 class="widget-title">Category</h4>
                      <ul>
                        <li>
                          <a href="//#">
                            All<span>(15)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Web Development <span>(10)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Ecommerce Development<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Mobile App Development<span>(04)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Marketing Strategy<span>(10)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Digital Marketing $ SEO<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                           Custom App Development<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Outsourcing Service<span>(05)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}
export default ServicesDetails;
