import * as Yup from "yup";

export const Validation = Yup.object({
  name: Yup.string()
    .min(2, "Min 2 Character are Required")
    .matches(/^[aA-zZ\s]+$/, "Please Enter Correct Name ")
    .required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  mobile: Yup.number().required("Please Enter Your Number"),
  message: Yup.string()
    .min(30, "Min 30 Character are Required")
    .max(500)
    .required("Please Enter Your Query"),
});
