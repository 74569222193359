import React from "react";
import { GrFormAdd } from "react-icons/gr";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
  return (
    <>
      <div class="widget cat-widget">
        <h4 class="widget-title">All Job List</h4>

        <ul>
          <li>
            <NavLink
              to={"jobs-details"}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Web Development
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"jobs-details"}
              activeStyle={{
                fontWeight: "bold",
                color: "#ff3300",
              }}
            >
              Content Writing
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"jobs-details"}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Business Development
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"jobs-details"}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              App Development
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"custom-app-development"}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Custom App Development
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink>
          </li>
          <li> */}
            {/* <NavLink
              to={"out-sourcing"}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Outsourcing service
              <span class="">
                <GrFormAdd />
              </span>
            </NavLink> */}
          {/* </li> */}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
