import React from "react";
import { MdComputer, MdArrowRightAlt } from "react-icons/md";
import { Link } from "react-router-dom";

const NewCard = ({ data }) => {
  return (
    <>
      {" "}
      <div className="grid mt-10 md:grid-cols-3 grid-cols-1 gap-4">
        {data.map((e) => {
          return (
            <div className="relative cursor-pointer hover:shadow-2xl group hover:bg-[#FF3300] duration-300 border border-[#fff] rounded-md bg-[#262051] p-4">
              <h2 className="absolute top-0 left-0 text-8xl text-[#332D5F] group-hover:text-red-400 duration-300 ">
                {e.no}
              </h2>
              <div className="py-8 relative z-10">
                <h2 className="text-white text-2xl">{e.title}</h2>
                <p className="text-gray-500">{e.desc}</p>
                <span className="flex items-center mt-10 space-x-2 ">
                  <Link
                    to={e.route}
                    className="text-white  text-base font-medium tracking-wider"
                  >
                    Learn More &rarr;
                  </Link>
                  {/* <MdArrowRightAlt className="text-3xl group-hover:translate-x-3 duration-300 mb-4" /> */}
                </span>
              </div>
              <span className="absolute bg-[#FF3300] group-hover:bg-[#262051] duration-500 pl-4 pt-4 pr-3 pb-3 bottom-0 right-0 rounded-tl-[50px]">
                <MdComputer className="text-4xl" />
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NewCard;
