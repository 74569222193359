import React from "react";
import Slider from "react-slick";
import Acm from "../../../images/Clients/ACM.png"
import robomanagers from "../../../images/Clients/RoboManagers.png"
import zconsulting from "../../../images/Clients/Z-Consulting.jpg"
import travelground from "../../../images/Clients/Travel-Ground.png"
import zaarly from "../../../images/Clients/Zaarly.jpg"
import tokyotechies from "../../../images/Clients/Tokyo-techies.png"
import tokyoacademics from "../../../images/Clients/Tokyo.png"
import thurmanbrownconsultant from "../../../images/Clients/Thurman.png"
import perrycommunityhospital from "../../../images/Clients/Perry.png"
import sonomaspecialtyhospital from "../../../images/Clients/Sonoma.png"
import drfood from "../../../images/Clients/Food-Cost.png"
import pic1 from "../../../images/Clients/Bello.jpg"
import pic2 from "../../../images/Clients/Dounto.png"
import pic3 from "../../../images/Clients/Elite.png"
import pic4 from "../../../images/Clients/Matadordigi.png"
import pic5 from "../../../images/Clients/Modernize.png"
import pic6 from "../../../images/Clients/Monday-Media.jpg"
import pic7 from "../../../images/Clients/PDI.png"
import pic8 from "../../../images/Clients/Sage-Talk.png"
const Client = () => {
    var settings = {
        arrows: false,
        slidesToShow: 4,
        speed: 3000,
        navSpeed: 3000,
        infinite: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
  return (
    <>
    
    <div className="container">
    <h2 className="text-center ourr">OUR CLIENTS</h2> 
            <Slider
              className="  text-center text-white owl-none "
              {...settings}
            >
              {client.map((data, index) => (
             <a
             target="_blank"
             rel="noreferrer"
             class=""
             href={data.route}
           >
                <div className="item p-3" key={index}>
                  <div className="">
                    <div className="dlab-media ">
                      {" "}
                      <div>
                        <img src={data.pic} alt="" />
                      </div>
                      
                    </div>
                  </div>
                </div>
                </a>
              ))}
            </Slider>
        </div>
    </>
  );
};
const client=[
    {
        pic:drfood,
        route:"https://drfoodcost.com/"
    },
    {
        pic:robomanagers,
        route:"https://robomanagers.com/"
    },
    {
        pic:zconsulting,
        route:"https://z-consulting.net/"
    },
    {
        pic:travelground,
        route:"https://www.travelground.com/"
    },
    {
        pic:zaarly,
        route:"https://www.zaarly.com/"
    },
    {
        pic:tokyotechies,
        route:"https://www.tokyotechies.com/"
    },
    {
        pic:tokyoacademics,
        route:"https://www.tokyoacademics.com/"
    },
    {
        pic:thurmanbrownconsultant,
        route:"https://thurmanbrownconsultant.com/"
    },
    {
        pic:perrycommunityhospital,
        route:"https://perrycommunityhospital.org/"
    },
    {
        pic:sonomaspecialtyhospital,
        route:"https://sonomaspecialtyhospital.org/"
    },
    {
        pic:pic1
    },
    {
        pic:pic2
    },
    {
        pic:pic3
    },
    {
        pic:pic4
    },
    {
        pic:pic5
    },
    {
        pic:pic6
    },
    {
        pic:pic7
    },
    {
        pic:pic8
    },
    {
        pic:Acm
    },

]
export default Client;
