import React from "react";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/8.jpg";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "./Sidebar";
import { GrFormAdd } from "react-icons/gr";
import bg2 from "../../../images/background/smm.jpg";
import View from "./View";
import StickyBox from "react-sticky-box";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import Information from "../../../images/Outsourcing/INFORMATION-TECHNOLOGY.jpg";
import Real from "../../../images/Outsourcing/REAL-ESTATE.jpg";
import Study from "../../../images/Outsourcing/EDUCATION.jpg";
import Telecome from "../../../images/Outsourcing/TELECOME.jpg";
import Medical from "../../../images/Outsourcing/MEDICAL.jpg";
import Financial from "../../../images/Outsourcing/financial-accounting.jpg";
import Ecommerce from "../../../images/Outsourcing/E-COMMERCE.jpg";
import Construction from "../../../images/Outsourcing/CONSTRUCTION.jpg";
import Automation from "../../../images/Outsourcing/AUTOMATION.jpg";
import { BusinessProcess, RPO, WeServe } from "./Outsourcing";
import { MdComputer, MdArrowRightAlt } from "react-icons/md";

// import Sidebar from "./Sidebar";
const SEO = () => {
  return (
    <>
      <Header />

      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <PageTitle
            motherMenu="Social Media Marketing "
            activeMenu="Social Media Marketing"
          />
        </div>
        <section class="service-details section-gap container-xxl mx-auto ">
          <div class="">
            <div class="row">
              <div class="col-lg-8">
                <div class="service-details-content">
                  <div class="main-thumb mb-40">
                    <img className="w-full" src={bg2} alt="" />
                  </div>
                  <h2 class="title">Social Media Marketing (SMM)</h2>
                  <p className="">
                    If you comprehend your clients’ prerequisites, your BPO
                    services can be a great answer to your client’s needs. BPOs
                    work to streamline the recruitment process for their
                    partners. It likewise guarantees that you stay in business
                    and prosper. With Business Process Outsourcing companies,
                    you can reduce expenses, spare time, and improve your
                    processes. Gobeens Technology offers the best BPO administrations.
                  </p>
                  {/* BUSINESS PROCESS OUTSOURCING SERVICES (BPO) */}
                  <div className="grid my-10 md:grid-cols-4 gap-2">
                    {BusinessProcess.map((e) => {
                      return (
                        <div className="relative cursor-pointer hover:shadow-2xl group hover:bg-[#FF3300] duration-300 border border-[#fff] rounded-md bg-[#262051] p-4">
                          <h2 className="absolute top-0 left-0 text-8xl text-[#332D5F] group-hover:text-red-400 duration-300 ">
                            {e.no}
                          </h2>
                          <div className="py-8 relative z-10">
                            <h2 className="text-white text-2xl">{e.title}</h2>
                            <p className="text-gray-500">{e.desc}</p>
                            {/* <span className="flex items-center mt-10 space-x-2 ">
                                <h2 className="text-white  text-base font-medium tracking-wider">
                                  Learn More
                                </h2>
                                <MdArrowRightAlt className="text-3xl group-hover:translate-x-3 duration-300 mb-4" />
                              </span> */}
                          </div>
                          <span className="absolute bg-[#FF3300] group-hover:bg-[#262051] duration-500 pl-4 pt-4 pr-3 pb-3 bottom-0 right-0 rounded-tl-[50px]">
                            <MdComputer className="text-4xl" />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {/* SCALABLE RECRUITMENT PROCESS OUTSOURCING (RPO) SOLUTIONS */}
                  <div className="">
                    <img
                      src="https://cdn-cpjjo.nitrocdn.com/OiTvcMywCpwqCFlxycmDlgSdGFUQWyin/assets/static/optimized/rev-ec11457/wp-content/uploads/2020/08/oie_261227ZqTwTg8z.gif"
                      alt=""
                    />
                    <h2 class="title">
                      SCALABLE RECRUITMENT PROCESS OUTSOURCING (RPO) SOLUTIONS
                    </h2>
                    <p className="">
                      Organizations, both big and little, rely upon internal
                      groups to source top-notch talent. Recruitment process
                      outsourcing (RPO) programs give talent acquisition service
                      to proficient professional and non-professional positions.
                      At Gobeens Technology’s, we help simplify the talent acquisition
                      process through our recruitment process. The
                      industry-driving RPO solutions focus on the right
                      methodology, quantifiable outcomes, and flexible
                      solutions.
                    </p>
                    <div className="grid my-10 md:grid-cols-4 gap-2">
                      {RPO.map((e) => {
                        return (
                          <div className="relative cursor-pointer hover:shadow-2xl group hover:bg-[#FF3300] duration-300 border border-[#fff] rounded-md bg-[#262051] p-4">
                            <h2 className="absolute top-0 left-0 text-8xl text-[#332D5F] group-hover:text-red-400 duration-300 ">
                              {e.no}
                            </h2>
                            <div className="py-8 relative z-10">
                              <h2 className="text-white text-2xl">{e.title}</h2>
                              <p className="text-gray-500">{e.desc}</p>
                              {/* <span className="flex items-center mt-10 space-x-2 ">
                                <h2 className="text-white  text-base font-medium tracking-wider">
                                  Learn More
                                </h2>
                                <MdArrowRightAlt className="text-3xl group-hover:translate-x-3 duration-300 mb-4" />
                              </span> */}
                            </div>
                            <span className="absolute bg-[#FF3300] group-hover:bg-[#262051] duration-500 pl-4 pt-4 pr-3 pb-3 bottom-0 right-0 rounded-tl-[50px]">
                              <MdComputer className="text-4xl" />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h2 class="title">INDUSTRIES WE SERVE</h2>

                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {WeServe.map((e) => {
                      return (
                        <div className="flex bg-white group shadow-md flex-col space-y-3 rounded-xl p-4 items-center">
                          <img
                            className="rounded-xl group-hover:shadow-lg"
                            src={e.img}
                            alt=""
                          />
                          <h2 className="text-lg tracking-wide font-medium">
                            {e.title}
                          </h2>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div className="lol7">
                  <View />
                </div> */}

                <div class="faq-section mt-40">
                  <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                    <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                      What is Business Process Outsourcing?
                    </summary>
                    <p class="px-4 py-3">
                      Business process outsourcing (BPO) involves a business
                      contracting a third-party to provide certain business
                      functions to it. These functions are not core functions of
                      a business but still are important for the business. As
                      the business does not have the human resources who can
                      perform these functions as well as the technology, tools
                      and infrastructure required for the functions, it
                      outsources them to a third party who has expertise in
                      performing these functions, leading to saving of time and
                      other resources which thus can be devoted to a business’s
                      core areas.
                    </p>
                  </details>

                  <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                    <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                      Which BPO services do you provide?
                    </summary>
                    <p class="px-4 py-3">
                      We provide BPO services in terms of accounting and
                      financial services, sourcing and procurement, digital
                      marketing, healthcare and insurance and recruitment
                      services.
                    </p>
                  </details>

                  <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                    <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                      How does outsourcing lessen business costs?
                    </summary>
                    <p class="px-4 py-3">
                      Outsourcing helps to bring your business costs down by
                      reducing your labor costs, operational costs, technology
                      costs, recruitment and training costs and infrastructure
                      costs. Due to these cost savings, your business can
                      maintain lower rates, which consequently will give it a
                      competitive advantage in the market.
                    </p>
                  </details>
                  <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                    <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                      What industries does our company specialize in?
                    </summary>
                    <p class="px-4 duration-500 py-3">
                      Our outsourcing services serve a wide array of industries
                      including information technology, real estate, financial
                      accounting, education, e-commerce, telecom, medical,
                      construction and automation.
                    </p>
                  </details>
                </div>
              </div>
              {/* collapse */}
              {/* Sidebar */}
              <div class="col-lg-4 col-md-8">
                <StickyBox
                  // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                  offsetTop={100}
                  offsetBottom={100}
                >
                  <div class="sidebar">
                    <Sidebar />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
        <Pricing />
        {/* Bottom Form */}
        <div
          className="section-full overlay-primary-dark bg-img-fix"
          style={{ backgroundImage: "url(" + bg1 + ")" }}
        >
          <FormStyle />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SEO;
