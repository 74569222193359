import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import bnr5 from "../../images/banner/bnr17.jpg";
const Privacy = () => {
  return (
    <>
      <Header />

      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <PageTitle motherMenu="Privacy Policy" activeMenu="Privacy Policy" />
        </div>
        <section id="content">
          <div class="container-fluid">
            <div class="content_sidebar">
              <div id="primary" class=" row">
                <div class="col-md-12">
                  <h2 class="page-title">Terms of Use &#038; Privacy Policy</h2>
                </div>
                <div class="clear"></div>{" "}
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id="117"
                  class="elementor elementor-117"
                  data-elementor-settings="[]"
                >
                  <div class="elementor-inner">
                    <div class="elementor-section-wrap">
                      <section
                        class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-2a16c329 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="2a16c329"
                        data-element_type="section"
                      >
                        <div class="elementor-container-fluidelementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74314ef"
                              data-id="74314ef"
                              data-element_type="column"
                            >
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-2081e516 elementor-widget elementor-widget-text-editor"
                                    data-id="2081e516"
                                    data-element_type="widget"
                                    data-widget_type="text-editor.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <p className="">
                                          Last Updated: April 04, 2020
                                        </p>
                                        <p className="m-b20">
                                          Welcome to Gobeens Technology!
                                        </p>
                                        <p className="">
                                          <h2>Please Note:</h2> Due to a
                                          combination of local and international
                                          regulatory requirements, commercial
                                          partnerships, and other factors, Gobeens
                                          Global do not accept payments or do
                                          business with individuals or entities
                                          in Cuba, Iran, North Korea, Syria, and
                                          the Crimean region of Ukraine.
                                        </p>
                                        <p className="">
                                          Further, Gobeens Technology do not provide
                                          services to any individual who belongs
                                          to these areas and neither we employ
                                          or hire individuals from these
                                          regions. As a practice, we also do not
                                          outsource or freelance our work from
                                          these restricted territories.
                                        </p>
                                        <p className="">
                                          These terms and conditions outline the
                                          rules and regulations for the use of
                                          Gobeens Technology LLP&#8217;s Website,
                                          located at
                                          https://www.Gobeensglobal.com.
                                        </p>
                                        <p className="">
                                          By accessing this website we assume
                                          you accept these terms and conditions.
                                          Do not continue to use Gobeens Technology if
                                          you do not agree to take all of the
                                          terms and conditions stated on this
                                          page.
                                        </p>
                                        <p className="m-b20">
                                          The following terminology applies to
                                          these Terms and Conditions, Privacy
                                          Statement and Disclaimer Notice and
                                          all Agreements: &#8220;Client&#8221;,
                                          &#8220;You&#8221; and
                                          &#8220;Your&#8221; refers to you, the
                                          person log on this website and
                                          compliant to the Company’s terms and
                                          conditions. &#8220;The Company&#8221;,
                                          &#8220;Ourselves&#8221;,
                                          &#8220;We&#8221;, &#8220;Our&#8221;
                                          and &#8220;Us&#8221;, refers to our
                                          Company. &#8220;Party&#8221;,
                                          &#8220;Parties&#8221;, or
                                          &#8220;Us&#8221;, refers to both the
                                          Client and ourselves. All terms refer
                                          to the offer, acceptance and
                                          consideration of payment necessary to
                                          undertake the process of our
                                          assistance to the Client in the most
                                          appropriate manner for the express
                                          purpose of meeting the Client’s needs
                                          in respect of provision of the
                                          Company’s stated services, in
                                          accordance with and subject to,
                                          prevailing law of Netherlands. Any use
                                          of the above terminology or other
                                          words in the singular, plural,
                                          capitalization and/or he/she or they,
                                          are taken as interchangeable and
                                          therefore as referring to same.
                                        </p>
                                        <h4 className="">
                                          <h2>Cookies</h2>
                                        </h4>
                                        <p className="">
                                          We employ the use of cookies. By
                                          accessing Gobeens Technology, you agreed to
                                          use cookies in agreement with the
                                          Gobeens Technology LLP&#8217;s Privacy
                                          Policy.
                                        </p>
                                        <p className="m-b20">
                                          Most interactive websites use cookies
                                          to let us retrieve the user’s details
                                          for each visit. Cookies are used by
                                          our website to enable the
                                          functionality of certain areas to make
                                          it easier for people visiting our
                                          website. Some of our
                                          affiliate/advertising partners may
                                          also use cookies.
                                        </p>
                                        <h4 className="m-b10">
                                          <classh2Name>License</classh2Name>
                                        </h4>
                                        <p className="">
                                          Unless otherwise stated, Gobeens Technology
                                          LLP and/or its licensors own the
                                          intellectual property rights for all
                                          material on Gobeens Technology. All
                                          intellectual property rights are
                                          reserved. You may access this from
                                          Gobeens Technology for your own personal use
                                          subjected to restrictions set in these
                                          terms and conditions.
                                        </p>
                                        <p className="">
                                          You must not:
                                        </p>
                                        <ul className="">
                                          <li className="list-className-type: none;">
                                            <ol>
                                              <li>
                                                (a).Republish material from
                                                Gobeens Technology
                                              </li>
                                              <li>
                                                (b).Sell, rent or sub-license
                                                material from Gobeens Technology
                                              </li>
                                              <li>
                                                (c).Reproduce, duplicate or copy
                                                material from Gobeens Technology
                                              </li>
                                              <li>
                                                (d).Redistribute content from
                                                Gobeens Technology
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                        <p className="">
                                          This Agreement shall begin on the date
                                          hereof.
                                        </p>
                                        <p className="">
                                          Parts of this website offer an
                                          opportunity for users to post and
                                          exchange opinions and information in
                                          certain areas of the website. Gobeens
                                          Global LLP does not filter, edit,
                                          publish or review Comments prior to
                                          their presence on the website.
                                          Comments do not reflect the views and
                                          opinions of Gobeens Technology LLP,its
                                          agents and/or affiliates. Comments
                                          reflect the views and opinions of the
                                          person who post their views and
                                          opinions. To the extent permitted by
                                          applicable laws, Gobeens Technology LLP
                                          shall not be liable for the Comments
                                          or for any liability, damages or
                                          expenses caused and/or suffered as a
                                          result of any use of and/or posting of
                                          and/or appearance of the Comments on
                                          this website.
                                        </p>
                                        <p className="">
                                          Gobeens Technology LLP reserves the right to
                                          monitor all Comments and to remove any
                                          Comments which can be considered
                                          inappropriate, offensive or causes
                                          breach of these Terms and Conditions.
                                        </p>
                                        <p className="">
                                          You warrant and represent that:
                                        </p>
                                        <p className="">
                                          You are entitled to post the Comments
                                          on our website and have all necessary
                                          licenses and consents to do so
                                        </p>
                                        <p className="">
                                          The Comments do not invade any
                                          intellectual property right, including
                                          without limitation copyright, patent
                                          or trademark of any third party;
                                        </p>
                                        <p className="">
                                          The Comments do not contain any
                                          defamatory, libelous, offensive,
                                          indecent or otherwise unlawful
                                          material which is an invasion of
                                          privacy
                                        </p>
                                        <p className="">
                                          The Comments will not be used to
                                          solicit or promote business or custom
                                          or present commercial activities or
                                          unlawful activity.
                                        </p>
                                        <p className="m-b20">
                                          You hereby grant Gobeens Technology LLP a
                                          non-exclusive license to use,
                                          reproduce, edit and authorize others
                                          to use, reproduce and edit any of your
                                          Comments in any and all forms, formats
                                          or media.
                                        </p>
                                        <h4 className="">
                                          <h2>Hyperlinking to our Content</h2>
                                        </h4>
                                        <p className="">
                                          The following organizations may link
                                          to our Website without prior written
                                          approval:
                                        </p>
                                        <p className="">
                                          Government agencies;
                                        </p>
                                        <p className="">
                                          Search engines;
                                        </p>
                                        <p className="">
                                          News organizations;
                                        </p>
                                        <p className="">
                                          Online directory distributors may link
                                          to our Website in the same manner as
                                          they hyperlink to the Websites of
                                          other listed businesses; and
                                        </p>
                                        <p className="">
                                          System wide Accredited Businesses
                                          except soliciting non-profit
                                          organizations, charity shopping malls,
                                          and charity fundraising groups which
                                          may not hyperlink to our Web site.
                                        </p>
                                        <p className="">
                                          These organizations may link to our
                                          home page, to publications or to other
                                          Website information so long as the
                                          link:
                                        </p>
                                        <p className="">
                                          (a) is not in any way deceptive;
                                        </p>
                                        <p className="">
                                          (b) does not falsely imply
                                          sponsorship, endorsement or approval
                                          of the linking party and its products
                                          and/or services; and
                                        </p>
                                        <p className="">
                                          (c) fits within the context of the
                                          linking party’s site.
                                        </p>
                                        <p className="">
                                          We may consider and approve other link
                                          requests from the following types of
                                          organizations:
                                        </p>
                                        <p className="">
                                          &#8211; commonly-known consumer and/or
                                          business information sources;
                                        </p>
                                        <p className="">
                                          &#8211; dot.com community sites;
                                        </p>
                                        <p className="">
                                          &#8211; associations or other groups
                                          representing charities;
                                        </p>
                                        <p className="">
                                          &#8211; online directory distributors;
                                        </p>
                                        <p className="">
                                          &#8211; internet portals;
                                        </p>
                                        <p className="">
                                          &#8211; accounting, law and consulting
                                          firms; and
                                        </p>
                                        <p className="">
                                          &#8211; educational institutions and
                                          trade associations.
                                        </p>
                                        <p className="">
                                          We will approve link requests from
                                          these organizations if we decide that:
                                        </p>
                                        <p className="">
                                          (a) the link would not make us look
                                          unfavorably to ourselves or to our
                                          accredited businesses;
                                        </p>
                                        <p className="">
                                          (b) the organization does not have any
                                          negative records with us;
                                        </p>
                                        <p className="">
                                          (c) the benefit to us from the
                                          visibility of the hyperlink
                                          compensates the absence of Gobeens
                                          Global LLP; and
                                        </p>
                                        <p className="">
                                          (d) the link is in the context of
                                          general resource information.
                                        </p>
                                        <p className="">
                                          These organizations may link to our
                                          home page so long as the link:
                                        </p>
                                        <p className="">
                                          (a) is not in any way deceptive;
                                        </p>
                                        <p className="">
                                          (b) does not falsely imply
                                          sponsorship, endorsement or approval
                                          of the linking party and its products
                                          or services; and
                                        </p>
                                        <p className="">
                                          (c) fits within the context of the
                                          linking party’s site.
                                        </p>
                                        <p className="">
                                          If you are one of the organizations
                                          listed in paragraph 2 above and are
                                          interested in linking to our website,
                                          you must inform us by sending an
                                          e-mail to Gobeens Technology LLP. Please
                                          include your name, your organization
                                          name, contact information as well as
                                          the URL of your site, a list of any
                                          URLs from which you intend to link to
                                          our Website, and a list of the URLs on
                                          our site to which you would like to
                                          link. Wait 2-3 weeks for a response.
                                        </p>
                                        <p className="">
                                          Approved organizations may hyperlink
                                          to our Website as follows:
                                        </p>
                                        <p className="">
                                          (a). By use of our corporate name; or
                                        </p>
                                        <p className="">
                                          (b).By use of the uniform resource
                                          locator being linked to; or
                                        </p>
                                        <p className=" cursor: text;">
                                          (c).By use of any other description of
                                          our Website being linked to that makes
                                          sense within the context and format of
                                          content on the linking party’s site.
                                        </p>
                                        <p className="m-b20">
                                          No use of Gobeens Technology LLP&#8217;s
                                          logo or other artwork will be allowed
                                          for linking absent a trademark license
                                          agreement.
                                        </p>
                                        <h4 className="m-b10">
                                          <h2>iFrames</h2>
                                        </h4>
                                        <p className="m-b20">
                                          Without prior approval and written
                                          permission, you may not create frames
                                          around our Web pages that alter in any
                                          way the visual presentation or
                                          appearance of our Website.
                                        </p>
                                        <h4 className="">
                                          <h2>Content Liability</h2>
                                        </h4>
                                        <p className="m-b20">
                                          We shall not be hold responsible for
                                          any content that appears on your
                                          Website. You agree to protect and
                                          defend us against all claims that is
                                          rising on your Website. No link(s)
                                          should appear on any Website that may
                                          be interpreted as libelous, obscene or
                                          criminal, or which infringes,
                                          otherwise violates, or advocates the
                                          infringement or other violation of,
                                          any third party rights.
                                        </p>
                                        <h4 className="">
                                          <h2>Your Privacy</h2>
                                        </h4>
                                        <p className="m-b20">
                                          Please read Privacy Policy below
                                        </p>
                                        <h4 className="">
                                          <h2>Reservation of Rights</h2>
                                        </h4>
                                        <p className="m-b20">
                                          We reserve the right to request that
                                          you remove all links or any particular
                                          link to our Website. You approve to
                                          immediately remove all links to our
                                          Website upon request. We also reserve
                                          the right to amen these terms and
                                          conditions and it’s linking policy at
                                          any time. By continuously linking to
                                          our Website, you agree to be bound to
                                          and follow these linking terms and
                                          conditions.
                                        </p>
                                        <h4 className="">
                                          <h2>
                                            Removal of links from our website
                                          </h2>
                                        </h4>
                                        <p className="">
                                          If you find any link on our Website
                                          that is offensive for any reason, you
                                          are free to contact and inform us any
                                          moment. We will consider requests to
                                          remove links but we are not obligated
                                          to or so or to respond to you
                                          directly.
                                        </p>
                                        <p className="m-b20">
                                          We do not ensure that the information
                                          on this website is correct, we do not
                                          warrant its completeness or accuracy;
                                          nor do we promise to ensure that the
                                          website remains available or that the
                                          material on the website is kept up to
                                          date.
                                        </p>
                                        <h4 className="">
                                          <h2>Disclaimer</h2>
                                        </h4>
                                        <p className="">
                                          To the maximum extent permitted by
                                          applicable law, we exclude all
                                          representations, warranties and
                                          conditions relating to our website and
                                          the use of this website. Nothing in
                                          this disclaimer will:
                                        </p>
                                        <p className="">
                                          limit or exclude our or your liability
                                          for death or personal injury;
                                        </p>
                                        <p className="">
                                          limit or exclude our or your liability
                                          for fraud or fraudulent
                                          misrepresentation;
                                        </p>
                                        <p className="">
                                          limit any of our or your liabilities
                                          in any way that is not permitted under
                                          applicable law; or
                                        </p>
                                        <p className="">
                                          exclude any of our or your liabilities
                                          that may not be excluded under
                                          applicable law.
                                        </p>
                                        <p className="">
                                          The limitations and prohibitions of
                                          liability set in this Section and
                                          elsewhere in this disclaimer: (a) are
                                          subject to the preceding paragraph;
                                          and (b) govern all liabilities arising
                                          under the disclaimer, including
                                          liabilities arising in contract, in
                                          tort and for breach of statutory duty.
                                        </p>
                                        <p className="m-b20">
                                          As long as the website and the
                                          information and services on the
                                          website are provided free of charge,
                                          we will not be liable for any loss or
                                          damage of any nature.
                                        </p>
                                        <h3 className="margin: 0in 0in 22.5pt; ">
                                          <b>
                                            <span className="font-size: 22.5pt; line-height: 107%; font-family: 'Arial',sans-serif; color: #333333;">
                                              Privacy Policy
                                            </span>
                                          </b>
                                        </h3>
                                        <p className="margin: 0in 0in 22.5pt; ">
                                          <span className="font-family: 'Arial',sans-serif; color: #333333;">
                                            Last updated: April 04, 2020
                                          </span>
                                        </p>
                                        <p className="box-sizing: border-box; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; widows: 2; -webkit-text-stroke-width: 0px; text-decoration-className: initial; text-decoration-color: initial; word-spacing: 0px; margin: 0in 0in 22.5pt; ">
                                          <span className="font-family: 'Arial',sans-serif; color: #333333;">
                                            This Privacy Policy describes Our
                                            policies and procedures on the
                                            collection, use and disclosure of
                                            Your information when You use the
                                            Service and tells You about Your
                                            privacy rights and how the law
                                            protects You.
                                          </span>
                                        </p>
                                        <p className="m-b10 box-sizing: border-box; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; widows: 2; -webkit-text-stroke-width: 0px; text-decoration-className: initial; text-decoration-color: initial; word-spacing: 0px; margin: 0in 0in 22.5pt; ">
                                          <span className="font-family: 'Arial',sans-serif; color: #333333;">
                                            We use Your Personal data to provide
                                            and improve the Service. By using
                                            the Service, you agree to the
                                            collection and use of information in
                                            accordance with this Privacy Policy.{" "}
                                          </span>
                                        </p>
                                        <h4 className="">
                                          Consent
                                        </h4>
                                        <p className="m-b20">
                                          By using our website, you hereby
                                          consent to our Privacy Policy and
                                          agree to its terms.
                                        </p>
                                        <h4 className="">
                                          <h2>
                                            Interpretation and Definitions
                                          </h2>
                                        </h4>
                                        <h4 className="">
                                          <h2>Interpretation</h2>
                                        </h4>
                                        <p className="">
                                          The words of which the initial letter
                                          is capitalized have meanings defined
                                          under the following conditions.
                                        </p>
                                        <p className="m-b20">
                                          The following definitions shall have
                                          the same meaning regardless of whether
                                          they appear in singular or in plural.
                                        </p>
                                        <h4 className="">
                                          <h2>Definitions</h2>
                                        </h4>
                                        <p className="m-b20">
                                          For the purposes of this Privacy
                                          Policy:
                                        </p>
                                        <p className="m-b20">
                                          <h2>You: </h2>means the individual
                                          accessing or using the Service, or the
                                          company, or other legal entity on
                                          behalf of which such individual is
                                          accessing or using the Service, as
                                          applicable.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Company: </h2>(referred to as
                                          either &#8220;the Company&#8221;,
                                          &#8220;We&#8221;, &#8220;Us&#8221; or
                                          &#8220;Our&#8221; in this Agreement)
                                          refers to Gobeens Technology LLP, E-260
                                          B(A), 4th Floor, Industrial Area Phase
                                          8B, S.A.S Nagar Mohali, Punjab &#8211;
                                          160071.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Affiliate: </h2>means an entity
                                          that controls, is controlled by or is
                                          under common control with a party,
                                          where &#8220;control&#8221; means
                                          ownership of 50% or more of the
                                          shares, equity interest or other
                                          securities entitled to vote for
                                          election of directors or other
                                          managing authority.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Account: </h2>means a unique
                                          account created for You to access our
                                          Service or parts of our Service.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Website: </h2>refers to Gobeens
                                          Global, accessible from
                                          http://www.Gobeensglobal.com or
                                          https://www.Gobeensglobal.com
                                        </p>
                                        <p className="m-b20">
                                          <h2>Service: </h2>refers to the
                                          Website.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Country: </h2>refers to:
                                          Chandigarh, India
                                        </p>
                                        <p className="m-b20">
                                          <h2>Service Provider: </h2>
                                          means any natural or legal person who
                                          processes the data on behalf of the
                                          Company. It refers to third-party
                                          companies or individuals employed by
                                          the Company to facilitate the Service,
                                          to provide the Service on behalf of
                                          the Company, to perform services
                                          related to the Service or to assist
                                          the Company in analyzing how the
                                          Service is used.
                                        </p>
                                        <p className="m-b20">
                                          <h2>
                                            Third-party Social Media Service:{" "}
                                          </h2>
                                          refers to any website or any social
                                          network website through which a User
                                          can log in or create an account to use
                                          the Service.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Personal Data: </h2>is any
                                          information that relates to an
                                          identified or identifiable individual.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Cookies: </h2>are small files that
                                          are placed on Your computer, mobile
                                          device or any other device by a
                                          website, containing the details of
                                          Your browsing history on that website
                                          among its many uses.
                                        </p>
                                        <p className="m-b20">
                                          <h2>Usage Data: </h2>refers to data
                                          collected automatically, either
                                          generated by the use of the Service or
                                          from the Service infrastructure itself
                                          (for example, the duration of a page
                                          visit).
                                        </p>
                                        <h4 className="m-b20">
                                          <h2>
                                            Collecting and Using Your Personal
                                            Data
                                          </h2>
                                        </h4>
                                        <h4 className="m-b20">
                                          <h2>Types of Data Collected</h2>
                                        </h4>
                                        <h5 className="">
                                          Personal Data
                                        </h5>
                                        <p className="">
                                          While using Our Service, We may ask
                                          You to provide Us with certain
                                          personally identifiable information
                                          that can be used to contact or
                                          identify You. Personally identifiable
                                          information may include, but is not
                                          limited to:
                                        </p>
                                        <p className="">
                                          &#8211; Email address
                                        </p>
                                        <p className="">
                                          &#8211; First name and last name
                                        </p>
                                        <p className="">
                                          &#8211; Phone number
                                        </p>
                                        <p className="m-b10">
                                          &#8211; Usage Data
                                        </p>
                                        <h5 className="">
                                          Usage Data
                                        </h5>
                                        <p className="">
                                          Usage Data is collected automatically
                                          when using the Service.
                                        </p>
                                        <p className="">
                                          Usage Data may include information
                                          such as Your Device&#8217;s Internet
                                          Protocol address (e.g. IP address),
                                          browser type, browser version, the
                                          pages of our Service that You visit,
                                          the time and date of Your visit, the
                                          time spent on those pages, unique
                                          device identifiers and other
                                          diagnostic data.
                                        </p>
                                        <p className="">
                                          When You access the Service by or
                                          through a mobile device, We may
                                          collect certain information
                                          automatically, including, but not
                                          limited to, the type of mobile device
                                          You use, Your mobile device unique ID,
                                          the IP address of Your mobile device,
                                          Your mobile operating system, the type
                                          of mobile Internet browser You use,
                                          unique device identifiers and other
                                          diagnostic data.
                                        </p>
                                        <p className="m-b10">
                                          We may also collect information that
                                          Your browser sends whenever You visit
                                          our Service or when You access the
                                          Service by or through a mobile device.
                                        </p>
                                        <h4 className="">
                                          Tracking Technologies and Cookies
                                        </h4>
                                        <p className="">
                                          We use Cookies and similar tracking
                                          technologies to track the activity on
                                          Our Service and store certain
                                          information. Tracking technologies
                                          used are beacons, tags, and scripts to
                                          collect and track information and to
                                          improve and analyze Our Service.
                                        </p>
                                        <p className="">
                                          You can instruct Your browser to
                                          refuse all Cookies or to indicate when
                                          a Cookie is being sent. However, if
                                          You do not accept Cookies, You may not
                                          be able to use some parts of our
                                          Service.
                                        </p>
                                        <p className="">
                                          Cookies can be
                                          &#8220;Persistent&#8221; or
                                          &#8220;Session&#8221; Cookies.
                                          Persistent Cookies remain on your
                                          personal computer or mobile device
                                          when You go offline, while Session
                                          Cookies are deleted as soon as You
                                          close your web browser. Learn more
                                          about cookies here:{" "}
                                          <a href="https://www.freeprivacypolicy.com/blog/cookies/">
                                            Cookies: What Do They Do?
                                          </a>
                                        </p>
                                        <p className="m-b20">
                                          We use both session and persistent
                                          Cookies for the purposes set out
                                          below:
                                        </p>
                                        <p className="">
                                          <h2>Necessary / Essential Cookies</h2>
                                        </p>
                                        <p className="">
                                          Type: Session Cookies
                                        </p>
                                        <p className="">
                                          Administered by: Us
                                        </p>
                                        <p className="m-b20">
                                          Purpose: These Cookies are essential
                                          to provide You with services available
                                          through the Website and to enable You
                                          to use some of its features. They help
                                          to authenticate users and prevent
                                          fraudulent use of user accounts.
                                          Without these Cookies, the services
                                          that You have asked for cannot be
                                          provided, and We only use these
                                          Cookies to provide You with those
                                          services.
                                        </p>
                                        <p className="">
                                          <h2>
                                            Cookies Policy / Notice Acceptance
                                            Cookies
                                          </h2>
                                        </p>
                                        <p className="">
                                          Type: Persistent Cookies
                                        </p>
                                        <p className="">
                                          Administered by: Us
                                        </p>
                                        <p className="m-b20">
                                          Purpose: These Cookies identify if
                                          users have accepted the use of cookies
                                          on the Website.
                                        </p>
                                        <p className="">
                                          <h2>Functionality Cookies</h2>
                                        </p>
                                        <p className="">
                                          Type: Persistent Cookies
                                        </p>
                                        <p className="">
                                          Administered by: Us
                                        </p>
                                        <p className="">
                                          Purpose: These Cookies allow us to
                                          remember choices You make when You use
                                          the Website, such as remembering your
                                          login details or language preference.
                                          The purpose of these Cookies is to
                                          provide You with a more personal
                                          experience and to avoid You having to
                                          re-enter your preferences every time
                                          You use the Website.
                                        </p>
                                        <p className="m-b20">
                                          For more information about the cookies
                                          we use and your choices regarding
                                          cookies, please visit our Cookies
                                          Policy.
                                        </p>
                                        <h4 className="">
                                          <h2>Use of Your Personal Data</h2>
                                        </h4>
                                        <p className="m-b20">
                                          The Company may use Personal Data for
                                          the following purposes:
                                        </p>
                                        <p className="m-b20">
                                          <h2>
                                            To provide and maintain our Service
                                          </h2>
                                          , including to monitor the usage of
                                          our Service.
                                        </p>
                                        <p className="m-b20">
                                          <h2>To manage Your Account:</h2>
                                          to manage Your registration as a user
                                          of the Service. The Personal Data You
                                          provide can give You access to
                                          different functionalities of the
                                          Service that are available to You as a
                                          registered user.
                                        </p>
                                        <p className="m-b20">
                                          <h2>
                                            For the performance of a contract:
                                          </h2>
                                          the development, compliance and
                                          undertaking of the purchase contract
                                          for the products, items or services
                                          You have purchased or of any other
                                          contract with Us through the Service.
                                        </p>
                                        <p className="m-b20">
                                          <h2>To contact You:</h2>To contact You
                                          by email, telephone calls, SMS, or
                                          other equivalent forms of electronic
                                          communication, such as a mobile
                                          application&#8217;s push notifications
                                          regarding updates or informative
                                          communications related to the
                                          functionalities, products or
                                          contracted services, including the
                                          security updates, when necessary or
                                          reasonable for their implementation.
                                        </p>
                                        <p className="m-b20">
                                          <h2>To provide You</h2>with news,
                                          special offers and general information
                                          about other goods, services and events
                                          which we offer that are similar to
                                          those that you have already purchased
                                          or enquired about unless You have
                                          opted not to receive such information.
                                        </p>
                                        <p className="">
                                          <h2>To manage Your requests:</h2>
                                          To attend and manage Your requests to
                                          Us.
                                        </p>
                                        <p className="m-b20">
                                          We may share your personal information
                                          in the following situations:
                                        </p>
                                        <p className="m-b20">
                                          <h2>With Service Providers:</h2>
                                          We may share Your personal information
                                          with Service Providers to monitor and
                                          analyze the use of our Service, to
                                          show advertisements to You to help
                                          support and maintain Our Service, to
                                          contact You, to advertise on third
                                          party websites to You after You
                                          visited our Service or for payment
                                          processing.
                                        </p>
                                        <p className="m-b20">
                                          <h2>For Business transfers:</h2>
                                          We may share or transfer Your personal
                                          information in connection with, or
                                          during negotiations of, any merger,
                                          sale of Company assets, financing, or
                                          acquisition of all or a portion of our
                                          business to another company.
                                        </p>
                                        <p className="m-b20">
                                          <h2>With Affiliates:</h2>We may share
                                          Your information with Our affiliates,
                                          in which case we will require those
                                          affiliates to honor this Privacy
                                          Policy. Affiliates include Our parent
                                          company and any other subsidiaries,
                                          joint venture partners or other
                                          companies that We control or that are
                                          under common control with Us.
                                        </p>
                                        <p className="m-b20">
                                          <h2>With Business partners:</h2>
                                          We may share Your information with Our
                                          business partners to offer You certain
                                          products, services or promotions.
                                        </p>
                                        <p className="m-b20">
                                          <h2>With other users:</h2>when You
                                          share personal information or
                                          otherwise interact in the public areas
                                          with other users, such information may
                                          be viewed by all users and may be
                                          publicly distributed outside. If You
                                          interact with other users or register
                                          through a Third-Party Social Media
                                          Service, Your contacts on the
                                          Third-Party Social Media Service may
                                          see You name, profile, pictures and
                                          description of Your activity.
                                          Similarly, other users will be able to
                                          view descriptions of Your activity,
                                          communicate with You and view Your
                                          profile.
                                        </p>
                                        <h4 className="m-b20">
                                          <h2>
                                            Retention of Your Personal Data
                                          </h2>
                                        </h4>
                                        <p className="">
                                          The Company will retain Your Personal
                                          Data only for as long as is necessary
                                          for the purposes set out in this
                                          Privacy Policy. We will retain and use
                                          Your Personal Data to the extent
                                          necessary to comply with our legal
                                          obligations (for example, if we are
                                          required to retain your data to comply
                                          with applicable laws), resolve
                                          disputes, and enforce our legal
                                          agreements and policies.
                                        </p>
                                        <p className="m-b20">
                                          The Company will also retain Usage
                                          Data for internal analysis purposes.
                                          Usage Data is generally retained for a
                                          shorter period of time, except when
                                          this data is used to strengthen the
                                          security or to improve the
                                          functionality of Our Service, or We
                                          are legally obligated to retain this
                                          data for longer time periods.
                                        </p>
                                        <h4 className="">
                                          <h2>
                                            Transfer of Your Personal Data
                                          </h2>
                                        </h4>
                                        <p className="">
                                          Your information, including Personal
                                          Data, is processed at the
                                          Company&#8217;s operating offices and
                                          in any other places where the parties
                                          involved in the processing are
                                          located. It means that this
                                          information may be transferred to —
                                          and maintained on — computers located
                                          outside of Your state, province,
                                          country or other governmental
                                          jurisdiction where the data protection
                                          laws may differ than those from Your
                                          jurisdiction.
                                        </p>
                                        <p className="">
                                          Your consent to this Privacy Policy
                                          followed by Your submission of such
                                          information represents Your agreement
                                          to that transfer.
                                        </p>
                                        <p className="m-b20">
                                          The Company will take all steps
                                          reasonably necessary to ensure that
                                          Your data is treated securely and in
                                          accordance with this Privacy Policy
                                          and no transfer of Your Personal Data
                                          will take place to an organization or
                                          a country unless there are adequate
                                          controls in place including the
                                          security of Your data and other
                                          personal information.
                                        </p>
                                        <h4 className="">
                                          <h2>
                                            Disclosure of Your Personal Data
                                          </h2>
                                        </h4>
                                        <h5 className="">
                                          Business Transactions
                                        </h5>
                                        <p className="m-b10">
                                          If the Company is involved in a
                                          merger, acquisition or asset sale,
                                          Your Personal Data may be transferred.
                                          We will provide notice before Your
                                          Personal Data is transferred and
                                          becomes subject to a different Privacy
                                          Policy.
                                        </p>
                                        <h5 className="">
                                          Law enforcement
                                        </h5>
                                        <p className="m-b10">
                                          Under certain circumstances, the
                                          Company may be required to disclose
                                          Your Personal Data if required to do
                                          so by law or in response to valid
                                          requests by public authorities (e.g. a
                                          court or a government agency).
                                        </p>
                                        <h4 className="">
                                          Other legal requirements
                                        </h4>
                                        <p className="">
                                          The Company may disclose Your Personal
                                          Data in the good faith belief that
                                          such action is necessary to:
                                        </p>
                                        <p className="">
                                          Comply with a legal obligation
                                        </p>
                                        <p className="">
                                          Protect and defend the rights or
                                          property of the Company
                                        </p>
                                        <p className="">
                                          Prevent or investigate possible
                                          wrongdoing in connection with the
                                          Service
                                        </p>
                                        <p className="">
                                          Protect the personal safety of Users
                                          of the Service or the public
                                        </p>
                                        <p className="m-b20">
                                          Protect against legal liability
                                        </p>
                                        <h4 className="">
                                          <h2>
                                            Security of Your Personal Data
                                          </h2>
                                        </h4>
                                        <p className="m-b10">
                                          The security of Your Personal Data is
                                          important to Us, but remember that no
                                          method of transmission over the
                                          Internet, or method of electronic
                                          storage is 100% secure. While We
                                          strive to use commercially acceptable
                                          means to protect Your Personal Data,
                                          We cannot guarantee its absolute
                                          security.
                                        </p>
                                        <h4 className="">
                                          Log Files
                                        </h4>
                                        <p className="m-b10">
                                          Gobeens Technology follows a standard
                                          procedure of using log files. These
                                          files log visitors when they visit
                                          websites. All hosting companies do
                                          this and a part of hosting
                                          services&#8217; analytics. The
                                          information collected by log files
                                          include internet protocol (IP)
                                          addresses, browser type, Internet
                                          Service Provider (ISP), date and time
                                          stamp, referring/exit pages, and
                                          possibly the number of clicks. These
                                          are not linked to any information that
                                          is personally identifiable. The
                                          purpose of the information is for
                                          analyzing trends, administering the
                                          site, tracking users&#8217; movement
                                          on the website, and gathering
                                          demographic information.
                                        </p>
                                        <h4 className="">
                                          Google DoubleClick DART Cookie
                                        </h4>
                                        <p className="m-b10">
                                          Google is one of a third-party vendor
                                          on our site. It also uses cookies,
                                          known as DART cookies, to serve ads to
                                          our site visitors based upon their
                                          visit to www.website.com and other
                                          sites on the internet. However,
                                          visitors may choose to decline the use
                                          of DART cookies by visiting the Google
                                          ad and content network Privacy Policy
                                          at the following URL –{" "}
                                          <a href="https://policies.google.com/technologies/ads">
                                            https://policies.google.com/technologies/ads
                                          </a>
                                        </p>
                                        <h4 className="">
                                          Our Advertising Partners
                                        </h4>
                                        <p className="m-b10">
                                          Some of advertisers on our site may
                                          use cookies and web beacons. Our
                                          advertising partners are listed below.
                                          Each of our advertising partners has
                                          their own Privacy Policy for their
                                          policies on user data. For easier
                                          access, we hyperlinked to their
                                          Privacy Policies below.
                                        </p>
                                        <p className="">
                                          Google
                                        </p>
                                        <p className="m-b10">
                                          <a href="https://policies.google.com/technologies/ads">
                                            https://policies.google.com/technologies/ads
                                          </a>
                                        </p>
                                        <h4 className="">
                                          Advertising Partners Privacy Policies
                                        </h4>
                                        <p className="">
                                          You may consult this list to find the
                                          Privacy Policy for each of the
                                          advertising partners of Gobeens Technology.
                                        </p>
                                        <p className="">
                                          Third-party ad servers or ad networks
                                          uses technologies like cookies,
                                          JavaScript, or Web Beacons that are
                                          used in their respective
                                          advertisements and links that appear
                                          on Gobeens Technology, which are sent
                                          directly to users&#8217; browser. They
                                          automatically receive your IP address
                                          when this occurs. These technologies
                                          are used to measure the effectiveness
                                          of their advertising campaigns and/or
                                          to personalize the advertising content
                                          that you see on websites that you
                                          visit.
                                        </p>
                                        <p className="m-b10">
                                          Note that Gobeens Technology has no access
                                          to or control over these cookies that
                                          are used by third-party advertisers.
                                        </p>
                                        <h4 className="">
                                          Third Party Privacy Policies
                                        </h4>
                                        <p className="">
                                          Gobeens Technology&#8217;s Privacy Policy
                                          does not apply to other advertisers or
                                          websites. Thus, we are advising you to
                                          consult the respective Privacy
                                          Policies of these third-party ad
                                          servers for more detailed information.
                                          It may include their practices and
                                          instructions about how to opt-out of
                                          certain options. You may find a
                                          complete list of these Privacy
                                          Policies and their links here: Privacy
                                          Policy Links.
                                        </p>
                                        <p className="m-b10">
                                          You can choose to disable cookies
                                          through your individual browser
                                          options. To know more detailed
                                          information about cookie management
                                          with specific web browsers, it can be
                                          found at the browsers&#8217;
                                          respective websites. What Are Cookies?
                                        </p>
                                        <h4 className="">
                                          CCPA Privacy Rights (Do Not Sell My
                                          Personal Information)
                                        </h4>
                                        <p className="">
                                          Under the CCPA, among other rights,
                                          California consumers have the right
                                          to:
                                        </p>
                                        <p className="">
                                          Request that a business that collects
                                          a consumer&#8217;s personal data
                                          disclose the categories and specific
                                          pieces of personal data that a
                                          business has collected about
                                          consumers.
                                        </p>
                                        <p className="">
                                          Request that a business delete any
                                          personal data about the consumer that
                                          a business has collected.
                                        </p>
                                        <p className="">
                                          Request that a business that sells a
                                          consumer&#8217;s personal data, not
                                          sell the consumer&#8217;s personal
                                          data.
                                        </p>
                                        <p className="m-b10">
                                          If you make a request, we have one
                                          month to respond to you. If you would
                                          like to exercise any of these rights,
                                          please contact us.
                                        </p>
                                        <h4 className="">
                                          GDPR Data Protection Rights
                                        </h4>
                                        <p className="">
                                          We would like to make sure you are
                                          fully aware of all of your data
                                          protection rights. Every user is
                                          entitled to the following:
                                        </p>
                                        <p className="">
                                          The right to access – You have the
                                          right to request copies of your
                                          personal data. We may charge you a
                                          small fee for this service.
                                        </p>
                                        <p className="">
                                          The right to rectification – You have
                                          the right to request that we correct
                                          any information you believe is
                                          inaccurate. You also have the right to
                                          request that we complete the
                                          information you believe is incomplete.
                                        </p>
                                        <p className="">
                                          The right to erasure – You have the
                                          right to request that we erase your
                                          personal data, under certain
                                          conditions.
                                        </p>
                                        <p className="">
                                          The right to restrict processing – You
                                          have the right to request that we
                                          restrict the processing of your
                                          personal data, under certain
                                          conditions.
                                        </p>
                                        <p className="">
                                          The right to object to processing –
                                          You have the right to object to our
                                          processing of your personal data,
                                          under certain conditions.
                                        </p>
                                        <p className="">
                                          The right to data portability – You
                                          have the right to request that we
                                          transfer the data that we have
                                          collected to another organization, or
                                          directly to you, under certain
                                          conditions.
                                        </p>
                                        <p className="m-b10">
                                          If you make a request, we have one
                                          month to respond to you. If you would
                                          like to exercise any of these rights,
                                          please contact us.
                                        </p>
                                        <h4 className="">
                                          Children&#8217;s Information
                                        </h4>
                                        <p className="">
                                          Another part of our priority is adding
                                          protection for children while using
                                          the internet. We encourage parents and
                                          guardians to observe, participate in,
                                          and/or monitor and guide their online
                                          activity.
                                        </p>
                                        <p className="m-b20">
                                          Gobeens Technology does not knowingly
                                          collect any Personal Identifiable
                                          Information from children under the
                                          age of 13. If you think that your
                                          child provided this kind of
                                          information on our website, we h2ly
                                          encourage you to contact us
                                          immediately and we will do our best
                                          efforts to promptly remove such
                                          information from our records.
                                        </p>
                                        <h4 className="">
                                          <h2>Copyrights and Trademarks</h2>
                                        </h4>
                                        <p className="m-b10">
                                          The material available on this website
                                          is current and accurate. It is the
                                          sole property of Gobeens Technology LLP. We
                                          do not allow anyone to re-print the
                                          material available on this site
                                          without a prior written permission.
                                        </p>
                                        <h4 className="">
                                          Changes to this Privacy Policy
                                        </h4>
                                        <p className="">
                                          We may update our Privacy Policy from
                                          time to time. We will notify You of
                                          any changes by posting the new Privacy
                                          Policy on this page.
                                        </p>
                                        <p className="">
                                          We will let You know via email and/or
                                          a prominent notice on Our Service,
                                          prior to the change becoming effective
                                          and update the &#8220;Last
                                          updated&#8221; date at the top of this
                                          Privacy Policy.
                                        </p>
                                        <p className="m-b10">
                                          You are advised to review this Privacy
                                          Policy periodically for any changes.
                                          Changes to this Privacy Policy are
                                          effective when they are posted on this
                                          page.
                                        </p>
                                        <h4 className="">
                                          Contact Us
                                        </h4>
                                        <p className="">
                                          If you have any questions about this
                                          Privacy Policy, You can contact us:
                                        </p>
                                        <p className="">
                                          By email:  Info@gobeens.com
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
