import React from "react";
import { useFormik } from "formik";
import Header from "../../Layout/Header";
import Footer from "./../../Layout/Footer";
import PageTitle from "./../../Layout/PageTitle";
import { AiOutlineSearch } from "react-icons/ai";

import bnr1 from "./../../../images/banner/bnr4.jpg";
import "./JobsDetails.css";
// import { useState } from "react";
import { Validation } from "./Validation";
import JobsSidebar from "./JobSiderbar"

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  resume: "",
};

const ServicesDetails = () => {
  // const [job, setJob] = useState([]);

  const {
    values,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      console.log(values, "gfghjkl;jhgf");
    },
  });
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <PageTitle motherMenu="Jobs Details" activeMenu="Jobs Details" />
        </div>
        {/* <!-- inner page banner END --> */}
        <section class="job-details-section section-gap">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <div class="job-details-wrapper">
                  <div class="job-details">
                    <h3 class="title">Software Engineer - Laravel, Vue JS</h3>
                    <div class="info">
                      <strong class="label">Vacancy</strong>
                      <div class="desc">3</div>
                    </div>
                    <div class="info">
                      <strong class="label">Job Responsibilities</strong>
                      <div class="desc">
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced with UI frameworks in
                          general; we love the Angular and Angular material.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced in building Single Page
                          Applications (SPA) &amp; integrating Web (Rest) API.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          Well versed with Angular modules or you have created
                          custom and web components by yourself.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You must have practical experience on Node.js,
                          TypeScript, webpack and Third party library.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have an in-depth understanding of cross browser
                          compatibility and you do not create bugs for such
                          reasons.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You are pixel-perfect in design conversions and you
                          think of mobile-first implementations.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You should have knowledge of CSS preprocessors, Media
                          queries, Image compression and be good with debugging.
                        </span>
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">Employment Status</strong>
                      <div class="desc">Full-time</div>
                    </div>
                    <div class="info">
                      <strong class="label">Educational Requirements</strong>
                      <div class="desc">
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced with UI frameworks in
                          general; we love the Angular and Angular material.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced in building Single Page
                          Applications (SPA) &amp; integrating Web (Rest) API.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          Well versed with Angular modules or you have created
                          custom and web components by yourself.
                        </span>
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">Experience Requirements</strong>
                      <div class="desc">
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced with UI frameworks in
                          general; we love the Angular and Angular material.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have to be experienced in building Single Page
                          Applications (SPA) &amp; integrating Web (Rest) API.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          Well versed with Angular modules or you have created
                          custom and web components by yourself.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You should have knowledge of CSS preprocessors, Media
                          queries, Image compression and be good with debugging.
                        </span>
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">Additional Requirements</strong>
                      <div class="desc">
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have an in-depth understanding of cross browser
                          compatibility and you do not create bugs for such
                          reasons.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You are pixel-perfect in design conversions and you
                          think of mobile-first implementations.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You should have knowledge of CSS preprocessors, Media
                          queries, Image compression and be good with debugging.
                        </span>
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">Job Location</strong>
                      <div class="desc">Remote Job</div>
                    </div>
                    <div class="info">
                      <strong class="label">Salary</strong>
                      <div class="desc">
                        $2000
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">
                        Compensation &amp; Other Benefits
                      </strong>
                      <div class="desc">
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You have an in-depth understanding of cross browser
                          compatibility and you do not create bugs for such
                          reasons.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You are pixel-perfect in design conversions and you
                          think of mobile-first implementations.
                        </span>
                        <span
                          style={{ fontSize: "10.5pt", lineHeight: "28px" }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            backgroundImage: "initial",
                            backgroundPosition: "initial",
                            backgroundSize: "initial",
                            backgroundRepeat: "initial",
                            backgroundAttachment: "initial",
                            backgroundOrigin: "initial",
                            backgroundClip: "initial",
                            lineHeight: "28px",
                          }}
                        >
                          You should have knowledge of CSS preprocessors, Media
                          queries, Image compression and be good with debugging.
                        </span>
                        <br />
                      </div>
                    </div>
                    <div class="info">
                      <strong class="label">Read Before Apply</strong>
                      <div class="desc">
                        <br />
                      </div>
                    </div>
                    <button
                      type="button"
                      class="site-button primary"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                {/* <!-- sidebar --> */}
                <div class="sidebar">
                  {/* <!-- Search Widget --> */}
                  <div class="widget search-widget">
                    <form action="/#">
                      <input type="text" placeholder="Search here" />
                      <button type="submit">
                        <AiOutlineSearch className="bold" />
                      </button>
                    </form>
                  </div>
                  {/* <!-- Cat Widget --> */}
                  {/* <div class="widget cat-widget">
                    <h4 class="widget-title">Category</h4>
                    <ul>
                      <li>
                        <a href="/#">
                          All<span>(15)</span>
                        </a>
                      </li>
                      <li>
                          <a href="//#">
                            Web Development <span>(10)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Ecommerce Development<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Mobile App Development<span>(04)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Marketing Strategy<span>(10)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Digital Marketing $ SEO<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                           Custom App Development<span>(05)</span>
                          </a>
                        </li>
                        <li>
                          <a href="//#">
                            Outsourcing Service<span>(05)</span>
                          </a>
                        </li>
                    </ul>
                  </div> */}
                  <JobsSidebar/>
                  <div class="widget newsletter-widget">
                    <div class="newsletter-content">
                      <h5 className="text">SUBSCRIBE</h5>
                      <h4 className="texts">SUBSCRIBE FOR NEWSLETTER</h4>
                      <form>
                        <div class="form_group">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            required
                          />
                          <span class="pad">
                            <button class="dez-page  site-button primary ">
                              Subscribe
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Popup  Button--> */}
          {/* Popup Modal */}
          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Apply Now
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      style={{ color: "black", fontSize: 30 }}
                      aria-hidden="true"
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <div class="modal-body">
                  {/* Modal Body */}
                  <form onSubmit={handleSubmit}>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-medium mb-2"
                        htmlFor="name"
                      >
                        Full Name
                      </label>
                      <input
                        class="form-control"
                        id="name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Full Name"
                      />
                      {errors.name && touched.name ? (
                        <p className="text-sm text-red-600">{errors.name}</p>
                      ) : null}
                    </div>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-medium mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        class="form-control"
                        id="email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <p className="text-sm text-red-600">{errors.email}</p>
                      ) : null}
                    </div>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-medium mb-2"
                        htmlFor="mobile"
                      >
                        Mobile
                      </label>
                      <input
                        class="form-control"
                        id="mobile"
                        type="tel"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Mobile No."
                      />
                      {errors.mobile && touched.mobile ? (
                        <p className="text-sm text-red-600">{errors.mobile}</p>
                      ) : null}
                    </div>
                    {/* <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-medium mb-2"
                        htmlFor="position"
                      >
                        You Apply For
                      </label>
                      <select
                        name="position"
                        class="form-control"
                        id="position"
                        disabled
                      >
                        <option value={job.position}>{job.position}</option>
                      </select>
                    </div> */}
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-medium mb-2"
                        htmlFor="resume"
                      >
                        Upload Resume
                      </label>
                      <input
                        class="form-control"
                        id="resume"
                        type="file"
                        name="resume"
                        onChange={(e) => {
                          setFieldValue("resume", e.target.files[0]);
                        }}
                      />
                    </div>
                    <button className="bg-black text-white px-4 rounded py-2">
                      Apply
                    </button>
                  </form>
                </div>
                {/* <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save changes
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default ServicesDetails;
