import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Slider1 from "../../../images/background/seobgi.jpg";
import PageTitle from "../../Layout/PageTitle";
// import Slider2 from "./../../images/main-slider/Home_2.jpg";
// import Slider3 from "./../../images/main-slider/Home_3.jpg";
import "./Seo.css";
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

class HomeOwlSlider extends Component {
  render() {
    var settings = {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
      <Slider
        className="owl-slider owl-carousel owl-theme owl-none"
        {...settings}
      >
        <div className="item slide-item">
          <div className="slide-item-img">
            <img src={Slider1} className="w-100" alt="" />
          </div>
          <div className="slide-content overlay-primary">
            <div className="slide-content-box container">
              <div className="centered">
                <h2 className="" style={{fontSize:"40px", color:"white", fontWeight:"normal"}}>
                  SEO Services to Boost Your Organic Traffic
                </h2>
                <p style={{color:"white", fontSize:"20px",fontWeight:"normal"}}>
                  Are you seeking an exponential increase in web traffic for
                  your website, which also results in incremental revenues?
                  Partner with a globally acknowledged seo services provider
                  which ensures performance driven SEO.
                </p>
                <Link
                  to="/contact"
                  className="get site-button m-r10 white button-lg"
                >
                  Get In Touch
                </Link>
                {/* <Link
                  to="/aboutus"
                  className="how site-button outline outline-2 button-lg"
                >
                  How It Work
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </Slider>
      </>
    );
  }
}

export default HomeOwlSlider;
