import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./HomeTab.css";

//Images
import pic5 from "./../../images/about/pic5.jpg";
import pic6 from "./../../images/about/pic6.jpg";
import pic7 from "./../../images/about/pic7.jpg";
import pic8 from "./../../images/about/pic8.jpg";

function Para() {
  return (
    <>
      <div className="abuot-box p-lr30">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </div>
    </>
  );
}

const HomeTab = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="dlab-tabs choseus-tabs">
          <ul className="nav sabtheek sty" id="myTab" role="tablist">
            {/* <li>
							<a className= {classnames({ active : activeTab === '1' }) + ' className if avialble'}  onClick={() => { toggle('1'); }}	
							><span className="title-head">Cost Effectiveness</span></a>
						</li> */}
            <li className="lol">
              <Link
                to={"#"}
                className={classnames({ active: activeTab === "1" }) + ""}
                onClick={() => {
                  toggle("1");
                }}
              >
                <span className="title-head ">Cost Effectiveness</span>
              </Link>
            </li>
            <li className="lol">
              <Link
                to={"#"}
                className={classnames({ active: activeTab === "2" }) + ""}
                onClick={() => {
                  toggle("2");
                }}
              >
                <span className="title-head ">Flexible Engagement Models</span>
              </Link>
            </li>
            <li className="lol">
              <Link
                to={"#"}
                className={classnames({ active: activeTab === "3" }) + ""}
                onClick={() => {
                  toggle("3");
                }}
              >
                <span className="title-head">Technology Expertization</span>
              </Link>
            </li>
            <li className="lol">
              <Link
                to={"#"}
                className={classnames({ active: activeTab === "4" }) + ""}
                onClick={() => {
                  toggle("4");
                }}
              >
                <span className="title-head">Pre-Built Packages</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="tab-content chosesus-content">
          <div id="cost" className="tab-pane active">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="row p-tb50">
                  <div className="col-lg-6 m-b10 col-md-4 about-img ">
                    <img src={pic5} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-8 text-white">
                    <div className="p-l30 ">
                      <h4 className="font-weight-300">
                        Project Cost Effectiveness
                      </h4>
                      <ul className="list-checked primary">
                        <li>
                          <span>
                            Best and result-oriented digital marketing
                            solutions.
                          </span>
                        </li>
                        <li>
                          <span>Saving of costs due to lesser spending.</span>
                        </li>
                        <li>
                          <span>
                            Increased ROI due to reduced promotion expenditure
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="abuot-box p-lr30">
                      <p>
                        To help your business grow and succeed more, Gobeens
                        Global offers a wide range of custom and cutting-edge
                        digital marketing solutions. They are designed keeping
                        in view unique marketing requirements and varying
                        budgetary needs of businesses. These proven and
                        efficient solutions not only save your costs that get
                        spent in traditional marketing, but also give your
                        business various gains. These include increased business
                        brand awareness, customer engagement and customer
                        loyalty. Moreover, they enable your business to achieve
                        more traffic, leads, sales and revenue. Apart from
                        giving your business an edge over your rival businesses,
                        they also help you achieve enhanced return on
                        investment.   
                      </p>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row p-tb50">
                  <div className="col-lg-6 col-md-4 about-img">
                    <img src={pic6} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-8 text-white">
                    <div className="p-l30">
                      <h4 className="font-weight-300">
                        Flexible Engagement Models
                      </h4>
                      <ul className="list-checked primary">
                        <li>
                          <span>
                            Ideal for businesses belonging to diverse industries
                            and with different business needs 
                          </span>
                        </li>
                        <li>
                          <span>Minimize cost on website development </span>
                        </li>
                        <li>
                          <span>Provide access to best of IT experts  </span>
                        </li>
                      </ul>
                    </div>
                    <div className="abuot-box p-lr30">
                      <p>
                        We provide your business the privilege to choose between
                        various flexible engagement models we are offering.
                        These include fixed cost, time and material and
                        dedicated resource. Fixed cost model is suitable for
                        small to mid-sized projects, while time and material
                        model is ideal for projects requiring a defined or
                        open-ended number of hours where requirement
                        specifications are evolving. In the dedicated resource
                        model, we provide you resources that are dedicated
                        solely to your project. Our flexible engagement models
                        enable your business to achieve more efficiency and
                        significant cost savings as our IT experts work towards
                        the success of your project.   
                      </p>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="row p-tb50">
                  <div className="col-lg-6 col-md-4 about-img">
                    <img src={pic7} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-8 text-white">
                    <div className="p-l30">
                      <h4 className="font-weight-300">
                        Technology Expertization
                      </h4>
                      <ul className="list-checked primary">
                        <li>
                          <span>
                            Experts with knowledge of the latest mobile app
                          </span>
                        </li>
                        <li>
                          <span>Build outstanding and scalable solutions</span>
                        </li>
                        <li>
                          <span>
                            Best practices followed while building and testing
                            apps
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="abuot-box p-lr30">
                      <p>
                        Our seasoned IT experts have impeccable knowledge of the
                        trending and cutting edge web design and development,
                        mobile app development and digital marketing
                        technologies, tools and processes. These include
                        WordPress, Django, jQuery, PhotoShop, Elementor besides
                        others. Our specialists utilize this deep knowledge for
                        building a wide array of unique, modern and customized
                        applications and solutions for diverse businesses. The
                        applications are vigorously tested, fully functional and
                        user friendly. They help to add value to the businesses
                        by boosting their marketing and customer engagement,
                        landing more business opportunities for them and
                        improving their bottom line. Also, the applications play
                        a vital role in achievement of specific business
                        goals.   
                      </p>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="row p-tb50">
                  <div className="col-lg-6 col-md-4 about-img">
                    <img src={pic8} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-8 text-white">
                    <div className="p-l30">
                      <h4 className="font-weight-300">Pre-Built Packages</h4>
                      <ul className="list-checked primary">
                        <li>
                          <span>
                            Designed keeping in mind particular marketing
                          </span>
                        </li>
                        <li>
                          <span>
                            Know exactly which solutions and support services
                            are available for your business 
                          </span>
                        </li>
                        <li>
                          <span>
                            Help you choose best option within your budget
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="abuot-box p-lr30">
                      <p>
                        Our pre-built packages help your business smoothly
                        venture into the digital world and lay a strong
                        foundation for its growth and success. They make it
                        crystal clear to you which particular solutions and
                        support services are available to you in various
                        packages. Also, they help you analyze easily which one
                        among them will be the right fit for your business. This
                        in turn will help you better plan, organize and
                        coordinate your marketing activities with our experts
                        besides saving your valuable time in planning them.
                        Also, you can achieve enhanced financial management by
                        allocating your budget optimally and thoughtfully for
                        marketing and various other aspects of your business.  
                      </p>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HomeTab;
