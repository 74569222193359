import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../images/GobeensLogo.png";
import logo2 from "./../../images/logo-black.png";
import HeaderMenu from "./HeaderMenu";
import "./Header.css";
import { MdOutlineEmail } from "react-icons/md";
const Header = () => {
  const [menu, setMenu] = useState(false);

  return (
    <>
      <header
        className="site-header header-transparent mo-left"
        id="fix-header"
      >
        <div className="top-bar">
          <div className="container-xxl ">
            <div className=" d-flex justify-content-between">
              <div className="dlab-topbar-left">
                <ul>
                  <li style={{ color: "white" }}>
                    <i className="flaticon-phone-call m-r5"></i>
                    +91 9988772199
                  </li>
                  <li style={{ color: "white" }}>
                    <i className="ti-location-pin m-r5"></i> Gole Ka Mandir, Mela Road, Gwalior, MP, 474005
                  </li>
                </ul>
              </div>
              <ul>
                <li className="flex space-x-3" style={{ color: "white", opacity: "0.7" }}>
                  <MdOutlineEmail className="mt-1" />{" "}
                  <h5 className="text-base text-gray-300 font-light"> Info@gobeens.com</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar clearfix ">
            <div className="container-xxl clearfix">
              <div>
                <div className="logo-header mostion">
                  <Link to={"./"} className="dez-page" style={{ width: 250 }}>
                    <img src={logo} alt="" />
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>

              <div className="extra-nav">
                <div className="extra-cell">
                  <Link
                    to={"./contact"}
                    className="dez-page  site-button primary"
                  >
                    Inquire Now{" "}
                  </Link>
                </div>
              </div>

              <div
                className="header-nav navbar-collapse  myNavbar justify-content-end"
                id="navbarNavDropdown"
              >
                <div className="logo-header mostion d-md-block d-lg-none">
                  <Link to={"./"} className="dez-page">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                {/*  Header Menu Contents  */}
                <HeaderMenu />
                {/*  Header Menu Contents End */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
