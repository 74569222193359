import React from "react";
import { TiTick } from "react-icons/ti";
import "./TestimonialCarousel1.css";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import bg2 from "../../images/background/bg24.png";
import bg3 from "../../images/background/bg25.png";
const TestimonialCarousel1 = () => {
  return (
    <>
      <section class="wcu-section section-gap">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div
                class="wcu-video wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div
                  class="video-poster-one bg-img-c"
                  style={{ backgroundImage: `url(${bg2} )`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}
          
                ></div>
                <div
                  class="video-poster-two bg-img-c"
                  style={{ backgroundImage: `url(${bg3} )`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}
                >
                  <a
                    href="https://youtu.be/3jRQmO1dDHw"
                    class="popup-video"
                  >
                    <FiPlay />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-10">
              <div class="wcu-text-two">
                <div class="section-title left-border mb-40">
                  <span class="title-tag">Why We Are Best ?</span>
                  <h2 class="title">We Have Many Reasons Why Choose Us</h2>
                </div>
                <p>
                  At Gobeens Technology, fulfilling each and every requirement of
                  yours is our top-most priority. We ensure that all your needs
                  are accounted for and properly addressed. Our clients trust
                  and count on us to keep their operations up and running
                  24x7x365.
                </p>
                <ul class="wcu-list clearfix ">
                  <li>
                    <TiTick class="design" /> Best Services
                  </li>

                  <li>
                    <TiTick class="design" /> Professional Advisor
                  </li>

                  <li>
                    <TiTick class="design" /> Responsive Design
                  </li>

                  <li>
                    <TiTick class="design" /> Awesome Pricing
                  </li>

                  <li>
                    <TiTick class="design" /> Online Support
                  </li>
                </ul>
                <Link to={"/contact"} class="dez-page  site-button primary">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialCarousel1;
