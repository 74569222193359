import React from "react";
import Header from "../../Layout/Header";
import Seobg from "./Seobg";
import "./Seo.css";
import Seoservice from "./Seoservice";
import Footer from "./../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import {
  MdCheckCircle,
  MdOutlineSpaceDashboard,
  MdOutlineAdsClick,
  MdOutlineFilterAlt,
  MdOutlineComputer,
} from "react-icons/md";
import { GiGrowth } from "react-icons/gi";
import { BsAward, BsArrowRepeat } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import { TbTarget } from "react-icons/tb";
import { BsClockHistory } from "react-icons/bs";
import { FaRunning } from "react-icons/fa";
import {
  HiOutlineClipboardList,
  HiOutlineDesktopComputer,
} from "react-icons/hi";
import bnr5 from "./../../../images/banner/1.jpg";
import first from "../../../images/seo/first.webp";
import second from "../../../images/seo/second.webp";
import third from "../../../images/seo/third.webp";
import fourth from "../../../images/seo/fourth.webp";
import FormStyle from "../../Element/FormStyle";
import bgimg3 from "../../../images/background/bg1.jpg";

const Searchengin = () => {
  return (
    <>
      <Header />
      <div className="">
        {/* <Seobg /> */}
        <div
          className="md:h-[500px] overlay-primary object-cover flex justify-center items-center "
          style={{
            backgroundImage: "url(" + bnr5 + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container-xxl mt-32 mx-auto z-10 ">
            <div className="md:w-2/4 text-center mx-auto ">
              <h2 className="text-white text-3xl md:text-5xl">
                Search Engine Optimization
              </h2>
              <p className="text-white tracking-wider">
                Are you seeking an exponential increase in web traffic for your
                website, which also results in incremental revenues? Partner
                with a globally acknowledged seo services provider which ensures
                performance driven SEO.
              </p>
              <div className="md:flex justify-center space-y-2 md:space-x-4 mt-3 items-center">
                <span className="flex bg-white md:p-1 p-2 shadow-md space-x-2 rounded items-center">
                  <MdCheckCircle />
                  <p className="text-xs font-normal">
                    International Award Winner
                  </p>
                </span>
                <span className="flex bg-white md:p-1 p-2 shadow-md space-x-2 rounded items-center">
                  <MdCheckCircle />
                  <p className="text-xs font-normal">150+ tech SEO experts</p>
                </span>
                <span className="flex bg-white md:p-1 p-2 shadow-md space-x-2 rounded items-center">
                  <MdCheckCircle />
                  <p className="text-xs font-normal">Proven success stories</p>
                </span>
              </div>
              <button className="site-button primary text-white p-2 text-sm mt-5 rounded-md px-3 mb-4 md:mb-0 shadow-md">
                Get a Free Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Result Driver Stat */}
      <section className="container-xxl my-10 mx-auto">
        <h2 className="text-center">We Are Driven By Results</h2>
        <div className="grid  container gap-4 py-10 md:grid-cols-3">
          {Stat.map((e) => {
            return (
              <div className="bg-white hover:scale-105 duration-300 hover:shadow-xl rounded-md text-black p-4  flex flex-col items-center shadow-md hover:">
                <h2 className="text-4xl">{e.icon}</h2>

                <p>{e.title}</p>
                <p className="text-4xl text-[#FF3300] font-bold">{e.number}</p>
              </div>
            );
          })}
        </div>
      </section>
      <div className="section-full bg-white content-inner-1 about-us">
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 col-md-8">
              <div className="abuot-box row">
                <div className="col-lg-4">
                  <h2 className="box-title m-tb0">
                    About Us<span className="bg-primary"></span>
                  </h2>
                  <h4 className="text-gray-dark">
                    Make your customers happy by giving services.
                  </h4>
                </div>
                <div className="col-lg-8">
                  <p>
                    The word ‘Gobeens’ means ‘Promise’. Gobeens Technology is a CANDID
                    IT Consulting / Digital Marketing company with a host of
                    services to offer under one umbrella. Founded in the year
                    2019 by a couple of IT enthusiasts who believe in making
                    something big. Today, Gobeens Technology is serving more than 100+
                    Clients across the globe. We are a bunch of interesting
                    people who work day in and day out to achieve the goals of
                    our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 about-img">
              <img
                src="https://cdn-bledd.nitrocdn.com/sMegMNkwPgLIyVZIoLggYdSAqxrSUlEd/assets/static/optimized/rev-be87ab5/wp-content/uploads/2021/09/seo-services-585x483-1.jpg"
                data-tilt
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="relative ">
          <div
            style={{
              backgroundImage: `url("https://miro.medium.com/max/1400/1*TtZ1bAE8DF-RNvBYYXQHgA.jpeg")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="overlay-primary py-20 w-full"
          >
            <div className="container flex flex-col items-center mx-auto">
              <h2 className="  text-white font-medium">
                Make Your Website Rank Higher On Google
              </h2>
              <p
                className="md:text-[20px]"
                style={{
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                You are just one step away from ranking your website higher on
                Google!
              </p>
              <div className=" mt-4 flex flex-col md:items-center">
                <div className="md:flex space-y-3 md:space-y-0 md:space-x-4">
                  <input
                    className="rounded-md h-12"
                    type="name"
                    placeholder="Name"
                  />
                  <input
                    className="rounded-md h-12"
                    type="PhoneNumber"
                    placeholder="PhoneNumber"
                  />
                  <input
                    className="rounded-md h-12"
                    type="Email"
                    placeholder="Email"
                  />
                  <input
                    className="rounded-md h-12"
                    type="text"
                    placeholder="website links"
                  />
                </div>
                <button
                  className="dez-page  site-button primary"
                  style={{ marginTop: "20px" }}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <section class="wcu-section section-gap">
            <div class="container-xxl">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6  col-md-10">
                  <div
                    class=" wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="400ms"
                  >
                    <div class="video-poster-one bg-img-c">
                      <img
                        className="w-full"
                        src="https://cdn-bledd.nitrocdn.com/sMegMNkwPgLIyVZIoLggYdSAqxrSUlEd/assets/static/optimized/rev-be87ab5/wp-content/uploads/2021/09/seo-marketing.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-10">
                  <div class="wcu-text-two">
                    <div class="section-title left-border mb-40">
                      <h2 class="title">
                        Why are SEO Marketing Services Essential to Boost
                        Business Growth?
                      </h2>
                    </div>
                    <p style={{ fontSize: "20px" }}>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Obcaecati error magni molestias deserunt nobis eveniet
                      accusantium quod ipsa illum quibusdam repudiandae autem
                      voluptatum, consectetur dicta suscipit, quam modi cum
                      mollitia! Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Officiis a odit aliquam sapiente quasi
                      iste inventore, delectus reprehenderit! Sapiente nam, ut
                      voluptates magni rerum perspiciatis nostrum soluta facilis
                      animi autem vitae odit maxime dolorum dignissimos dolor
                      impedit necessitatibus aliquam. Ab facere ducimus veniam,
                      deserunt minus ipsum! Molestiae ipsum ex mollitia! Lorem
                      ipsum, dolor sit amet consectetur adipisicing elit.
                      Voluptatum nam, non quae excepturi, eos temporibus fugit
                      accusantium error doloremque neque cupiditate facere
                      delectus praesentium exercitationem. Iste, fugit accusamus
                      maxime debitis omnis placeat sapiente. Quam reprehenderit,
                      corporis et perferendis cumque tempore repellendus ratione
                      amet repellat cupiditate. Iste nostrum tempora iusto
                      saepe.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Seoservice */}
        <Seoservice />
        {/* Seoservice */}
        <div className="mt-10">
          <div className="m-2 md:m-0">
            <h2 className="text-center">How can SEO Help Your Business?</h2>
            <p className="py-6 text-center">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, qui
              labore? Accusantium voluptatum soluta distinctio?
            </p>

            <section class="max-w-5xl mx-auto py-10">
              <div>
                <div class="flex group flex-row">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 duration-300 group-hover:bg-[#FF3300]  py-5 border border-gray-300 rounded  mr-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-6xl font-black text-gray-500 group-hover:text-white duration-300">
                        <TbTarget />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 1
                      </div>
                    </div>
                    <div class="h-full border-l-4 border-transparent">
                      <div class="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex  md:flex-row flex-col items-center">
                      <div class="flex-auto ">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 1</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Setting Goals
                        </div>
                        <div class="px-3 pb-6">
                          As one of the pioneers of SEO services, we make it a
                          point to make our client’s goals our goals. We have a
                          meticulous discussion with our clients to understand
                          the crux of their functionings and goals and match up
                          with their requirements.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 1"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row">
                  <div class="border-t-4 border-r-4 border-transparent">
                    <div class="w-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-b-4 rounded-bl-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-t-4 rounded-tr-full"></div>
                </div>
                <div class="flex group flex-row-reverse">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded ml-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <BsClockHistory />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 2
                      </div>
                    </div>
                    <div class="h-full border-r-4 border-transparent">
                      <div class="border-l-4 ml-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 2</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Creating a Long Terms Plans
                        </div>
                        <div class="px-3 pb-6">
                          Once we have conclusively established the goals, the
                          next step is to create strategies for your business by
                          charting out a plan to achieve all the objectives and
                          eventually, the goal of your company for a streamlined
                          journey.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 2"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row-reverse">
                  <div class="border-t-4 border-l-4 border-transparent">
                    <div class="w-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-b-4 rounded-br-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-t-4 rounded-tl-full"></div>
                </div>
                <div class="flex group flex-row">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded mr-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <FaRunning />
                      </div>
                      <div class="text-gray-500  group-hover:text-white text-sm">
                        Step 3
                      </div>
                    </div>
                    <div class="h-full border-l-4 border-transparent">
                      <div class="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 3</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Getting ahead of the Competition
                        </div>
                        <div class="px-3 pb-6">
                          Our SEO services are centred around one objective –
                          helping you surpass your competitors. We conduct a
                          thorough competitor analysis to understand how they
                          are generating traffic, to help you surpass their
                          results with ease.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 3"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row">
                  <div class="border-t-4 border-r-4 border-transparent">
                    <div class="w-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-b-4 rounded-bl-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-t-4 rounded-tr-full"></div>
                </div>
                <div class="flex group flex-row-reverse">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded ml-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <HiOutlineClipboardList />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 4
                      </div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 4</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Conducting Thorough SEO Audits
                        </div>
                        <div class="px-3 pb-6">
                          The first and foremost thing that an SEO company
                          should do is conduct thorough audits to analyze the
                          websites and chart a plan to work on. Infidigit
                          conducts a thorough SEO Audit with a 270+ audit
                          pointer checklist and technical SEO audit pointers to
                          conclusively tell you the areas of improvement on your
                          websites.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 4"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row-reverse">
                  <div class="border-t-4 border-l-4 border-transparent">
                    <div class="w-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-b-4 rounded-br-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-t-4 rounded-tl-full"></div>
                </div>
                <div class="flex group flex-row">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded mr-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <MdOutlineComputer />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 5
                      </div>
                    </div>
                    <div class="h-full border-l-4 border-transparent">
                      <div class="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 3</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          On-Page Activities
                        </div>
                        <div class="px-3 pb-6">
                          We take our digital marketing services seriously and
                          provide a multitude of on-page activities to keep your
                          webpages healthy and organically ranked in search
                          engines. Whether it is content optimization, internal
                          linking, URL optimization, meta descriptions, or
                          content creation, we make sure that your webpages are
                          always on the top of their game.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 3"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row">
                  <div class="border-t-4 border-r-4 border-transparent">
                    <div class="w-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-b-4 rounded-bl-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-t-4 rounded-tr-full"></div>
                </div>
                <div class="flex group flex-row-reverse">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded ml-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <HiOutlineDesktopComputer />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 6
                      </div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 4</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Off-Page Activities
                        </div>
                        <div class="px-3 pb-6">
                          Apart from taking care of on-page activities, we also
                          ensure that your brand visibility is increased with
                          elaborate influencer outreach activities.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 4"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="flex items-start flex-row-reverse">
                  <div class="border-t-4 border-l-4 border-transparent">
                    <div class="w-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-b-4 rounded-br-full"></div>
                  </div>
                  <div class="border-t-4 border-transparent flex-auto">
                    <div class="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div class="w-16 mt-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-t-4 rounded-tl-full"></div>
                </div>
                <div class="flex group flex-row">
                  <div class="hidden md:flex flex-col items-center">
                    <div class="w-32 group-hover:bg-[#FF3300] py-5 border border-gray-300 rounded mr-4 uppercase flex flex-col items-center justify-center">
                      <div class="text-5xl group-hover:text-white font-black text-gray-500">
                        <BsArrowRepeat />
                      </div>
                      <div class="text-gray-500 group-hover:text-white text-sm">
                        Step 7
                      </div>
                    </div>
                    <div class="h-full border-l-4 border-transparent">
                      <div class="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div class="flex-auto group-hover:shadow-2xl border rounded  border-gray-300">
                    <div class="flex md:flex-row flex-col items-center">
                      <div class="flex-auto">
                        <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500">
                          <span class="font-black">Step 3</span>
                        </div>
                        <div class="p-3 text-3xl text-gray-800 font">
                          Regular Performance Tracking
                        </div>
                        <div class="px-3 pb-6">
                          Our SEO services involve the use of established
                          industry tools like Google Analytics, Webmaster,
                          SEMRush, Ahref, etc. The use of these tools ensures
                          that all your webpages’ performance is monitored daily
                          and kept at their best health to maintain a constant
                          flow of organic traffic. e.
                        </div>
                      </div>
                      {/* <div class="md:w-96 w-full p-5">
                        <img
                          src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
                          alt="step 3"
                          class="object-scale-down"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* HOW CAN INFIDIGIT’S SEO SERVICES HELP YOU? */}
        <section className="container-xxl my-20 mx-auto">
          <h2 className="text-center pb-16">
            HOW CAN Gobeens Technology SEO SERVICES HELP YOU?
          </h2>
          <div className="grid md:grid-cols-4 gap-20 md:gap-4">
            {SeoHelp.map((e) => {
              return (
                <div className="relative hover:scale-105 duration-300 shadow-md border rounded-md p-3 flex flex-col items-center">
                  <img className="absolute -top-16 w-40" src={e.img} alt="" />
                  <div className="pt-20">
                    <h2 className="text-center text-xl tracking-wide">
                      {e.title}
                    </h2>
                    <p className="text-black text-sm text-center">{e.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        {/* FAQ */}
        <section className="container my-32 mx-auto">
          <h2 className="text-center">FAQ'S ON SEO SERVICES</h2>
          <div className="mt-6">
            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                How much does a new website cost?
              </summary>
              <p class="px-4 py-3">
                The cost of a new website depends on various factors like the
                kind of features and functionality required in it, number of
                pages, complexity of the website, etc. When our experts are able
                to gain a thorough understanding of your specific website
                building needs, only then they can provide you with a realistic
                estimate of your website’s pricing.
              </p>
            </details>

            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                Do you build WordPress websites?
              </summary>
              <p class="px-4 py-3">
                Yes we create WordPress websites. As WordPress is flexible and
                supportive in creating any type of website without any
                restrictions, it is increasingly used today for business website
                building. More than 30% of websites being powered by WordPress
                shows its dominant position in the website development realm.
                Apart from WordPress, we use other content management systems
                like Joomla, Drupal, Wix, Weebly and Sitecore for building
                business websites.
              </p>
            </details>

            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                Will you also maintain my website?
              </summary>
              <p class="px-4 py-3">
                Our experts will not only build your website, but will also take
                care of its maintenance to ensure it continues to function in a
                smooth, glitch-free and secure manner. Regular website
                maintenance performed by them will help your business function
                normally and retain customers’ trust in your business brand.
              </p>
            </details>
            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                Will my website be mobile friendly?
              </summary>
              <p class="px-4 duration-500 py-3">
                Yes, the website we will develop for you will open and look
                perfect on a variety of mobile phones and other devices like
                tablets, laptops and desktops. The responsive website will
                provide your users an appealing viewing experience as well as an
                optimum browsing experience on their respective devices.
              </p>
            </details>
          </div>
        </section>
        {/* faq */}
        <div
          className="section-full overlay-primary-dark bg-img-fix"
          style={{ backgroundImage: "url(" + bgimg3 + ")" }}
        >
          <FormStyle />
        </div>
        {/* footer */}
        <div className="">
          <Footer />
        </div>
        {/* footer */}
      </div>
    </>
  );
};
const SERVE = [
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
];
const SERVICE = [
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
  {
    img: "https://static.vecteezy.com/system/resources/thumbnails/005/307/780/small/seo-targeting-icon-free-vector.jpg",
    title: " Lorem ipsum dolor sit amet.",
    decs: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa ea iusto vero reprehenderit doloremque eaque sunt similique, deserunt voluptatum vel?",
  },
];
const Stat = [
  {
    icon: <MdOutlineSpaceDashboard />,
    title: "Organic Sessions",
    number: "1.8 Billion+",
  },
  {
    icon: <GiGrowth />,
    title: "#1 Ranking Keywords",
    number: "2000+",
  },
  {
    icon: <BsAward />,
    title: "Award & Recognization",
    number: "20+",
  },
  {
    icon: <MdOutlineAdsClick />,
    title: "Clicks",
    number: "2k+",
  },
  {
    icon: <FiUserPlus />,
    title: "Users",
    number: "20k+",
  },
  {
    icon: <MdOutlineFilterAlt />,
    title: "Leads",
    number: "200k+",
  },
];

const SeoHelp = [
  {
    img: first,
    title: "Increase in Revenue",
    desc: "By increasing organic traffic on your website, we make sure that your revenue sees a significant bump via organic channels.",
  },
  {
    img: second,
    title: "Brand Visibility",
    desc: "We help your business in creating a positive brand image through optimum search engine ranking and increase your brand’s online recognition and presence.",
  },
  {
    img: third,
    title: "More Sales",
    desc: "Increase in search engine rankings means more prospects visiting your website. This means you get the opportunity to convert more leads which would increase your sales significantly.",
  },
  {
    img: fourth,
    title: "Faster Growth",
    desc: "With all the aforementioned factors, your business or brand can expect to grow much faster and make a bigger impact on the industry.",
  },
];

export default Searchengin;
