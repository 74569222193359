import React, { Component, Fragment } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import "./Portfolio.css";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";

const PortfolioDetails = () => {
  const location = useLocation();
  const data = location.state;
  return (
    <Fragment>
      <Header />
      <div className="page-content bg-white">
        {/*  banner  */}
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{
            backgroundImage:
              "url('https://webtend.net/demo/html/finsa/assets/img/sidebar/cta.jpg')",
          }}
        >
          <PageTitle
            motherMenu="Portfolio Details"
            activeMenu="Portfolio Details"
          />
        </div>
        <section class="portfolio-area portfolio-details section-gap">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7">
                <div class="portfolio-details-gallery row">
                  <div class="col-12">
                    <div class="image">
                      <img src={data.pic} alt="Portfolio" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-md-10">
                <StickyBox
                  // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                  offsetTop={100}
                  offsetBottom={100}
                >
                  <div class="portfolio-desc">
                    <h3 class="title">{data.title}</h3>
                    <p>{data.descrepation}</p>
                    <div class="portfolio-info">
                      <h6>Projects Info</h6>
                      <ul>
                        <li>
                          <span>Category: &nbsp;</span>
                          <a href="/#">{data.catagroy}</a>
                        </li>
                        <li>
                          <span>Date:</span> June 21, 2021
                        </li>
                        <li>
                          <span>Tags: &nbsp;</span>
                          {data.tecnology.map((e) => {
                            return <a href="/#">{e}</a>;
                          })}
                        </li>
                      </ul>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="site-button primary mt-30"
                        href={data.route}
                      >
                        Live Preview
                      </a>
                      <span class="icon">
                        <i class="flaticon-files"></i>
                      </span>
                    </div>
                  </div>
                </StickyBox>
              </div>
            </div>
            {/* <div class="related-portfolio">
              <h3 class="related-portfolio-title">Related projects</h3>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                  <div class="portfolio-box-two mt-30">
                    <div
                      class="thumb"
                      style={{
                        backgroundImage:
                          "url('https://pixia.vercel.app/img/portfolio/05.jpg')",
                      }}
                    >
                      <a class="link" href="portfolio-details">
                        &rarr;
                      </a>
                    </div>
                    <div class="content">
                      <div class="tags">
                        <a href="/#">Design,</a>
                        <a href="/#">Development</a>
                      </div>
                      <h4 class="title">
                        <a href="portfolio-details">Kikado Mobile App Design</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="portfolio-box-two mt-30">
                    <div
                      class="thumb"
                      style={{
                        backgroundImage:
                          "url('https://pixia.vercel.app/img/portfolio/07.jpg')",
                      }}
                    >
                      <a class="link" href="portfolio-details">
                        &rarr;
                      </a>
                    </div>
                    <div class="content">
                      <div class="tags">
                        <a href="/#">Design,</a>
                        <a href="/#">Development</a>
                      </div>
                      <h4 class="title">
                        <a href="portfolio-details">Kikado Mobile App Design</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="portfolio-box-two mt-30">
                    <div
                      class="thumb"
                      style={{
                        backgroundImage:
                          "url('https://pixia.vercel.app/img/portfolio/06.jpg')",
                      }}
                    >
                      <a class="link" href="portfolio-details">
                        &rarr;
                      </a>
                    </div>
                    <div class="content">
                      <div class="tags">
                        <a href="/#">Design,</a>
                        <a href="/#">Development</a>
                      </div>
                      <h4 class="title">
                        <a href="portfolio-details">Kikado Mobile App Design</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default PortfolioDetails;
