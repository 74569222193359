import Header from "../../Layout/Header";
import Footer from "./../../Layout/Footer";
import PageTitle from "./../../Layout/PageTitle";
// import SectionCounter from "./../../Element/SectionCounter3";
import TestimonialCarousel from "./../../Element/TestimonialCarousel1";
import FormStyle from "./../../Element/FormStyle";
import bnr5 from "./../../../images/banner/bnr5.jpg";
import bg1 from "./../../../images/background/bg1.jpg";
import SectionCounterNew from "../../Element/SectionCounterNew";
import { Link } from "react-router-dom";
import pic1 from "./../../../images/service/Web.jpg";
import pic2 from "./../../../images/service/E.jpg";
import pic3 from "./../../../images/service/Mobile.jpg";
import pic4 from "./../../../images/service/Digital.jpg";
import pic5 from "./../../../images/service/App.jpg";
import pic6 from "./../../../images/service/Outsourcing.jpg";
import pic7 from "./../../../images/service/SEO.jpg";
import pic8 from "./../../../images/service/SMM.jpg";
import React , { Component }  from "react";

const iconBox = [
  {
    img: pic1,
    title: "Web Development",
    route: "web-development",
    desc: "Designs crafted and built by experienced professionals with customer needs in mind create and maintain websites",
  },
  {
    img: pic2,
    title: "E-Commerce Web Design",
    route: "/e-commerce",
    desc: "Our Custom App development provides users with unique SaaS experience that integrates with AI and Virtual Reality",
  },
  {
    img: pic3,
    title: "Mobile App Development",
    route: "/mobile-app-development",
    desc: "Our mobile app development provides mobile e-commerce solutions, mobile wallet solutions that are W3C compliant",
  },
  {
    img: pic4,
    title: "Digital Marketing ",
    route: "/digital-marketing",
    desc: "Scale up your business with our comprehensive e-commerce development solutions and give your customers a stellar shopping experience",
  },
  {
    img: pic5,
    title: "Custom App Development",
    route: "/custom-app-development",
    desc: "We offer result driven and highly professional Digital Marketing services to help you develop a robust online marketing strategy",
  },
  {
    img: pic6,
    title: "Outsourcing Services",
    route: "/out-sourcing",
    desc: "Performance optimization techniques to increase traffic and converting it to leads and sales by making your business a brand",
  },
  {
    img: pic7,
    title: "Search Engine optimization",
    route: "/seo",
    desc: " SEO services are search engine optimization services intended to increase visibility and ultimately organic search traffic to websites.",
  },
  {
    img: pic8,
    title: "Social Media Marketings",
    route: "/smm",
    desc: "Social media marketing (SMM) services assist businesses with the promotion of their product or brand on social media platforms.",
  },
];

class Service extends Component {
  render() {
    return (
    <>
      <Header />

      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <PageTitle motherMenu="Our Services" activeMenu="Our Services" />
        </div>
        {/* <!-- inner page banner END --> */}
        <div className="content-block">
          {/* <!-- About Us --> */}
          <div className="section-full content-inner">
            <div className="">
              <div className="text-black text-center ">
                <div className="section-title">
                  <br />
                  <h7 className="title m-tb0 ">
                    We Provide Most Exclusive
                    <br />
                    Service For Business
                    <span className="bg-primary "></span>
                  </h7>
                </div>
              </div>
            </div>
            <br />
            <div className="">
              <div className="container-xxl grid grid-cols-4 gap-4 mx-auto">
                {iconBox.map((data, index) => (
                  <Link
                    to={data.route}
                    className="m-b30 shadow-2xl hover:shadow-md border "
                    key={index}
                  >
                    <div className="cards zoom">
                      <div
                        style={{
                          // paddingTop: 20,
                          // paddingBottom: 20,
                          // paddingLeft: 40,
                          // paddingRight: 40,
                          height: "380px",
                        }}
                        className=""
                      >
                        <div
                          className="card1 card1__image"
                          style={{backgroundImage: `url(${data.img})`, height:"380px",}}
                        >
                      <div class="card1__overlay">
                          <div class="card1__header">
                            <svg
                              class="card1__arc"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path />
                            </svg>
                           
                            <div class="card1__header-text">
                              <h3 class="card1__title">{data.title}</h3>
                              
                            </div>
                          </div>
                          <p class="card1__description text-center">
                            {data.desc}
                          </p>
                        </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                
                ))}
              </div>
            </div>
          </div>
          {/* <!-- Our Services --> */}
          {/* Section COunter New */}
          <SectionCounterNew />
          {/* <!-- Why Chose Us --> */}
          {/* <SectionCounter /> */}

          <div className="container">
            <div className="section-content m-b30 ">
              <TestimonialCarousel />
            </div>
          </div>

          {/* <!-- Testimonials END --> */}
          {/* <!-- Get in touch --> */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          {/* <!-- Get in touch --> */}
        </div>
        {/* <!-- contact area END --> */}
      </div>
      
      <div className="">
      <Footer />
      </div>
    </>
  );
};
};
export default Service;
