import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "./../../Layout/Footer";
import PageTitle from "./../../Layout/PageTitle";
import "./Career.css";
// import { BiFingerprint } from "react-icons/bi";
import { AiFillAlipayCircle, AiOutlineSolution } from "react-icons/ai";
import { MdOutlineManageAccounts, MdComputer } from "react-icons/md";

import bnr1 from "./../../../images/banner/bnr4.jpg";
import { Link } from "react-router-dom";

class ServicesDetails extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Career" activeMenu="Career" />
          </div>
          {/* <!--====== Breadcrumb part End ======--> */}

          {/* <!--====== Job Category Start======--> */}
          <section class="job-category-section section-gap">
            <div class="container-xxl ">
              <div class="job-categories">
                <div
                  class="single-cat flex flex-col items-center fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <Link to="/jobs-details">
                    <AiOutlineSolution className="icon-size" />
                    <span>Web Development</span>
                  </Link>
                </div>
                <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <Link to="/jobs-details">
                    <MdComputer className="icon-size" />
                    <span>
                      Content <br /> Writing
                    </span>
                  </Link>
                </div>
                <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="600ms"
                >
                  <Link to="/jobs-details">
                    <AiFillAlipayCircle className="icon-size" />
                    <span>Business Development</span>
                  </Link>
                </div>
                {/* <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <a href="/#">
                    <BiFingerprint className="icon-size" />
                    <span>Digital Marketing</span>
                  </a>
                </div> */}
                <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="800ms"
                >
                  <Link to="/jobs-details">
                    <MdOutlineManageAccounts className="icon-size" />
                    <span>App development</span>
                  </Link>
                </div>
                {/* <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="900ms"
                >
                  <a href="/#">
                    <MdGraphicEq className="icon-size" />
                    <span>UX/UI Strategy</span>
                  </a>
                </div>
                <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="1s"
                >
                  <a href="/#">
                    <SiMarketo className="icon-size" />
                    <span>IT Marketing</span>
                  </a>
                </div>
                <div
                  class="single-cat wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="1.1s"
                >
                  <a href="/#">
                    <BsPiggyBank className="icon-size" />
                    <span>Bank & Finance</span>
                  </a>
                </div> */}
              </div>
            </div>
          </section>
          {/* <!--====== Job Category End======-->

<!--====== Job List Start======--> */}
          <section class="job-list-section section-gap grey-bg">
            <div class="container-xxl">
              <div class="job-lists">
                {/* Single JOb Card */}
                <div class="single-job">
                  <h4 class="title">
                    Web Designer <span class="job-time">(Full Time)</span>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium doloremque laudantium totam rem
                    aperiam
                  </p>
                  <Link to="/jobs-details" class="site-button mt-4">
                    Apply now <i class="fa fa-long-arrow-right"></i>
                  </Link>
                </div>
                <div class="single-job">
                  <h4 class="title">
                    Web Designer <span class="job-time">(Full Time)</span>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium doloremque laudantium totam rem
                    aperiam
                  </p>
                  <Link to="/jobs-details" class="site-button mt-4">
                    Apply now <i class="fa fa-long-arrow-right"></i>
                  </Link>
                </div>
                <div class="single-job">
                  <h4 class="title">
                    Web Designer <span class="job-time">(Full Time)</span>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium doloremque laudantium totam rem
                    aperiam
                  </p>
                  <Link to="/jobs-details" class="site-button mt-4">
                    Apply now <i class="fa fa-long-arrow-right"></i>
                  </Link>
                </div>
                <div class="single-job">
                  <h4 class="title">
                    Web Designer <span class="job-time">(Full Time)</span>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusa ntium doloremque laudantium totam rem
                    aperiam
                  </p>
                  <Link to="/jobs-details" class="site-button mt-4">
                    Apply now <i class="fa fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div class="view-btn text-center mt-60">
                <Link to="/jobs-details" class="site-button">
                  View All Jobs
                </Link>
              </div>
            </div>
          </section>
          {/* <!--====== Job List End======-->

<!--====== Client Area Start ======--> */}

          {/* <section className="bot">
            <div className="container ">
              <Slider {...settings}>
                <div className="simple">
                  <div className="gap">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/matador.png"
                    alt=""
                  />
                  </div>
                </div>
                <div>
                  <img
                    className="simple"
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/pioneer.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/03/Sage.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/09/png-1.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/03/Robo_Managers-1.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/03/Zconsulting.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/03/travel-ground.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/03/Zaarly.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/04/ta-techies-logo.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/04/TA-Academies.png"
                    alt=""
                  />
                </div>

                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/09/Thurman-Brown-Consultant_logo_png.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/09/475x255.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/07/Monday-Media-Marketing-logo.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/10/Logo-Transparency-Ready-to-use-in-PNG-HD-1.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2020/08/Horizontal-Gold_Transparent2-1536x290-1.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/ACM.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/bello.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/Modernize_Consulting.png"
                    alt=""
                  />
                </div>
                <div className="simple">
                  <img
                    src="https://www.Gobeensglobal.com/wp-content/uploads/2021/02/ELITE_INTERPRETATION_LOGO_1_TRANSPARENT-1024x461-1.png"
                    alt=""
                  />
                </div>
              </Slider>
            </div>
          </section> */}
          {/* <!--====== Client Area End ======-->

<!--====== Footer Part Start ======--> */}
          <Footer />
        </div>
      </>
    );
  }
}
export default ServicesDetails;
