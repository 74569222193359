import React from "react";
import { Link } from "react-router-dom";
import Counter from "./Counter";
import bg1 from "./../../images/background/bg1.jpg";

const SectionCounterNew = () => {
  const iconBlog = [
    {
      icon: <i className="flaticon-pen" />,
      title: "WHY Gobeens",
      desc: "At Gobeens we love to code and we put it to good use by creating innovative, cool and functional applications and websites.",
    },
    {
      icon: <i className="flaticon-diamond" />,
      title: "CORE VALUES",
      desc: "As the name suggests, we believe in keeping our word and from that comes our Punch Line 'We Deliver What We Promise'.",
    },
    {
      icon: <i className="flaticon-devices" />,
      title: "Quality Assured",
      desc: "At Gobeens we love to code and we put it to good use by creating innovative, cool and functional applications and websites.",
    },
  ];
  return (
    <>
      <div
        className="section-full content-inner-1 overlay-primary about-service bg-img-fix"
        style={{ backgroundImage: "url(" + bg1 + ")" }}
      >
        <div className="container">
          <div className="section-head text-white text-center">
            <h2 className="box-title m-tb0 max-w800 m-auto">
              Amazing things happen to your business when we connect those dots
              of utility and value<span className="bg-primary"></span>
            </h2>
            <p>
              The word ‘Gobeens’ means ‘Promise’. Gobeens Technology is a CANDID IT
              Consulting / Digital Marketing company with a host of services to
              offer under one umbrella. Founded in the year 2019 by a couple of
              IT enthusiasts who believe in making something big.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row text-white">
            {iconBlog.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
                <div
                  style={{ padding: 10 }}
                  className="icon-bx-wraper bx-style-1 p-a30 center"
                >
                  <div className="icon-lg text-white m-b20">
                    {" "}
                    <Link to={"#"} className="icon-cell text-white">
                      {item.icon}
                    </Link>{" "}
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte text-uppercase">{item.title}</h5>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="choses-info text-white">
          <Counter />
        </div>
      </div>
    </>
  );
};

export default SectionCounterNew;
