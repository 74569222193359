import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ThemeButton extends Component {
  render() {
    return (
      <>
        <a
          href="https://api.whatsapp.com/send/?phone=9988772199&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
          className="bt-buy-now d-flex justify-content-center align-items-center theme-btn"
        >
          <img
            src="https://img.icons8.com/material-outlined/24/FFFFFF/whatsapp--v1.png"
            alt=""
          />
          <span>Whatsapp</span>
        </a>
        <Link to="/contact" className="bt-support-now theme-btn">
          <i className="ti-headphone-alt"></i>
          <span>Contact us</span>
        </Link>
      </>
    );
  }
}
