import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

//Images
import pic1 from "./../../images/project/portfolio1.png";
import pic2 from "./../../images/project/portfolio2.png";
import pic3 from "./../../images/project/portfolio3.png";
import pic4 from "./../../images/project/portfolio4.png";
import pic5 from "./../../images/project/portfolio5.png";
import pic6 from "./../../images/project/portfolio6.png";
import pic7 from "./../../images/project/portfolio7.png";
import pic8 from "./../../images/project/portfolio8.png";
import pic9 from "./../../images/project/portfolio9.png";
import pic10 from "./../../images/project/portfolio10.jpg";
import pic11 from "./../../images/project/portfolio11.png";
import pic12 from "./../../images/project/portfolio12.png";
import pic13 from "./../../images/project/portfolio13.png";
import pic14 from "./../../images/project/portfolio14.png";
import pic15 from "./../../images/project/portfolio15.png";
import pic16 from "./../../images/project/portfolio16.png";
import pic17 from "./../../images/project/portfolio17.png";
import pic18 from "./../../images/project/portfolio18.jpg";
import pic19 from "./../../images/project/portfolio19.jpg";
import pic20 from "./../../images/project/portfolio20.jpg";
import pic21 from "./../../images/project/portfolio21.png";

const contentBlog = [
  {
    images: pic1,
    link: "https://acmgeneralconstruction.com/",
    webName : "ACM General Construction"
  },
  {
    images: pic2,
    link: "https://iconycs.com/",
  },
  {
    images: pic3,
    link: "https://wavewelcome.com/",
  },
  {
    images: pic4,
    link: "https://gmcmed.org/",
  },
  {
    images: pic5,
    link: "http://colusamedcenter.com/",
  },
  {
    images: pic6,
    link: "https://greendisco.earth/",
  },
  {
    images: pic7,
    link: "https://www.matadordigi.com/",
  },
  {
    images: pic8,
    link: "https://bellosolar.com/",
  },
  {
    images: pic9,
    link: "https://sonomaspecialtyhospital.org/",
  },
  {
    images: pic10,
    link: "https://thurmanbrownconsultant.com/",
  },
  {
    images: pic11,
    link: "https://praesidiumsecurity.com/",
  },
  {
    images: pic12,
    link: "https://landmarkrr.com/",
  },
  {
    images: pic13,
    link: "https://qwwws.com/",
  },
  {
    images: pic14,
    link: "https://pioneerdr.com/",
  },
  {
    images: pic15,
    link: "https://thekinshipadvisors.com/",
  },
  {
    images: pic16,
    link: "https://madcityanalytics.com/",
  },
  {
    images: pic17,
    link: "https://www.eliteinterpretation.com/",
  },
  {
    images: pic18,
    link: "http://dounto.co/",
  },
  {
    images: pic19,
    link: "https://tokyotechies.com/",
  },
  {
    images: pic20,
    link: "https://www.zaarly.com/",
  },
  {
    images: pic21,
    link: "https://getnugg.com/",
  },
];
//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="mfp-link"
      title="Title Come Here"
    >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  );
};

class PortfolioCarousel extends Component {
  render() {
    var settings = {
      arrows: false,
      slidesToShow: 4,
      speed: 3000,
      navSpeed: 3000,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <SimpleReactLightbox>
          <SRLWrapper>
            <Slider
              className="img-carousel-content owl-carousel text-center text-white owl-none "
              {...settings}
            >
              {contentBlog.map((item, index) => (
                <div className="item p-3" key={index}>
                  <div className="dlab-box portfolio-box">
                    <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                      {" "}
                      <div style={{ height: 500, overflow: "hidden" }}>
                        <img src={item.images} alt="" />
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="dlab-info-has p-a15 back-color-port"
                      >
                        {/* <Link
                          to={"#"}
                          className="site-button outline radius-xl white pull-left"
                        >
                          Wordpress
                        </Link> */}
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "white" }}
                          className="site-button outline radius-xl white pull-right"
                        >
                          Visit Site
                        </a>
                      </div>
                      <div className="overlay-bx">
                        <div className="overlay-icon text-white">
                          <h5>{item?.webName}</h5>
                          <p className="m-b10">
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots
                          </p>
                          {/* <a href={item.images} className="mfp-link" title="Title Come Here"> <i className="ti-fullscreen icon-bx-xs"></i> </a> */}
                          {/* <Iconimage /> */}
                          <a rel="noreferrer" target="_blank" href={item.link}>
                            <i className="ti-arrow-top-right icon-bx-xs"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </SRLWrapper>
        </SimpleReactLightbox>
      </>
    );
  }
}

export default PortfolioCarousel;
