import React from "react";
import { GrFormAdd } from "react-icons/gr";
import { Link, NavLink } from "react-router-dom";
import img from "./../../../images/banner/bnr13.jpg";
// import StickyBox from "react-sticky-box";
const Sidebar = () => {
  return (
    <>
      <div class="sidebar">
        <div class="widget cat-widget">
          <h4 class="widget-title">All Service List</h4>

          <ul>
            <li>
              <NavLink
                to={"web-development"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Web Development
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"e-commerce"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "#ff3300",
                }}
              >
                E-commerce Web Design
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"mobile-app-development"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Mobile App Development
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"digital-marketing"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Digital Marketing Development
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"custom-app-development"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Custom App Development
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"out-sourcing"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Outsourcing service
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"seo"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Search Engine optimization 
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"smm"}
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
               Social Media Marketing
                <span class="">
                  <GrFormAdd />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div class="wid contact-wid">
          <h4 class="widget-title">Conatct Us</h4>
          <form action="#">
            <input type="text" placeholder="Your Email" />
            <textarea placeholder="Message"></textarea>
            <button type="submit" class="site-button">
              Send Message
            </button>
          </form>
        </div>
        {/* <!-- CTA Widget --> */}
        <div
          class=" widget cta-widget"
          style={{ backgroundImage: `url(${img})`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}
        >
          <h4 class="title">Need Any Consultations</h4>
          <Link to={"/"} class="site-button">
            Send Message
          </Link>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
