import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          {/* Home */}
          <li className="">
            <Link to={"/"}>Home</Link>
          </li>
          {/* Our Service */}
          <li>
            <Link to={"/services"}>Our Services</Link>
          </li>
          {/* Blog */}
          <li>
            <Link to={"/blog"}> Blog </Link> 
          </li>
          <li>
            <Link to={"/portfolio"}>Our Portfolio </Link>
          </li>
          <li>
            <Link to={"/career"}>Our Careers </Link>
          </li>
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
