import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Slider1 from "./../../images/main-slider/Home_1.jpg";
import Slider2 from "./../../images/main-slider/Home_2.jpg";
import Slider3 from "./../../images/main-slider/Home_3.jpg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

class HomeOwlSlider extends Component {
  render() {
    var settings = {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 10000,
      speed:4000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <Slider
        className="owl-slider owl-carousel owl-theme owl-none"
        {...settings}
      >
        <div className="item slide-item">
          <div className="slide-item-img">
            <img src={Slider1} className="w-100" alt="" />
          </div>
          <div className="slide-content overlay-primary">
            <div className="slide-content-box container-xxl z-50">
              <div className="max-w600 text-white">
                <h2 className="text-white font-weight-400">
                  We provide innovative and customized website development.
                 
                </h2>
                <p>
                  We are a group of developers passionate about building
                  futuristic, advanced and robust website development solutions
                  that enhance the brand development efforts of businesses. 
                </p>
                <Link
                  to="/contact"
                  className="get site-button m-r10 white button-lg"
                >
                  Get Started
                </Link>
                <Link
                  to="/aboutus"
                  className="how site-button outline outline-2 button-lg"
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="item slide-item ">
          <div className="slide-item-img  ">
            <img src={Slider3} className="w-100" alt="" />
          </div>
          <div className="slide-content overlay-primary">
            <div className="slide-content-box container-xxl z-50">
              <div className="max-w600 text-white">
                <h2 className="text-white font-weight-400">
                  We would great ideas into
                  <br /> prodigious and innovative products. <br />
                </h2>
                <p>
                  We are passionate of deep understanding of management and
                  communication and how to interact with the digital world is
                  the true value we provide.
                </p>
                <Link
                  to="/contact"
                  className="get site-button m-r10 white button-lg"
                >
                  Get Started
                </Link>
                <Link
                  to="/aboutus"
                  className="how site-button outline outline-2 button-lg"
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="item slide-item ">
          <div className="slide-item-img">
            <img src={Slider2} className="w-100" alt="" />
          </div>
          <div className="slide-content overlay-primary">
            <div className="slide-content-box container-xxl z-50">
              <div className="max-w600 text-white">
                <h2 className="text-white font-weight-400">
                  Move with passion, perform best work
                  <br /> and be pride, whatever you do.{" "}
                </h2>
                <p>
                  A forward thinking representation for a research production
                  and the ability to do it dynamically with broad stability and
                  drive your business up to a higher level.
                </p>
                <Link
                  to="/contact"
                  className="get site-button m-r10 white button-lg"
                >
                  Get Started
                </Link>
                <Link
                  to="/aboutus"
                  className="how site-button outline outline-2 button-lg"
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default HomeOwlSlider;
