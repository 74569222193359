import React, { Fragment, Component } from "react";
import CountUp from "react-countup";

const counterBlog = [
    {
      icon: <i className="ti-bag m-r10" />,
      num: "3",
      title: "Years in Business",
    },
    { icon: <i className="ti-user m-r8" />, 
    num: "700", 
    title: "Happy Clients" },
    {
      icon: <i className="flaticon-users m-r10" />,
      num: "50",
      title: "Technical Experts",
    },
    {
      icon: <i className="ti-mobile m-r10" />,
      num: "200",
      title: "Project Delivered",
    },
  ];

class Counter extends Component {
  render() {
    return (
      <Fragment>
        <div className="container-fluid ">
          <div className="row">
        
            {counterBlog.map((data, index) => (
              <div className="col-sm-3 col-md-3">
              <div class="shadow-lg p-3  mb-5 bg-white rounded card-title">
                <div class="card-body card ">
                  <h2 class=" justify-content-center">
                    {data.icon}
                    <span className="counter card-text ">
                      <CountUp end={data.num} duration={4} />
                    </span>
                    +
                  </h2>
                  <h4 className="font-weight-300 m-t0 ">{data.title}</h4>
                </div>
              </div>
              </div>
            ))}
        
        </div>
        </div>
      </Fragment>
    );
  }
}
export default Counter;
