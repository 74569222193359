import React, {Fragment } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import "./Portfolio.css";
import { Link,} from "react-router-dom";
import bnr1 from "./../../../images/banner/bnr12.jpg";
import Client from "../Service/Client"
const PortfolioGrid2 = () => {
  // const navigate = useNavigate();

  return (
    <Fragment>
      <Header />
      <div className="page-content bg-white">
        {/*  banner  */}
        <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
          <PageTitle motherMenu="Portfolio" activeMenu="Portfolio" />
        </div>
        {/*  Section-1 Start  */}
        <section class="portfolio-area ">
          <div class="container-xxl">
            {/* <div class="portfolio-filter mb-10">
                <ul>
                  <li class="active" data-filter="*">
                    All Works
                  </li>
                  <li class="" data-filter=".">
                    
                  </li>
                  <li class="" data-filter=".">
                    
                  </li>
                  <li class="" data-filter=".">
                    UI/UX
                  </li>
                  <li class="" data-filter=".">
                    
                  </li>
                </ul>
              </div> */}
            <div class="portfolio-filter-items row">
              {portfolioData.map((e) => {
                return (
                  <div class={e.class}>
                    <div class="portfolio-box-three mt-30">
                      <div
                        class="thumb"
                        style={{
                          backgroundImage: `url(${e.pic})`,
                          backgroundPositionY: 0,
                        }}
                      ></div>
                      <div class="content">
                        <div class="tags">
                          <p className="text-white">{e.catagroy}</p>
                        </div>
                        <h4 class="title">
                          <Link
                            to={{ pathname: "/portfoliodetails", state: e }}
                          >
                            {e.title}
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
       
        <Client/>
      <Footer />
    </Fragment>
  );
};

const portfolioData = [
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/acm.png",
    catagroy: "Web Design",
    title: "ACM General Construction",
    descrepation:
      "Elite Interpretation mission is to weave humane interactions with respect, dignity and trust by contributing our expertise in interpreting. In this way, diversity is celebrated, valued, more and more normalized, and we all thrive.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://acmgeneralconstruction.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/iconycs.png",
    catagroy: "Web Design",
    title: "Iconycs",
    descrepation:
      "ICONYCS is a real estate and finance technology company dedicated to empowering real estate analysts and financial industry professionals in identifying, analyzing, financing, and marketing of residential real estate socio-economic trends.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://iconycs.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/wavewelcome-.png",
    catagroy: "Web Design",
    title: "Wave Welcome",
    descrepation:
      "Wave Welcome also aims to spotlight the talent of young individuals and focuses on empowering those individuals with digital skills needed for the future.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://wavewelcome.com/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/glennmedical.png",
    catagroy: "Web Design",
    title: "Glenn Medical center",
    descrepation:
      "Glenn Medical Center in Willows, California, is a Critical Access Hospital offering inpatient, outpatient and rural health clinic services. ",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://gmcmed.org/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/colusa.png",
    catagroy: "Web Design",
    title: "COLUSA MEDICAL CENTER",
    descrepation:
      "Colusa Medical Center’s vision is to transform the health care experience through a continuum of care utilizing a culture of caring, quality, safety, service, innovation, and excellence, and be recognized by employees, physicians, patients, volunteers and the community.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://colusamedcenter.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/greendisco.png",
    catagroy: "Web Design",
    title: "Green Disco",
    descrepation:
      "Green Disco provides individuals with easy and measurable ways to have a positive impact on the environment while allowing live event organizers the unique opportunity to become carbon neutral for the first time.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://www.greendisco.earth/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/matadordigi.png",
    catagroy: "Web Design",
    title: "Matador Digi, LLC",
    descrepation:
      "Matador Digi, was built from a dream to create a place that helps entrepreneurs effectively grow their business. ",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://www.matadordigi.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/bellosolar.png",
    catagroy: "Web Design",
    title: "BELLO SOLAR",
    descrepation:
      "Bello Solar works with dealer partners to fulfill homeowners desires to “go solar”.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://bellosolar.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/sonomaspecialtyhospital.png",
    catagroy: "Web Design",
    title: "SONOMA SPECIALITY HOSPITAL",
    descrepation:
      "Sonoma Specialty Hospital, as the county’s only Long-Term Acute Care Hospital, is dedicated to caring for patients that require an extended acute care stay due to severe illness or catastrophic injury. We accept patients from the ICU, Step-Down and Telemetry units of partner hospitals and continue their care for as long as 45 days. ",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://sonomaspecialtyhospital.org/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/09/thurmanbrown-scaled.jpg",
    catagroy: "Web Design",
    title: "THURMAN BROWN CONSULTANT",
    descrepation:
      "Demonstrated expertise in Mental Health and Substance Abuse Treatment, Career Development, Treatment Groups, Workshop Facilitation, Supervision, and Management.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://thurmanbrownconsultant.com/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/screencapture-praesidiumsecurity-2020-11-23-17_32_45.png",
    catagroy: "Web Design",
    title: "PRAESIDIUM SECURITY",
    descrepation:
      "The main focus of Praesidium Security is on providing the best defensive protection to help your establishment in continuing to focus on growth and what you do best, while we take on the burden of guarding and defending your people and your assets. ",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://landmarkrr.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/landmark-roofing.png",
    catagroy: "Web Design",
    title: "Landmark Roofing and Restoration",
    descrepation:
      "We are a 3rd Generation Family-Owned and Operated company, specializing in repairs, renovations, restorations, and reconditioning.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://thurmanbrownconsultant.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/qwwws.png",
    catagroy: "Web Design",
    title: "QUALITY WORLD WIDE WEB SERVICES",
    descrepation:
      "QWWWS creates the highest possible quality s, apps, landing pages, etc. that not only function correctly but rank as high as possible in your respective industries, and are artistic enough to communicate your message well.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://qwwws.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/pioneer.png",
    catagroy: "Web Design",
    title: "PIONEER DIAGNOSTIC & IMAGING",
    descrepation:
      "Pioneer is the largest independent provider of fixed and flexible Imaging services. We provide diagnostic testing for a growing number of healthcare providers. Pioneer offers a complete panel of testing, including OB/GYN, general, vascular, and cardiac ultrasounds along with other Imaging needs.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://pioneerdr.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/thekinshipadvisors.png",
    catagroy: "Web Design",
    title: "The Kinship Advisors",
    descrepation: "The Kinship Advisors evolves people and expands brands. ",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://thekinshipadvisors.com/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2021/02/madcityanalytics.png",
    catagroy: "Web Design",
    title: "MadCity Analytics",
    descrepation:
      "	MadCity Analytics is a distributed data science firm with headquarters in Madison, WI",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://madcityanalytics.com/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/10/screencapture-eliteinterpretation-2020-11-23-16_56_46.png",
    catagroy: "Web Design",
    title: "Elite Interpretation",
    descrepation:
      "Elite Interpretation mission is to weave humane interactions with respect, dignity and trust by contributing our expertise in interpreting. In this way, diversity is celebrated, valued, more and more normalized, and we all thrive.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://www.eliteinterpretation.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/09/dounto-scaled.jpg",
    catagroy: "Web Design",
    title: "Dounto",
    descrepation:
      "Dounto dedicated to growing more conscious and compassionate leaders for a better world.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "http://dounto.co/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/09/tokyotechies-scaled.jpg",
    catagroy: "Web Design",
    title: "Tokyo Techies",
    descrepation:
      "We believe that great quality is the perfect alignment between requirements, process and deliverables which leads to customer satisfaction.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://www.tokyotechies.com/",
    class: "col-xl-3 col-md-6",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/09/zaarly-scaled.jpg",
    catagroy: "Web Design",
    title: " Zaarly",
    descrepation:
      "We have a community of pros ready to level-up your home. Choose the service you need, enter a few details, and get matched with pros who are vetted, qualified, and available.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://www.zaarly.com/",
    class: "col-xl-6 col-md-12",
  },
  {
    pic: "https://www.Gobeensglobal.com/wp-content/uploads/2020/11/getnugg.png",
    catagroy: "Web Design",
    title: "NuggMd",
    descrepation:
      "Nugg’s mission is to create the world’s most reliable, customer-centric cannabis company; to radically improve people’s quality of life by making cannabis accessible and embraced all over the globe.",
    tecnology: ["Wordpress", "PHP", "MySQL", "jQuery"],
    route: "https://getnugg.com/",
    class: "col-xl-3 col-md-6",
  },
];
export default PortfolioGrid2;
