import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/6.jpg";
import { GrFormAdd } from "react-icons/gr";
import View from "./View";
import "./ServicesDetails.css";
// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import StickyBox from "react-sticky-box";
import bg2 from "../../../images/background/outsourcing.jpg";
import Information from "../../../images/Outsourcing/INFORMATION-TECHNOLOGY.jpg";
import Real from "../../../images/Outsourcing/REAL-ESTATE.jpg";
import Study from "../../../images/Outsourcing/EDUCATION.jpg";
import Telecome from "../../../images/Outsourcing/TELECOME.jpg";
import Medical from "../../../images/Outsourcing/MEDICAL.jpg";
import Financial from "../../../images/Outsourcing/financial-accounting.jpg";
import Ecommerce from "../../../images/Outsourcing/E-COMMERCE.jpg";
import Construction from "../../../images/Outsourcing/CONSTRUCTION.jpg";
import Automation from "../../../images/Outsourcing/AUTOMATION.jpg";
import { MdComputer, MdArrowRightAlt } from "react-icons/md";
class Outsourcing extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="OutSourcing Services"
              activeMenu="OutSourcing Services"
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl  mx-auto">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div class="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <h2 class="title">
                      BUSINESS PROCESS OUTSOURCING SERVICES (BPO)
                    </h2>
                    <p className="">
                      If you comprehend your clients’ prerequisites, your BPO
                      services can be a great answer to your client’s needs.
                      BPOs work to streamline the recruitment process for their
                      partners. It likewise guarantees that you stay in business
                      and prosper. With Business Process Outsourcing companies,
                      you can reduce expenses, spare time, and improve your
                      processes. Gobeens Technology offers the best BPO
                      administrations.
                    </p>
                    {/* <span className="row ">
                      <div className="lol col-sm-4 col-md-6">
                        <div class="card " style={{ width: "20rem" }}>
                          <div class="card-body">
                            <h7 class="">ACCOUNTING AND FINANCIAL SERVICES</h7>
                            <p class="card-text">
                              You can rely upon our financial and accounting
                              group to help you characterize the sort of service
                              you need. It helps you bring the right talent to
                              achieve your accounting objectives through the
                              year-end budget report.
                            </p>
                          </div>
                        </div>
                        <div className="lol1">
                          <div class="card" style={{ width: "20rem" }}>
                            <div class="card-body">
                              <h7 class="">DIGITAL MARKETING</h7>
                              <p class="card-text">
                                Gobeens Technology is the leading digital marketing
                                firm that offers a complete choice of services
                                to help your business grow. We help resolve the
                                difficulties you face in your business growth
                                and boost your online reach.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lol col-sm-4 col-md-6">
                        <div className="">
                          <div class="card" style={{ width: "20rem" }}>
                            <div class="card-body">
                              <h7 class="">SOURCING AND PROCUREMENT</h7>
                              <p class="card-text">
                                An advanced talent acquisition organization
                                automates repeatable assignments to support
                                proficiency and diminishing expenses. It equips
                                the business stakeholders with continuous
                                admittance to analytics and insights through AI
                                and simple to-utilize online tools.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="lol2">
                          <div class="card" style={{ width: "20rem" }}>
                            <div class="card-body">
                              <h7 class="">HEALTHCARE AND INSURANCE</h7>
                              <p class="card-text">
                                We offer health insurance BPO services to
                                corporate employees and individual clients. We
                                envision a monetarily secure fate of our clients
                                through the delivery of altered medical coverage
                                plans.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span> */}
                    {/* BUSINESS PROCESS OUTSOURCING SERVICES (BPO) */}
                    <div className="grid my-10 md:grid-cols-4 gap-2">
                      {BusinessProcess.map((e) => {
                        return (
                          <div className="relative cursor-pointer hover:shadow-2xl group hover:bg-[#FF3300] duration-300 border border-[#fff] rounded-md bg-[#262051] p-4">
                            <h2 className="absolute top-0 left-0 text-8xl text-[#332D5F] group-hover:text-red-400 duration-300 ">
                              {e.no}
                            </h2>
                            <div className="py-8 relative z-10">
                              <h2 className="text-white text-2xl">{e.title}</h2>
                              <p className="text-gray-500">{e.desc}</p>
                              {/* <span className="flex items-center mt-10 space-x-2 ">
                                <h2 className="text-white  text-base font-medium tracking-wider">
                                  Learn More
                                </h2>
                                <MdArrowRightAlt className="text-3xl group-hover:translate-x-3 duration-300 mb-4" />
                              </span> */}
                            </div>
                            <span className="absolute bg-[#FF3300] group-hover:bg-[#262051] duration-500 pl-4 pt-4 pr-3 pb-3 bottom-0 right-0 rounded-tl-[50px]">
                              <MdComputer className="text-4xl" />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    {/* SCALABLE RECRUITMENT PROCESS OUTSOURCING (RPO) SOLUTIONS */}
                    <div className="">
                      <img
                        src="https://cdn-cpjjo.nitrocdn.com/OiTvcMywCpwqCFlxycmDlgSdGFUQWyin/assets/static/optimized/rev-ec11457/wp-content/uploads/2020/08/oie_261227ZqTwTg8z.gif"
                        alt=""
                      />
                      <h2 class="title">
                        SCALABLE RECRUITMENT PROCESS OUTSOURCING (RPO) SOLUTIONS
                      </h2>
                      <p className="">
                        Organizations, both big and little, rely upon internal
                        groups to source top-notch talent. Recruitment process
                        outsourcing (RPO) programs give talent acquisition
                        service to proficient professional and non-professional
                        positions. At Gobeens Technology’s, we help simplify the
                        talent acquisition process through our recruitment
                        process. The industry-driving RPO solutions focus on the
                        right methodology, quantifiable outcomes, and flexible
                        solutions.
                      </p>
                      <div className="grid my-10 md:grid-cols-4 gap-2">
                        {RPO.map((e) => {
                          return (
                            <div className="relative cursor-pointer hover:shadow-2xl group hover:bg-[#FF3300] duration-300 border border-[#fff] rounded-md bg-[#262051] p-4">
                              <h2 className="absolute top-0 left-0 text-8xl text-[#332D5F] group-hover:text-red-400 duration-300 ">
                                {e.no}
                              </h2>
                              <div className="py-8 relative z-10">
                                <h2 className="text-white text-2xl">
                                  {e.title}
                                </h2>
                                <p className="text-gray-500">{e.desc}</p>
                                {/* <span className="flex items-center mt-10 space-x-2 ">
                                <h2 className="text-white  text-base font-medium tracking-wider">
                                  Learn More
                                </h2>
                                <MdArrowRightAlt className="text-3xl group-hover:translate-x-3 duration-300 mb-4" />
                              </span> */}
                              </div>
                              <span className="absolute bg-[#FF3300] group-hover:bg-[#262051] duration-500 pl-4 pt-4 pr-3 pb-3 bottom-0 right-0 rounded-tl-[50px]">
                                <MdComputer className="text-4xl" />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <h2 class="title">INDUSTRIES WE SERVE</h2>

                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {WeServe.map((e) => {
                        return (
                          <div className="flex bg-white group shadow-md flex-col space-y-3 rounded-xl p-4 items-center">
                            <img
                              className="rounded-xl group-hover:shadow-lg"
                              src={e.img}
                              alt=""
                            />
                            <h2 className="text-lg tracking-wide font-medium">
                              {e.title}
                            </h2>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="lol7">{/* <View /> */}</div>
                  <div class="faq-section mt-60 ">{/* collapse */}</div>

                  <div class="faq-section ">
                    {/* collapse */}

                    <div class="faq-section mt-40">
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          What is Business Process Outsourcing?
                        </summary>
                        <p class="px-4 py-3">
                          Business process outsourcing (BPO) involves a business
                          contracting a third-party to provide certain business
                          functions to it. These functions are not core
                          functions of a business but still are important for
                          the business. As the business does not have the human
                          resources who can perform these functions as well as
                          the technology, tools and infrastructure required for
                          the functions, it outsources them to a third party who
                          has expertise in performing these functions, leading
                          to saving of time and other resources which thus can
                          be devoted to a business’s core areas.
                        </p>
                      </details>

                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                          Which BPO services do you provide?
                        </summary>
                        <p class="px-4 py-3">
                          We provide BPO services in terms of accounting and
                          financial services, sourcing and procurement, digital
                          marketing, healthcare and insurance and recruitment
                          services.
                        </p>
                      </details>

                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          How does outsourcing lessen business costs?
                        </summary>
                        <p class="px-4 py-3">
                          Outsourcing helps to bring your business costs down by
                          reducing your labor costs, operational costs,
                          technology costs, recruitment and training costs and
                          infrastructure costs. Due to these cost savings, your
                          business can maintain lower rates, which consequently
                          will give it a competitive advantage in the market.
                        </p>
                      </details>
                      <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                        <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                          What industries does our company specialize in?
                        </summary>
                        <p class="px-4 duration-500 py-3">
                          Our outsourcing services serve a wide array of
                          industries including information technology, real
                          estate, financial accounting, education, e-commerce,
                          telecom, medical, construction and automation.
                        </p>
                      </details>
                    </div>
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>
          {/* <Pricing /> */}
          {/* Bottom Form */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export const BusinessProcess = [
  {
    no: "1",
    title: "ACCOUNTING AND FINANCIAL SERVICES",
    desc: " You can rely upon our financial and accounting group to help you characterize the sort of service you need. It helps you bring the right talent to achieve your accounting objectives through the year-end budget report.",
  },
  {
    no: "2",
    title: "DIGITAL MARKETING",
    desc: "  Gobeens Technology is the leading digital marketing firm that offers a complete choice of services to help your business grow. We help resolve the difficulties you face in your business growth and boost your online reach.",
  },
  {
    no: "3",
    title: "SOURCING AND PROCUREMENT",
    desc: "An advanced talent acquisition organization automates repeatable assignments to support proficiency and diminishing expenses. It equips the business stakeholders with continuous admittance to analytics and insights through AI and simple to-utilize online tools.",
  },
  {
    no: "4",
    title: "HEALTHCARE AND INSURANCE",
    desc: " We offer health insurance BPO services to corporate employees and individual clients. We envision a monetarily secure fate of our clients through the delivery of altered medical coverage plans.",
  },
];

export const RPO = [
  {
    no: "1",
    title: "FLEXIBILITY",
    desc: "HR offices are being approached to accomplish more with less. Gobeens Technology can reinforce selecting assets for new market openings, explicit ranges of abilities, etc.",
  },
  {
    no: "2",
    title: "SCALABILITY",
    desc: "Our flexible arrangements permit you to scale redistributing up or down during high and low volume periods.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet.",
  },
  {
    no: "3",
    title: "SEAMLESS COMPLIANCE",
    desc: "Gobeens Technology's rigorous recruitment process guarantees a legitimately compliant process of hiring and a streamlined auditing response.",
  },
  {
    no: "4",
    title: "GLOBAL REACH",
    desc: "Gobeens Technology is the biggest RPO. Our worldwide recruiting capacities can give you admittance to the best workforce that you need.",
  },
];

export const WeServe = [
  {
    img: Information,
    title: "Information Technology",
  },
  {
    img: Study,
    title: "Education",
  },
  {
    img: Medical,
    title: "Medical",
  },
  {
    img: Real,
    title: "Real Estate",
  },
  {
    img: Ecommerce,
    title: "Ecommerce",
  },
  {
    img: Telecome,
    title: "Telecom",
  },
  {
    img: Financial,
    title: "Financial Accounting",
  },
  {
    img: Construction,
    title: "Construction",
  },
  {
    img: Automation,
    title: "Automation",
  },
];
export default Outsourcing;
