import React, { Fragment, Component } from "react";
import CountUp from "react-countup";

const counterBlog = [
    {
      title: "SATISFACTION GUARANTEED",
      num: "100",
    },
    {
        title: "RETURN ON INVESTMENT",
        num: "100"
      },
      {
        title: "ON-TIME DELIVERY",
        num: "100",  
      },
   
  ];

class Counter extends Component {
  render() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="justify-content-center ">
            {counterBlog.map((data, index) => (
              <div class="shadow-lg p-3  mb-5 bg-white rounded">
                <div class="card-body card">
                  <h7 className=" m-t0 ">{data.title}</h7>
                  <h2 class="card-body cardjustify-content-center">
                    <span className="counter card-text ">
                      <CountUp end={data.num} duration={4} />
                    </span>
                    %
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Counter;
