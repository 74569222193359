import React, { Component } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Layout/PageTitle";
import bnr5 from "./../../../images/banner/3.jpg";
import { GrFormAdd } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import "./ServicesDetails.css";
// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Pricing from "./Pricing";
import FormStyle from "../../Element/FormStyle";
import bg1 from "../../../images/background/bg1.jpg";
import StickyBox from "react-sticky-box";
import bg2 from "../../../images/background/mobile.jpg";
import bg3 from "../../../images/background/mobile1.jpg";
import Android from "../../../images/mobile icons/ANDROID--mobile.jpg";
import Iphone from "../../../images/mobile icons/i-phone-2.jpg";
import Iwatch from "../../../images/mobile icons/i-watch-mobile.jpg";
import Ipad from "../../../images/mobile icons/ipad-mobile.jpg";
import Phonegap from "../../../images/mobile icons/phonegap-mobile.jpg";
import Window from "../../../images/mobile icons/winsows-mobile.jpg";
import NewCard from "./NewCard";
class Mobile extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
            style={{ backgroundImage: "url(" + bnr5 + ")" }}
          >
            <PageTitle
              motherMenu="Mobile App Development"
              activeMenu="Mobile App Development"
            />
          </div>
          {/* <!-- inner page banner END --> */}
          <section class="service-details section-gap container-xxl mx-auto ">
            <div class="">
              <div class="row">
                <div class="col-lg-8">
                  <div class="service-details-content">
                    <div class="main-thumb mb-40">
                      <img className="w-full" src={bg2} alt="" />
                    </div>
                    <h2 class="title">Mobile App Development</h2>
                    <p className=" lol2">
                      From idea to the development of application software,
                      Gobeens Technology covers the mobile application development
                      cycle, regardless of how varied or multifaceted your needs
                      are. Our capacity to address your issues comes from our
                      team of specialists, who have multiple years of
                      involvement with product engineering and global app
                      development ventures. From effectively running start-ups
                      to our existing customers, we attempt to streamline the
                      work process, coordinate functions into one consistent
                      pattern, and work to enhance a business endeavor’s
                      profitability. We have experienced UI/UX designers,
                      versatile mobile application developers, and quality
                      analysts. They deliver innovatively created mobile
                      applications to take your business to the next level. We
                      plan and create custom mobile applications for iOS and
                      Android variants. Our designers have the aptitude to make
                      mobile applications by using the latest AI and ML
                      techniques.
                    </p>
                    <h2 className="title ">WHAT WE SERVE</h2>
                    <p className="">
                      As an eminent and reliable mobile application development
                      organization, we guarantee quality while making eye-catchy
                      applications. We have been offering a premium mobile
                      application development for a worldwide customer base. We
                      are devoted to giving the best app development services
                      and are glad to have a tremendous offshore customer base
                      alongside many top local brands.
                    </p>

                    {/* Cards Data */}
                    {/* <div className="row">
                      {SERVE.map((data, index) => (
                        <div class=" col-sm-4 col-md-4 kid-space ">
                          <div class=" flip-card lol1">
                            <div class="flip-card-inner">
                              <div className="flip-card-front">
                                <img
                                  src={data.img}
                                  style={{ width: "300px", height: "200px" }}
                                  alt="Avatar"
                                />
                                <h7>{data.title}</h7>
                              </div>
                              <div className="flip-card-back">
                                <p className="lol4">{data.title}</p>
                                <p className="lol3">{data.decs}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    <NewCard data={SERVE} />
                    {/* Cards Data End */}
                    <div className="lol5">
                      <div class="row align-items-center justify-content-center  container">
                        <div class="col-lg-6">
                          <div class="image text-center mb-40">
                            <img
                              src={bg3}
                              // style={{ height: "350px", width: "350px" }}
                              className="w-full"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 lol2">
                          <h3 class="sub-title">WHY CHOOSE US</h3>
                          <p>
                            Gobeens Technology is recognized to be one of the famous
                            Mobile Application Development services. We deliver
                            creative mobile application development solutions
                            that help companies gain profit by generating good
                            revenue and reducing costs. Here are a few reasons
                            that justify why selecting Gobeens Technology for your
                            mobile app development needs is a great idea!
                          </p>
                          <ul class="check-list mt-30 mb-40">
                            <li className="flex items-center">
                              <TiTick class="design" />
                              YEARS OF INVOLVEMENT & CREDIBILITY
                            </li>
                            <li className="flex items-center">
                              <TiTick class="design" />
                              OPERATIONAL EXPERTISE AND QUALITY
                            </li>
                            <li className="flex items-center">
                              <TiTick class="design" />
                              FINANCIALLY SAVVY
                            </li>
                            <li className="flex items-center">
                              <TiTick class="design" />
                              SECURITY
                            </li>
                          </ul>
                        </div>
                        <div class="faq-section ">
                          {/* collapse */}

                          <div class="faq-section mt-40">
                            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                                What is required in mobile app development?
                              </summary>
                              <p class="px-4 py-3">
                                Mobile app development requires creation of
                                software applications that run on various kinds
                                of mobile devices. To build a mobile app, app
                                developers of an experienced development firm
                                create a detailed development plan, build a
                                user-friendly interface, construct necessary
                                installable software bundles as well as
                                implement appropriate backend services for
                                supporting the app. The app is rigorously tested
                                throughout the development process to ensure it
                                perfectly matches the client’s requirements and
                                is smoothly functional, secure and free from any
                                issues.
                              </p>
                            </details>

                            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3 after:content-['+']">
                                What are the different types of mobile apps that
                                your developers can build?
                              </summary>
                              <p class="px-4 py-3">
                                Our seasoned developers are skilled at building
                                mobile apps for both Android and iOS phones and
                                tablets. They are also adept at building mobile
                                apps for Windows and Phonegap mobile platforms.
                              </p>
                            </details>

                            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                                Will you also maintain my website?
                              </summary>
                              <p class="px-4 py-3">
                                Our experts will not only build your website,
                                but will also take care of its maintenance to
                                ensure it continues to function in a smooth,
                                glitch-free and secure manner. Regular website
                                maintenance performed by them will help your
                                business function normally and retain customers’
                                trust in your business brand.
                              </p>
                            </details>
                            <details class="w-full shadow-md bg-white border border-blue-500 cursor-pointer mb-3">
                              <summary class="w-full bg-white text-dark flex justify-between px-4 py-3  after:content-['+']">
                                Will my website be mobile friendly?
                              </summary>
                              <p class="px-4 duration-500 py-3">
                                Yes, the website we will develop for you will
                                open and look perfect on a variety of mobile
                                phones and other devices like tablets, laptops
                                and desktops. The responsive website will
                                provide your users an appealing viewing
                                experience as well as an optimum browsing
                                experience on their respective devices.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* collapse */}
                {/* Sidebar */}
                <div class="col-lg-4 col-md-8">
                  <StickyBox
                    // className="md:w-2/6 m-2 p-4 border-gray-300 shadow-md rounded-lg bg-white border "
                    offsetTop={100}
                    offsetBottom={100}
                  >
                    <div class="sidebar">
                      <Sidebar />
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>

          <Pricing />
          {/* Bottom Form */}
          <div
            className="section-full overlay-primary-dark bg-img-fix"
            style={{ backgroundImage: "url(" + bg1 + ")" }}
          >
            <FormStyle />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
const SERVE = [
  {
    title: "IPHONE APPS DEVELOPMENT",
    desc: "STRUCTURING EXQUISITE, FEATURE-RICH, CUSTOM IOS APPLICATIONS ACCOMPANIES A MULTIFACETED NATURE.Gobeens Technology IOS DEVELOPMENT SERVICE ALTERS THE IDEA OF DEVELOPING AN IOS APPLICATION INTO REALISM WITH SIMPLE TO UTILIZE AND NATURAL INTERFACE.",
    route: "/iphone-apps-development",
  },
  {
    title: "IPAD APPS DEVELOPMENT",
    desc: "Gobeens Technology IS THE LEADING IPAD APPLICATION DEVELOPMENT ORGANIZATION THAT IS TOP-RATED GLOBALLY. OUR IPAD APPLICATION ENGINEERS HAVE A TOP TO BOTTOM COMPREHENSION OF LOCAL IPAD DEVELOPMENT PREREQUISITES.",
    route: "/ipad-apps-development",
  },
  {
    title: "WINDOWS APPS DEVELOPMENT",
    desc: " Gobeens Technology IS A MAIN WINDOWS APP DEVELOPMENT ORGANIZATION IN INDIA, OFFERING THE BEST SERVICES CUSTOM-FITTED TO MEET YOUR BUSINESS OBJECTIVES. WE ARE KNOWN FOR GIVING A SECURE AND RESULTS-DRIVEN APP DEVELOPMENT SERVICE.",
    route: "/windows-apps-development",
  },
  {
    title: "IWATCH APPS DEVELOPMENT",
    desc: " Gobeens Technology APPLE WATCH DEVELOPMENT SERVICE IS AN UPCOMING BUSINESS SECTOR TRENDING FOR THE LAST COUPLE OF YEARS. WE HAVE DESIGNERS WHO REPRESENT CONSIDERABLE AUTHORITY IN WEARABLE GADGET APPLICATIONS DEVELOPMENT.",
    route: "/iwatch-apps-development",
  },
  {
    title: "ANDROID APPS DEVELOPMENT",
    desc: "STRUCTURING EXQUISITE, FEATURE-RICH, CUSTOM IOS APPLICATIONS OVER MULTIPLE YEARS, Gobeens Technology HAS BAGGED A REPUTATION OF BEING ONE OF THE MOST DEPENDABLE ANDROID APPLICATION DEVELOPMENT ORGANIZATIONS. WE CAN ASSIST YOU IN BUILDING A ROBUST SOLUTION THAT WORKS WITH EACH GADGET",
    route: "/android-apps-development",
  },
  {
    title: "PHONEGAP APPS DEVELOPMENT",
    desc: "  PHONEGAP HELPS MAKE APPLICATIONS FOR VARIOUS  STAGES WITH A SOLITARY CODEBASE TO FOCUS ON AN  ENORMOUS CLIENT BASE. Gobeens Technology TAKES A CROSS-STAGE APPLICATION DEVELOPMENT TO THE FOLLOWING LEVEL WITH THE PHONEGAP TOOLSET.",
    route: "/phonegap-apps-development",
  },
];
export default Mobile;
