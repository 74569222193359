import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeOwlSlider from "../Element/HomeOwlSlider";
import HomeOwlSlider2 from "../Element/HomeOwlSlider2";
import ImgCarouselContent from "../Element/ImgCarouselContent";
import HomeTab from "../Element/HomeTab";
import Counter from "../Element/Counter";
import PortfolioCarousel from "../Element/PortfolioCarousel";
import TestimonialCarousel from "../Element/TestimonialCarousel";
import FormStyle from "../Element/FormStyle";

//Images
import pic4 from "./../../images/about/pic4.jpg";
import bgimg from "./../../images/background/bg1.jpg";
import bgimg2 from "./../../images/background/bg-map.jpg";
import bgimg3 from "./../../images/background/bg1.jpg";
// import Client from "./Service/Client";

class Homepage extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="page-wraper">
        <Header />
        <div className="page-content bg-white">
          {/*  Slider Banner */}
          <div className="owl-slider-banner main-slider">
            <HomeOwlSlider />
            {/*  Service   */}
            <div className="service-box-slide">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-lg-12">
                    <ImgCarouselContent />
                  </div>
                </div>
              </div>
            </div>
            {/*  Service End  */}
          </div>
          {/*  Slider Banner */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full bg-white content-inner-1 about-us">
              <div className="container">
                <div style={{ display: "flex" }} className="row ">
                  <div className="col-lg-7 col-md-8">
                    <div className="abuot-box row">
                      <div className="col-lg-4">
                        <h2 className="box-title m-tb0">
                          About Us<span className="bg-primary"></span>
                        </h2>
                        <h4 className="text-gray-dark">
                          Make your customers happy by giving services.
                        </h4>
                      </div>
                      <div className="col-lg-8">
                        <p>
                          The word ‘Gobeens’ means ‘Promise’. Gobeens Technology is a
                          CANDID IT Consulting / Digital Marketing company with
                          a host of services to offer under one umbrella.
                          Founded in the year 2019 by a couple of IT enthusiasts
                          who believe in making something big. Today, Gobeens
                          Global is serving more than 100+ Clients across the
                          globe. We are a bunch of interesting people who work
                          day in and day out to achieve the goals of our
                          clients.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4 about-img">
                    <img src={pic4} data-tilt alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- About Us End --> */}
            {/* <!-- Our Services --> */}
            <div className="section-full content-inner-2">
              <div className="container-fluid">
                <div className="section-head text-black text-center">
                  {/* <h4 className="text-gray-dark m-b10">Our Services</h4> */}
                  <h2 className="box-title m-tb0">
                    Our Best Services<span className="bg-primary"></span>
                  </h2>
                  <p>
                    Gobeens Technology offers a variety of IT consulting and Digital
                    Marketing services which are flexible, robust & reliable as
                    per our client’s requirements. At Gobeens, we believe in
                    building brands rather than a just a company or a name.
                    Following is the list of services that we provide to our
                    clients.
                  </p>
                </div>
              </div>
              <div className="development-box">
                <div className="container-xxl">
                  <HomeOwlSlider2 />
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <div
              className="section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix"
              style={{ backgroundImage: "url(" + bgimg + ")" }}
            >
              <div className="container-fluid">
                <div className="section-head text-white text-center">
                  <h2 className="box-title m-tb0">
                    Why Chose Us<span className="bg-primary"></span>
                  </h2>
                  <p>
                    At Gobeens Technology, fulfilling each and every requirement of
                    yours is our top-most priority. We ensure that all your
                    needs are accounted for and properly addressed. Our clients
                    trust and count on us to keep their operations up and
                    running 24x7x365.
                  </p>
                </div>
              </div>
              <HomeTab />
              <div className="choses-info text-white">
                <Counter />
              </div>
            </div>
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Our Portfolio --> */}
            <div className="section-full content-inner-1 mfp-gallery">
              <div className="container-xxl">
                <div className="section-head text-center">
                  <h4 className="text-gray-dark m-b10">Our Portfolio</h4>
                  <h2 className="box-title m-tb0">
                    We work to innovate & are proud
                    <br /> of what we've created
                    <span className="bg-primary"></span>
                  </h2>
                  <h5>
                    YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!
                  </h5>
                </div>
                <PortfolioCarousel />
              </div>
            </div>
            {/* <!-- Our Portfolio END --> */}
            {/* <!-- Testimonials --> */}
            <div
              className="section-full content-inner"
              style={{
                backgroundImage: "url(" + bgimg2 + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container-xxl">
                <div className="section-head text-center">
                  <h2 className="box-title m-tb0">
                    Our Testimonials<span className="bg-primary"></span>
                  </h2>
                </div>
                <div className="section-content m-b30 container-xxl ">
                  <TestimonialCarousel />
                </div>
              </div>
            </div>
            {/* <!-- Testimonials END --> */}
            {/*<!--our client start --> */}
            <div className="m-b20">{/* <Client/> */}</div>
            {/*<!--our client end --> */}
            {/* <!-- Get in touch --> */}
            <div
              className="section-full overlay-primary-dark bg-img-fix"
              style={{ backgroundImage: "url(" + bgimg3 + ")" }}
            >
              <FormStyle />
            </div>
            {/* <!-- Get in touch --> */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Homepage;
